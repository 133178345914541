import 'chart.js/auto';
import { useMemo, useState } from 'react';

// COMPONENTS
import { Line } from 'react-chartjs-2';
import CustomLegend from './CustomLegend';
import ChartContainer from './ChartContainer';

// TYPES
import { ChartOptions } from 'chart.js';

type CostVsExpectedItem = {
  cost: number;
  expected_cost: number;
};

interface CostVsExpectCostProps {
  dailyData?: Record<string, CostVsExpectedItem> | null;
  weeklyData?: Record<string, CostVsExpectedItem> | null;
}

// AUTO OPTIMIZE COST VS EXPECTED COST CHART
function CostVsExpectCost({ dailyData, weeklyData }: CostVsExpectCostProps) {
  const [isWeekly, setIsWeekly] = useState(false);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({
    Cost: true,
    'Expected Cost': true,
  });

  // Formatted daily data
  const daily = useMemo(() => {
    // Get [label, data] array (max 90 days)
    const sortedEntries = Object.entries(dailyData || {})
      .map((entry) => entry)
      .sort((a, b) => {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      })
      .slice(-90);

    return sortedEntries.reduce(
      (
        acc: {
          labels: string[];
          cost: number[];
          expected: number[];
        },
        [dateStr, { cost, expected_cost }]
      ) => {
        acc.labels.push(dateStr);
        acc.cost.push(cost);
        acc.expected.push(expected_cost);
        return acc;
      },
      {
        labels: [],
        cost: [],
        expected: [],
      }
    );
  }, [dailyData]);

  // Formatted weekly data
  const weekly = useMemo(() => {
    const sortedEntries = Object.entries(weeklyData || {})
      .map((entry) => entry)
      .sort((a, b) => {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      })
      .slice(-90);

    // Weekly Create date range labels
    return sortedEntries.reduce(
      (
        acc: {
          labelsObj: { [key: string]: string };
          labels: string[];
          cost: number[];
          expected: number[];
        },
        [dateRangeStr, { cost, expected_cost }]
      ) => {
        // Truncate label
        const dateLabel = dateRangeStr.slice(9, 19);
        acc.labelsObj[dateLabel] = `${dateRangeStr}`;

        acc.labels.push(dateLabel);
        acc.cost.push(cost);
        acc.expected.push(expected_cost);
        return acc;
      },
      {
        labelsObj: {},
        labels: [],
        cost: [],
        expected: [],
      }
    );
  }, [weeklyData]);

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const revenueOverviewData = {
    labels: isWeekly ? weekly.labels : daily.labels,
    datasets: [
      {
        label: 'Cost',
        data: daily.cost,
        borderColor: 'rgba(50, 215, 111, 0.80)',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: 'rgba(50, 215, 111, 0.80)',
        pointRadius: 2,
        tension: 0.4,
        hidden: !visibility.Cost,
      },
      {
        label: 'Expected Cost',
        data: isWeekly ? weekly.expected : daily.expected,
        borderColor: '#595959',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#595959',
        pointRadius: 2,
        tension: 0.4,
        hidden: !visibility['Expected Cost'],
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            if (isWeekly) {
              const label = weekly.labels[tooltipItems[0].dataIndex];
              return `${weekly.labelsObj[label]}`;
            }
            const label = daily.labels[tooltipItems[0].dataIndex];
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: isWeekly ? 'Week' : 'Date',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 10,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Cost',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          stepSize: 5000,
          callback: function (value) {
            const numericValue = Number(value);
            return numericValue >= 1000
              ? numericValue / 1000 + 'K'
              : numericValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  // JSX
  return (
    <ChartContainer
      header="Cost vs Expected Cost"
      description="Understand the impact of Auto Optimize on your Paid Search cost"
      toggle={{
        labels: ['Daily*', 'Weekly'],
        checked: isWeekly,
        onChange: (isChecked) => {
          setIsWeekly(isChecked);
        },
      }}
      downloadParams={{
        filename: 'autoOptimizeCostVsExpectation',
        headers: ['date', 'cost', 'expected cost'],
        labels: revenueOverviewData.labels,
        columns: revenueOverviewData.datasets.map((item) => item.data),
      }}
    >
      <div className="flex justify-end text-xs pe-4 text-gray400">
        <span className="leading-none">* max 90 days or weeks</span>
      </div>
      <div className="flex">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%] h-[295px]">
          <Line data={revenueOverviewData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <div className="flex items-center">
            <CustomLegend
              legendSet="set3"
              onLegendClick={handleLegendClick}
              visibility={visibility}
            />
          </div>
        </div>
      </div>
    </ChartContainer>
  );
}

export default CostVsExpectCost;
