import { useQuery } from 'react-query';
import { useCmpIntContext } from 'hooks';
import { useEffect, useState } from 'react';
import { DEFAULT_STALE_TIME } from '../../constants';

// API REQUESTS
import { fetchCompCAData } from 'api';

// COMPONENTS
import TopAds from './CAComponents/TopAds';
import OutletSpinner from '../OutletSpinner';
import LoadingSpinner from '../LoadingSpinner';
import Top100Words from './CAComponents/Top100Words';
import TopWordsInAdCopy from './CAComponents/TopWordsInAdCopy';
import CANewAdsSeenLast7Days from './CAComponents/CANewAdsSeenLast7Days';

// Types
import {
  Top10Words,
  PlatformAds,
  DataStructure,
  Top100WordsInterface,
} from '../../types/comp-intel/comp-intel-content-analysis';

const defaultDataStructure: DataStructure = {
  new_ads: {
    SEM: {
      desktop: {},
      mobile: {},
    },
    SEO: {
      desktop: {},
      mobile: {},
    },
    PLA: {
      desktop: {},
      mobile: {},
    },
  },
  top_100_words: {},
  top_10_words: {},
  top_ads: {
    ORGANIC: [],
    PAID: [],
    PLA: [],
  },
};

// -------------------- DATA FILTERING BASED ON DROPDOWN SECTION --------------------

const filterNewAds = (data: PlatformAds, selectedDomains: string[]) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {} as PlatformAds);
};

const filterTop100Words = (
  data: Top100WordsInterface,
  selectedDomains: string[]
) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {} as Top100WordsInterface);
};

const filterTop10Words = (data: Top10Words, selectedDomains: string[]) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {} as Top10Words);
};

const filterDataBasedOnDropdown = (
  data: DataStructure,
  selectedDomains: string[]
): DataStructure => {
  if (selectedDomains.length === 0) return defaultDataStructure;

  const newData: DataStructure = {
    new_ads: {
      SEM: {
        desktop: {},
        mobile: {},
      },
      SEO: {
        desktop: {},
        mobile: {},
      },
      PLA: {
        desktop: {},
        mobile: {},
      },
    },
    top_100_words: {},
    top_10_words: {},
    top_ads: {
      ORGANIC: [],
      PAID: [],
      PLA: [],
    },
  };

  // Filter new ads data with nullish coalescing and optional chaining
  newData.new_ads = {
    SEM: {
      desktop: filterNewAds(data?.new_ads?.SEM?.desktop ?? {}, selectedDomains),
      mobile: filterNewAds(data?.new_ads?.SEM?.mobile ?? {}, selectedDomains),
    },
    SEO: {
      desktop: filterNewAds(data?.new_ads?.SEO?.desktop ?? {}, selectedDomains),
      mobile: filterNewAds(data?.new_ads?.SEO?.mobile ?? {}, selectedDomains),
    },
    PLA: {
      desktop: filterNewAds(data?.new_ads?.PLA?.desktop ?? {}, selectedDomains),
      mobile: filterNewAds(data?.new_ads?.PLA?.mobile ?? {}, selectedDomains),
    },
  };

  // Filter top 100 words data with nullish coalescing
  newData.top_100_words = filterTop100Words(
    data?.top_100_words ?? {},
    selectedDomains
  );

  // Filter top 10 words data with nullish coalescing
  newData.top_10_words = filterTop10Words(
    data?.top_10_words ?? {},
    selectedDomains
  );

  // Filter "Top Ads" data independantly via table
  newData.top_ads = { ...data.top_ads };

  return newData;
};

// -------------------- END OF: DATA FILTERING BASED ON DROPDOWN SECTION --------------------

function CompIntelContentAnalysis() {
  const {
    dateRange,
    clientDomain,
    selectedCompetitors,
    selectedSearchOptions,
    setCompetitorOptions,
  } = useCmpIntContext();

  const [filteredData, setFilteredData] =
    useState<DataStructure>(defaultDataStructure);

  const { data, error, isLoading } = useQuery(
    [
      'compDataContentAnalysis',
      dateRange?.startDate,
      dateRange?.endDate,
      // selectedSearchOptions.terms,
    ],
    () => fetchCompCAData(dateRange, selectedSearchOptions.terms),
    { staleTime: DEFAULT_STALE_TIME }
  );

  useEffect(() => {
    setCompetitorOptions((prev) =>
      Array.from(new Set([...prev, ...(data?.unique_brands || [])]))
    );
  }, [data, setCompetitorOptions]);

  useEffect(() => {
    if (selectedCompetitors.length > 0) {
      if (data) {
        const newData = filterDataBasedOnDropdown(data, selectedCompetitors);
        setFilteredData(newData);
      }
    } else {
      setFilteredData(defaultDataStructure);
    }
  }, [data, selectedCompetitors]);

  // JSX
  return isLoading ? (
    <OutletSpinner />
  ) : error ? (
    <div>Error fetching data</div>
  ) : data ? (
    <>
      {/* <CANewAdsSeenLast7Days data={data.new_ads} /> */}
      {filteredData && filteredData.new_ads ? (
        <CANewAdsSeenLast7Days data={filteredData.new_ads} />
      ) : (
        <LoadingSpinner />
      )}

      {/* <TopWordsInAdCopy data={data.top_10_words} /> */}
      {filteredData && filteredData.top_10_words ? (
        <TopWordsInAdCopy
          data={filteredData.top_10_words}
          clientDomain={clientDomain}
        />
      ) : (
        <LoadingSpinner />
      )}

      {/* <Top100Words data={data.top_100_words} /> */}
      {filteredData && filteredData.top_100_words ? (
        <Top100Words
          data={filteredData.top_100_words}
          clientDomain={clientDomain}
        />
      ) : (
        <LoadingSpinner />
      )}

      {/* <TopAds data={data.top_ads} /> */}
      {filteredData && filteredData.top_ads ? (
        <TopAds
          data={filteredData.top_ads}
          defaultCompetitors={selectedCompetitors}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  ) : (
    <div>No data available.</div>
  );
}

export default CompIntelContentAnalysis;
