import { useState, useRef, useEffect } from 'react';

// CHARTS
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartOptions, Chart, registerables } from 'chart.js';

// COMPONENTS
import { Bar } from 'react-chartjs-2';
import RCBarChart from './RCBarChart';
import RCLineChart from './RCLineChart';
import MultiDropdown from '../../MultiDropdown';
import TooltipPopover from 'components/TooltipPopover';

// UTILS
import { shortenNumberStr } from 'utils';
import { downloadImage, downloadXLS } from 'utils/download';

Chart.register(...registerables);

interface RetailerData {
  Retailer: string;
  total_demand: number;
}

interface TimeSeriesData {
  period_formatted: string;
  total_demand: number;
}

interface BrandTimeSeriesData {
  period_formatted: string;
  total_retail_demand: number;
}

interface MindsetData {
  Mindset: string;
  total_mindset_demand: number;
  demand_percentage: number;
}

interface RetailMindsetData {
  Mindset: string;
  total_retail_demand: number;
  retail_demand_percentage: number;
}

interface MindsetBarChartData {
  total_demand_per_mindset: MindsetData[];
  total_retail_demand_per_mindset: RetailMindsetData[];
}

interface RetailerOverviewProps {
  data: {
    data: RetailerData[];
  };
  timeseries_chart_data: {
    total_demand_per_period: TimeSeriesData[];
    total_demand_retail_per_period: BrandTimeSeriesData[];
  };
  mindset_bar_chart_data: MindsetBarChartData;
}

function RetailerOverview({
  data,
  timeseries_chart_data,
  mindset_bar_chart_data,
}: RetailerOverviewProps) {
  const chartRef = useRef<Chart | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const retailerLabels = data.data.map((item) => item.Retailer);
  // const retailerDemands = data.data.map((item) => item.total_demand);

  // Initialize selectedRetailers to top 5 retailers based on demand
  const topRetailers = data.data
    .sort((a, b) => b.total_demand - a.total_demand)
    .slice(0, 5)
    .map((item) => item.Retailer);

  const [selectedRetailers, setSelectedRetailers] =
    useState<string[]>(topRetailers);

  const chartWidth =
    (chartRef.current as HTMLElement | null)?.offsetWidth || 800;
  const calculatedBarThickness = Math.max(
    10,
    chartWidth / (retailerLabels.length * 2)
  );

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Retailer',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 90,
          minRotation: 45,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return shortenNumberStr(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: shortenNumberStr,
        anchor: 'end',
        align: 'top',
        offset: -4,
        color: '#4D4D4D',
        font: {
          weight: 'bold',
        },
      },
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  // const drawLabel = (chart: Chart<'bar'>) => {
  //   const ctx = chart.ctx;
  //   ctx.save();

  //   chart.data.datasets.forEach((dataset, datasetIndex) => {
  //     chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {
  //       const value = dataset.data[index] as number;
  //       const model = bar as BarElement;
  //       const barHeight = (model.y - chart.scales.y.getPixelForValue(0)) * -1;
  //       const label =
  //         value >= 1_000_000_000
  //           ? `${(value / 1_000_000_000).toFixed(1)}B`
  //           : value >= 1_000_000
  //           ? `${(value / 1_000_000).toFixed(1)}M`
  //           : value >= 1_000
  //           ? `${(value / 1_000).toFixed(1)}K`
  //           : value.toString();

  //       ctx.font = 'bold 14px DM Sans';
  //       ctx.textAlign = 'center';

  //       if (calculatedBarThickness < 65.375) {
  //         ctx.fillStyle = '#1A1A1A';
  //         ctx.fillText(label, model.x, model.y - 5);
  //       } else {
  //         ctx.fillStyle = '#ffffff';
  //         const centerY = model.y + barHeight / 2;
  //         ctx.fillText(label, model.x, centerY);
  //       }
  //     });
  //   });

  //   ctx.restore();
  // };

  // const customPlugin: Plugin<'bar'> = {
  //   id: 'customPlugin',
  //   afterDatasetsDraw: (chart) => {
  //     drawLabel(chart);
  //   },
  // };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // const handleRetailerSelect = (retailer: string) => {
  //   setSelectedRetailers((prev) => {
  //     const newRetailers = prev.includes(retailer)
  //       ? prev.filter((r) => r !== retailer)
  //       : [...prev, retailer];
  //     return newRetailers;
  //   });
  // };

  const filteredRetailers = selectedRetailers;

  const filteredTotalDemandData = data.data
    .filter((retailer) => filteredRetailers.includes(retailer.Retailer))
    .map((retailer) => retailer.total_demand);

  const filteredLabels = data.data
    .filter((retailer) => filteredRetailers.includes(retailer.Retailer))
    .map((retailer) => retailer.Retailer);

  const filteredRetailerData = {
    labels: filteredLabels,
    datasets: [
      {
        label: 'Demand',
        data: filteredTotalDemandData,
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: calculatedBarThickness,
      },
    ],
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadChartData = () => {
    const retailerHeaders = ['Retailer', 'Demand'];
    const retailerLabels = data.data.map((item) => item.Retailer);
    const retailerColumns = data.data.map((item) => item.total_demand);

    downloadXLS('Retailer_Overview_Data', retailerHeaders, retailerLabels, [
      retailerColumns,
    ]);

    const timeSeriesHeaders = ['Month', 'Demand', 'Retail Demand'];
    const timeSeriesLabels = timeseries_chart_data.total_demand_per_period.map(
      (item) => item.period_formatted
    );
    const timeSeriesColumns = [
      timeseries_chart_data.total_demand_per_period.map(
        (item) => item.total_demand
      ),
      timeseries_chart_data.total_demand_retail_per_period.map(
        (item) => item.total_retail_demand
      ),
    ];

    downloadXLS(
      'Time_Series_Data',
      timeSeriesHeaders,
      timeSeriesLabels,
      timeSeriesColumns
    );

    const transformedTotalDemand =
      mindset_bar_chart_data.total_demand_per_mindset
        .filter((item) => item.Mindset !== 'Uncategorized')
        .map((item) => ({
          ...item,
          Mindset: item.Mindset === 'Return' ? 'Enjoy' : item.Mindset,
        }));

    const transformedRetailDemand =
      mindset_bar_chart_data.total_retail_demand_per_mindset
        .filter((item) => item.Mindset !== 'Uncategorized')
        .map((item) => ({
          ...item,
          Mindset: item.Mindset === 'Return' ? 'Enjoy' : item.Mindset,
        }));

    const mindsetHeaders = ['Mindset', 'Demand', 'Retail Demand Percentage'];
    const mindsetLabels = transformedTotalDemand.map((item) => item.Mindset);
    const mindsetColumns = [
      transformedRetailDemand.map((item) => item.total_retail_demand),
      transformedRetailDemand.map((item) => item.retail_demand_percentage),
    ];

    downloadXLS('Mindset_Data', mindsetHeaders, mindsetLabels, mindsetColumns);
  };

  const downloadChartImage = () => {
    downloadImage('Retailer_Overview_Chart');
  };

  return (
    <div
      id="Retailer_Overview_Chart"
      className="bg-light rounded-2xl shadow-sm mt-8"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            Retailer Overview
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            How consumers search for retailers
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-Retailer_Overview_Chart"
        >
          {/* <CustomDropdown
            title={selectedRetailers.length > 0 ? selectedRetailers.join(', ') : 'Retailer'}
            options={[...retailerLabels]}
            onOptionClick={handleRetailerSelect}
          /> */}
          <TooltipPopover 
            content='consumer_intent_page.retailer_comparison_tab.retailer_overview.retailer_filter' 
            type='popup'
            side='top'
          >
            <MultiDropdown
              placeholder={'Retailer'}
              preselectedOptions={selectedRetailers}
              options={[...retailerLabels]}
              dropdownLength="max-h-72"
              dropdownWidth="max-w-72"
              // onOptionClick={handleRetailerSelect}
              onSelectionChange={setSelectedRetailers}
            />
          </TooltipPopover>

          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[95%] xl:w-[95%] md:w-[95%] w-[95%]">
          <Bar
            data={filteredRetailerData}
            options={options}
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
      <RCLineChart
        totalDemandPerPeriod={timeseries_chart_data.total_demand_per_period}
        totalRetailDemandPerPeriod={
          timeseries_chart_data.total_demand_retail_per_period
        }
      />
      <RCBarChart data={mindset_bar_chart_data} />
    </div>
  );
}

export default RetailerOverview;
