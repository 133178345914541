import { useQuery } from 'react-query';
import { APP_ROUTES } from '../constants';
import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// API Requests
import {
  fetchStudiesData,
  fetchCompetitorGroups,
  fetchCmpIntSearchGroups,
} from '../api';

// Components
import TimeFramePicker, {
  TimeFrameOption,
  TimeFrameDateRange,
} from './TimeFramePicker';
import Dropdown from './Dropdown';
import NavTabs from '../components/NavTabs';
import MultiDropdown from './MultiDropdown';
import FilterBarWrapper from './FilterBarWrapper';
import TooltipPopover from 'components/TooltipPopover';
import DatepickerComponent from './DatepickerComponent';

// Types
import { DateValueType } from 'react-tailwindcss-datepicker';
import { CompetitorGroup, CompIntSearchOptions } from '../types';

// Utils
import { getSelectionsFromCompGroups } from '../utils';

const TIME_FRAME_OPTIONS: TimeFrameOption[] = ['12M', '1M', '7D', '24H'];

const TAB_OPTIONS = [
  { label: 'Overview', value: APP_ROUTES.COMP_INTEL_OVERVIEW },
  { label: 'Brand Focus', value: APP_ROUTES.COMP_INTEL_BRAND_FOCUS },
  { label: 'Content Analysis', value: APP_ROUTES.COMP_INTEL_CONTENT_ANALYSIS },
  { label: 'Reports', value: APP_ROUTES.COMP_INTEL_REPORTS },
];

const DEFAULT_SEARCH_OPTIONS: CompIntSearchOptions = {
  groups: [],
  terms: [],
  data: {},
};

// Competitive Intelligence Page (Container)
function Comp_IntelligencePage() {
  const { pathname } = useLocation();
  const [studyList, setStudyList] = useState<string[]>([]);
  const [currentStudy, setCurrentStudy] = useState<string>('');
  const [clientDomain, setClientDomain] = useState<string>('');

  const [timeFrame, setTimeFrame] = useState<TimeFrameOption | null>('1M');
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({ startDate: null, endDate: null });

  const { data: studyData, isLoading: isLoadingStudies } = useQuery(
    ['studyData'],
    () => fetchStudiesData()
  );

  // Competitor Options (Groups & Domains)
  const [compGroupOptions, setCompGroupOptions] = useState<CompetitorGroup[]>(
    []
  );
  const [selectedCompGroups, setSelectedCompGroups] = useState<string[]>([]);
  const [competitorOptions, setCompetitorOptions] = useState<string[]>([]);
  const [selectedCompetitors, setSelectedCompetitors] = useState<string[]>([]);

  const { data: groupData } = useQuery(
    ['competitorGroupsData'],
    fetchCompetitorGroups,
    {
      onSuccess: ({ allDomains, competitorGroups, domain }) => {
        // Set default selections
        const initialSelectedGroups = competitorGroups.map(
          (group) => `${group.group_name}`
        );

        const { selectedCompetitors: defaultSelections } =
          getSelectionsFromCompGroups(
            competitorGroups,
            initialSelectedGroups,
            domain
          );

        setCompetitorOptions((prev) => {
          return Array.from(new Set([...prev, ...allDomains]));
        });
        setSelectedCompetitors(defaultSelections);

        setCompGroupOptions(competitorGroups);
        setSelectedCompGroups(initialSelectedGroups);
      },
    }
  );

  // Search Options (Groups & Terms)
  const [selectedSearchOptions, setSelectedSearchOptions] =
    useState<CompIntSearchOptions>({ ...DEFAULT_SEARCH_OPTIONS });

  const { data: searchOptions } = useQuery(
    ['searchTermGroups', currentStudy],
    () => fetchCmpIntSearchGroups()
  );

  useEffect(() => {
    if (!searchOptions) return;
    searchOptions.terms = Array.from(
      new Set(searchOptions.terms.map((term) => term.trim()))
    );
    setSelectedSearchOptions({ ...searchOptions });
  }, [searchOptions]);

  useEffect(() => {
    if (studyData && !isLoadingStudies) {
      const { studyList, domain } = studyData;

      if (studyList) {
        setStudyList(studyList.map((item) => item.study_name));
        if (studyList.length > 0) {
          setCurrentStudy(studyList[0]?.study_name);
        }
      }

      if (domain) {
        setClientDomain(domain);
      }
    }
  }, [studyData, isLoadingStudies]);

  const onSelectSearchGroups = (selections: string[]) => {
    setSelectedSearchOptions((prev) => {
      const terms: string[] = [];

      for (const group of selections) {
        terms.push(...Object.values(prev.data[group] || []));
      }

      return {
        ...prev,
        terms,
        groups: [...selections],
      };
    });
  };

  const onSelectSearchTerms = (selections: string[]) => {
    setSelectedSearchOptions((prev) => ({ ...prev, terms: [...selections] }));
  };

  // HANDLE TIME FRAME SELECTION
  const onChangeTimeFrame = (date_range: TimeFrameDateRange) => {
    setDateRange(date_range);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
      setTimeFrame(null);
    }
  };

  // HANDLE COMPETITOR GROUP SELECTIONS
  const onSelectCompetitorGroup = (selections: string[]) => {
    const { selectedCompetitors, selectedGroups } = getSelectionsFromCompGroups(
      compGroupOptions,
      selections
    );

    setSelectedCompGroups(selectedGroups);
    setSelectedCompetitors(selectedCompetitors);
  };

  // Handle domain selections
  const onSelectCompetitors = (selections: string[]) => {
    if (selections.length) {
      setSelectedCompetitors([...selections]);
    } else {
      setSelectedCompetitors([...competitorOptions]);
    }
  };

  // Reset Filters on tab changes
  const onChangeTab = () => {
    setTimeFrame('1M');
    if (!groupData) return;

    // Set default selections
    const initialSelectedGroups = groupData.competitorGroups.map(
      (group) => `${group.group_name}`
    );

    const { selectedCompetitors: defaultSelections } =
      getSelectionsFromCompGroups(
        groupData.competitorGroups,
        initialSelectedGroups,
        groupData.domain
      );

    setCompetitorOptions((prev) => {
      return Array.from(new Set([...prev, ...groupData.allDomains]));
    });
    setSelectedCompetitors(defaultSelections);

    setCompGroupOptions(groupData.competitorGroups);
    setSelectedCompGroups(initialSelectedGroups);
  };

  const isReportsTab = pathname === APP_ROUTES.COMP_INTEL_REPORTS;

  // JSX
  return (
    <NavTabs
      options={TAB_OPTIONS}
      onChange={onChangeTab}
      customItems={
        <TooltipPopover
          content="competitive_intelligence_page.filter.study"
          type="popup"
          side="top"
        >
          <Dropdown
            title={`Study: ${currentStudy}`}
            options={studyList}
            dropdownWidth={'min-w-96'}
            dropdownOuterWidth={'max-w-96'}
            onOptionClick={setCurrentStudy}
          />
        </TooltipPopover>
      }
    >
      {!isReportsTab ? (
        <div className="flex items-center absolute top-20 z-20 right-0"></div>
      ) : null}

      <div className="py-12">
        {!isReportsTab ? (
          <>
            <FilterBarWrapper>
              <div className="w-full flex flex-col gap-2">
                <div className="flex justify-between w-full items-center">
                  <div className="float-start">
                    <TimeFramePicker
                      onChange={onChangeTimeFrame}
                      defaultValue="1M"
                      value={timeFrame}
                      timeframeOptions={TIME_FRAME_OPTIONS}
                    />
                  </div>

                  <div className="float-end">
                    <TooltipPopover
                      content="competitive_intelligence_page.filter.date_range"
                      type="popup"
                      side="top"
                    >
                      <DatepickerComponent
                        value={dateRange}
                        onChange={onChangeDatePicker}
                        product="comp_int"
                      />
                    </TooltipPopover>
                  </div>
                </div>
                <div className="flex justify-between md:mt-0 w-full">
                  <TooltipPopover
                    content="competitive_intelligence_page.filter.search_term_group"
                    type="popup"
                    side="top"
                  >
                    <MultiDropdown
                      placeholder={'Search Term Groups'}
                      options={searchOptions?.groups || []}
                      preselectedOptions={selectedSearchOptions?.groups || []}
                      onSelectionChange={onSelectSearchGroups}
                      dropdownLength="max-h-60"
                      dropdownOpenWidth="max-w-60"
                    />
                  </TooltipPopover>
                  <TooltipPopover
                    content="competitive_intelligence_page.filter.search_terms"
                    type="popup"
                    side="top"
                  >
                    <MultiDropdown
                      placeholder={'Search Terms'}
                      options={searchOptions?.terms || []}
                      preselectedOptions={selectedSearchOptions?.terms || []}
                      onSelectionChange={onSelectSearchTerms}
                      dropdownLength="max-h-60"
                      dropdownOpenWidth="max-w-60"
                    />
                  </TooltipPopover>
                  <TooltipPopover
                    content="competitive_intelligence_page.filter.competitor_groups"
                    type="popup"
                    side="top"
                  >
                    <MultiDropdown
                      placeholder={'Competitor Groups'}
                      options={compGroupOptions.map(
                        (group) => `${group.group_name}`
                      )}
                      preselectedOptions={selectedCompGroups}
                      onSelectionChange={onSelectCompetitorGroup}
                      dropdownLength="max-h-60"
                      dropdownOpenWidth="max-w-60"
                    />
                  </TooltipPopover>
                  <TooltipPopover
                    content="competitive_intelligence_page.filter.domains"
                    type="popup"
                    side="top"
                  >
                    <MultiDropdown
                      placeholder={'Domains'}
                      options={competitorOptions}
                      preselectedOptions={selectedCompetitors}
                      onSelectionChange={onSelectCompetitors}
                      dropdownLength="max-h-60"
                      dropdownOpenWidth="max-w-60"
                    />
                  </TooltipPopover>
                </div>
              </div>
            </FilterBarWrapper>
            <div className="flex justify-end mb-4 mr-4 text-xs text-gray300 font-normal">
              Data updates daily
            </div>
          </>
        ) : null}

        {/* --- Child Route Content --- */}
        <Outlet
          context={{
            dateRange,
            clientDomain,
            competitorOptions,
            selectedCompetitors,
            setCompetitorOptions,
            selectedSearchOptions,
          }}
        />
      </div>
    </NavTabs>
  );
}

export default Comp_IntelligencePage;
