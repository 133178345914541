import React, { FC, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend from '../CustomLegend';
import { ChartOptions } from 'chart.js';
import Dropdown from '../../Dropdown';
import TooltipPopover from 'components/TooltipPopover';

interface BrandFocusBarChartProps {
  data: {
    [type: string]: {
      [platform: string]: {
        [site: string]: [number, number][];
      }
    }
  }
  selectedFilters: string[];
  clientDomain: string;
}

const BrandFocusBarChart: FC<BrandFocusBarChartProps> = ({ data, selectedFilters, clientDomain }) => {
  const getDefaultData = (device: 'desktop' | 'mobile') => {
    const labels = ['Position 1', 'Position 2', 'Position 3'];
    const datasets = selectedFilters.map((filter) => ({
      label: `${device.charAt(0).toUpperCase() + device.slice(1)} ${filter}`,
      data: [[0, 0, 0]],
      backgroundColor: 'rgba(34, 34, 34, 0.80)',
      borderWidth: 1,
      borderRadius: 8,
      barThickness: 76,
    }));
    return { labels, datasets };
  };

  const [postionDesktopData, setPositionDesktopData] = useState(getDefaultData('desktop'));
  const [postionMobileData, setPositionMobileData] = useState(getDefaultData('mobile'));

  const combinedSet = new Set<string>();
  selectedFilters.forEach((filter) => {
    const desktopKeys = Object.keys(data?.[filter]?.desktop || {});
    const mobileKeys = Object.keys(data?.[filter]?.mobile || {});
    desktopKeys.forEach(key => combinedSet.add(key));
    mobileKeys.forEach(key => combinedSet.add(key));
  })
  
  const competitorOptions: string[] = Array.from(combinedSet);

  const [selectedCompetitor, setSelectedCompetitor] = useState<string>(clientDomain ? clientDomain : competitorOptions[0]);

  const getFilteredData = (device: 'desktop' | 'mobile') => {
    const labels = ['Position 1', 'Position 2', 'Position 3'];
    const datasets = selectedFilters.map((filter) => ({
      label: `${device.charAt(0).toUpperCase() + device.slice(1)} ${filter}`,
      data: data?.[filter]?.[device]?.[selectedCompetitor] || 0,
      backgroundColor: 'rgba(34, 34, 34, 0.80)',
      borderWidth: 1,
      borderRadius: 8,
      barThickness: 76,
    }));
    return { labels, datasets };
  };

  useEffect(() => {
    setPositionDesktopData(getFilteredData('desktop'));
    setPositionMobileData(getFilteredData('mobile'));
  }, [data, selectedFilters, selectedCompetitor]);

  useEffect(() => {
    setSelectedCompetitor(clientDomain ? clientDomain : competitorOptions[0]);
  }, [data]);
  
  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Position',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Overlap (%)',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: function (value) {
            return value + '%';
          },
          stepSize: 25,
        },
        max: 100,
        min: 0,
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  return (
    <div className="mt-[60px] pb-4">
      <div>
        <TooltipPopover 
          content='competitive_intelligence_page.brand_focus_tab.brand_focus.avg_position' 
          type='info'
          className='top-1 left-[155px]'
          side='top'
          tooltipWidth='max-w-96'
        >
        <h2 className="text-primarygray font-bold mb-2 pl-4 pt-2">Average Position</h2>
        </TooltipPopover>
      </div>
      <div className="flex relative">
        <div className="flex flex-col items-center pl-2 py-6 2xl:w-[1280px] xl:h-[300px] md:w-[876px] w-[370px] h-[650px] md:ml-0 ml-8">
          <div className="flex flex-col xl:flex-row items-start gap-4 xl:gap-8 ml-4">
            <div className="flex flex-col items-start">
              <h3 className="text-bold mb-2">Desktop</h3>
              <div className="relative h-[295px] w-[420px]">
                <Bar data={postionDesktopData} options={options} />
              </div>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-bold mb-2">Mobile</h3>
              <div className="relative h-[295px] w-[420px]">
                <Bar data={postionMobileData} options={options} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend legendSet="set1" />
          <Dropdown title={`${selectedCompetitor}`} options={competitorOptions} onOptionClick={setSelectedCompetitor} dropdownLength='max-h-44'/>
        </div>
      </div>
    </div>
  );
};

export default BrandFocusBarChart;
