import { useState, useEffect, useRef } from 'react';

// UTILS
import { sortFuncDropdownOptions } from 'utils';

interface MultiDropdownProps {
  placeholder: string;
  options: string[];
  preselectedOptions: string[];
  maxNChoices?: number;
  onOptionClick?: (option: string) => void;
  onOptionChange?: (option: string[]) => void;
  onSelectionChange?: (selections: string[]) => void;
  selectAllOption?: boolean;
  applyButton?: boolean;
  dropdownLength?: string;
  dropdownWidth?: string;
  dropdownOpenWidth?: string;
  mode?: string;
  className?: string;
  alignMenu?: 'start' | 'center' | 'end';
}

function MultiDropdown({
  placeholder,
  options,
  preselectedOptions = [],
  maxNChoices = Infinity,
  onOptionClick = () => {},
  onOptionChange = () => {},
  onSelectionChange = () => {},
  applyButton = true,
  selectAllOption = true,
  dropdownLength = 'max-h-40',
  dropdownWidth = 'max-w-40',
  dropdownOpenWidth = 'max-w-72',
  mode = 'light',
  className = '',
  alignMenu = 'end',
}: MultiDropdownProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] =
    useState<string[]>(preselectedOptions);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedItems(preselectedOptions.slice(0).sort());
  }, [options, preselectedOptions]);

  useEffect(() => {
    if (!isOpen) {
      applyCurrentSelections();
    }
  }, [isOpen]);

  const toggleMultiDropdown = () => {
    if (isOpen) {
      applyCurrentSelections();
    }
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    let updatedSelectedItems;
    if (selectedItems.includes(option)) {
      updatedSelectedItems = selectedItems.filter((item) => item !== option);
    } else {
      if (selectedItems.length >= maxNChoices) return;
      updatedSelectedItems = [...selectedItems, option];
    }

    updatedSelectedItems.sort(sortFuncDropdownOptions);

    setSelectedItems(updatedSelectedItems);
    onOptionChange(updatedSelectedItems);
    onOptionClick(option);
  };

  const handleSelectAllClick = (deselectAll: boolean) => {
    const updatedSelectedItems = deselectAll ? [] : [...options];

    setSelectedItems(updatedSelectedItems.sort());
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options
    .filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort(sortFuncDropdownOptions);

  const sortedOptions = [
    ...selectedItems,
    ...filteredOptions.filter(
      (option) => option && !selectedItems.includes(option)
    ),
  ];

  const applyCurrentSelections = () => {
    onSelectionChange(selectedItems);
    onOptionChange(selectedItems);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const alignMenuClass =
    alignMenu === 'start'
      ? 'left-0'
      : alignMenu === 'center'
      ? 'left-1/2 -translate-x-1/2'
      : 'right-0';

  const isMaxSelected = selectedItems.length === maxNChoices;

  return (
    <div
      ref={dropdownRef}
      onClick={toggleMultiDropdown}
      className={`${className} text-base relative
      ${
        mode === 'dark'
          ? 'bg-primarygray text-white'
          : 'bg-white hover:bg-gray-100 border border-gray300 text-thirdgray'
      }
      py-1.5 pl-4 pr-4 rounded-lg font-medium cursor-pointer whitespace-nowrap`}
    >
      <div className="flex justify-between items-center gap-4 text-center select-none">
        <span className={`${dropdownWidth} truncate`}>
          {selectedItems.length > 0
            ? `${placeholder}: ${selectedItems.join(', ')}`
            : placeholder}
        </span>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
          className={`h-3 w-3 my-1 ml-1 transform ${
            isOpen ? '-rotate-90' : 'rotate-90'
          }`}
          alt="Arrow"
        />
      </div>
      {isOpen && (
        <div
          className={`absolute z-10 ${(() => {
            if (mode === 'dark') {
              return 'bg-primarygray text-white border-white divide-white';
            } else if (mode === 'light') {
              return 'bg-white border-gray300 divide-gray300';
            }
          })()}
        shadow-md border divide-y rounded-lg ${alignMenuClass} top-10 text-center ${dropdownLength} min-w-48 ${dropdownOpenWidth} overflow-y-auto`}
        >
          {selectAllOption && (
            <div
              className="py-1 underline select-none"
              onClick={(e) => {
                e.stopPropagation();
                handleSelectAllClick(selectedItems.length === options.length);
              }}
            >
              {selectedItems.length === options.length
                ? 'Deselect all'
                : 'Select all'}
            </div>
          )}
          {applyButton && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                applyCurrentSelections();
                setIsOpen(false);
              }}
              className="p-1.5 hover:bg-green-100 select-none"
            >
              Apply
            </div>
          )}
          <input
            onClick={(e) => {
              e.stopPropagation();
            }}
            type="text"
            className="w-full px-4 py-2 border-b border-gray-300 focus:outline-none"
            placeholder={
              maxNChoices === Infinity
                ? 'Search...'
                : `Search...(max choices ${maxNChoices})`
            }
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {(searchTerm === '' ? sortedOptions : filteredOptions).map(
            (option, index) => {
              const isChecked = selectedItems.includes(option);

              return (
                <div
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionClick(option);
                  }}
                  className={`select-none flex items-center gap-2 py-1.5 px-6 ${
                    mode === 'dark' ? 'hover:bg-gray-800' : 'hover:bg-gray-200'
                  } ${
                    isMaxSelected && !isChecked
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                >
                  <input
                    type="checkbox"
                    className="min-w-4 min-h-4"
                    checked={selectedItems.includes(option)}
                    onChange={() => {}}
                    value={option}
                  />
                  <div
                    className={
                      isMaxSelected && !isChecked
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'cursor-pointer'
                    }
                  >
                    {option}
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
}

export default MultiDropdown;
