import axios from 'axios';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// COMPONENTS
import EditPage from './EditPage';
import LoadingSpinner from './Spinner';
import OutletSpinner from '../OutletSpinner';

// TYPES
import { User } from '../../protectedRoute';

interface PlatformLogos {
  name: string;
  id: string;
  logo: string;
}

interface TableHeaders {
  label: string;
  sortable: boolean;
}

interface StatusClasses {
  Active: string;
  InProgress: string;
  Onboarding: string;
  Inactive: string;
}

interface CustomerInfo {
  customer_id: string;
  agency: { name: string };
  client: { name: string };
  line_of_business?: { name: string } | null;
  market: string;
  region: string;
  platform: string[];
  status: string;
  new_business: boolean; // New Business flag
}

interface AccountConfigurations {
  google_search_ads?: { google_search_ads_status: string };
  microsoft_ads?: null;
  google_ads?: { google_ads_status: string } | null;
  google_search_console?: { google_search_console_status: string } | null;
  google_analytics?: { google_analytics_status: string } | null;
  adobe_analytics?: null;
}

interface AccountData {
  customer_info: CustomerInfo;
  account_configurations: AccountConfigurations[];
}

interface TransformedData {
  customer_id: string;
  agency: string;
  client: string;
  line_of_business: string;
  status: string;
  platforms: boolean[];
  market: string;
  region: string;
  platform: string[];
  account_configurations: AccountConfigurations[];
  new_business: boolean; // New Business flag
}

const platformLogos: PlatformLogos[] = [
  {
    name: 'Google Ads',
    id: 'google_ads_status',
    logo: `${process.env.PUBLIC_URL}/static/img/google_ads.png`,
  },
  {
    name: 'Google Search Ads 360',
    id: 'google_search_ads_status',
    logo: `${process.env.PUBLIC_URL}/static/img/search_ads.png`,
  },
  {
    name: 'Google Search Console',
    id: 'google_search_console_status',
    logo: `${process.env.PUBLIC_URL}/static/img/search_console.png`,
  },
  {
    name: 'Google Analytics',
    id: 'google_analytics_status',
    logo: `${process.env.PUBLIC_URL}/static/img/analytics.png`,
  },
  {
    name: 'Microsoft Ads',
    id: 'microsoft_ads_status',
    logo: `${process.env.PUBLIC_URL}/static/img/microsoft_ads.png`,
  },
  {
    name: 'Adobe Analytics',
    id: 'adobe_analytics_status',
    logo: `${process.env.PUBLIC_URL}/static/img/adobe_analytics.png`,
  },
];

const tableHeaders: TableHeaders[] = [
  { label: 'Agency', sortable: true },
  { label: 'Client', sortable: true },
  { label: 'Line of Business', sortable: true },
  { label: 'Market', sortable: true },
  { label: 'Region', sortable: true },
  { label: 'Status', sortable: true },
  { label: 'New Business', sortable: true },
];

const statusClasses: StatusClasses = {
  Active: 'bg-disgreen100 text-disgreen800',
  InProgress: 'bg-purple-100 text-yellow800',
  Onboarding: 'bg-orange-100 text-gray800',
  Inactive: 'bg-red100 text-red800',
};

interface AccountsManagementProps {
  loggedInUser: User | null;
  setTopBarButton: (button: React.ReactNode) => void;
}

function AccountsManagement({
  setTopBarButton,
  loggedInUser,
}: AccountsManagementProps) {
  const locationState = useLocation().state as { customerId?: string | null };
  const [accountsData, setAccountsData] = useState<TransformedData[]>([]);
  const [selectedCustomer, setSelectedCustomer] =
    useState<TransformedData | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isViewingArchived, setIsViewingArchived] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [updatingAccountId, setUpdatingAccountId] = useState<string | null>(
    null
  ); // Track updating account ID
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const itemsPerPage = 10; // Number of accounts per page
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc' | null;
  }>({
    key: '',
    direction: null,
  });

  const navigate = useNavigate();

  const { isLoading } = useQuery(['customer-info-onboard'], {
    queryFn: () =>
      axios.get<{ customers: AccountData[] }>('/customer-info-onboard/'),
    onSuccess: (response) => {
      const transformedData: TransformedData[] = response.data.customers.map(
        (item) => {
          const accountConfig = item.account_configurations[0] || {};
          const platformsStatus = platformLogos.map((platform) => {
            const platformConfig =
              accountConfig[platform.id as keyof AccountConfigurations];
            return !!platformConfig;
          });
          return {
            customer_id: item.customer_info.customer_id,
            agency: item.customer_info.agency.name,
            client: item.customer_info.client.name,
            line_of_business: item.customer_info.line_of_business?.name || '',
            status: item.customer_info.status,
            platforms: platformsStatus,
            platform: item.customer_info.platform,
            market: item.customer_info.market,
            region: item.customer_info.region,
            account_configurations: item.account_configurations,
            new_business: item.customer_info.new_business, // New Business flag
          };
        }
      );

      if (locationState?.customerId) {
        const editCustomer = transformedData.find(
          ({ customer_id }) => customer_id === locationState.customerId
        );
        setSelectedCustomer(editCustomer || null);
        locationState.customerId = null;
      }

      setAccountsData(transformedData);
    },
  });

  useEffect(() => {
    if (selectedCustomer) {
      setTopBarButton(
        <div className="border border-thirdgray rounded-[20px] px-8">
          <button
            className="text-sm font-bold flex items-center text-thirdgray"
            onClick={() => {
              setSelectedCustomer(null);
              window.location.reload();
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
              alt="arrow"
              className="mr-[6px] rotate-180"
            />
            BACK
          </button>
        </div>
      );
    } else {
      setTopBarButton(
        <button
          className="text-sm font-bold py-2 px-4 rounded-full items-center hidden lg:flex bg-success text-light"
          onClick={() => {
            navigate('/onboard');
            setSelectedCustomer(null);
          }}
        >
          <>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/plus_icon.svg`}
              alt="plus_icon"
              className="w-[18px] h-[18px] mr-2"
            />
            ONBOARD NEW
          </>
        </button>
      );
    }
  }, [selectedCustomer, setTopBarButton, navigate]);

  const handleSort = (key: string) => {
    let fieldToSortBy: keyof TransformedData;

    switch (key) {
      case 'agency':
        fieldToSortBy = 'agency';
        break;
      case 'client':
        fieldToSortBy = 'client';
        break;
      case 'line of business':
        fieldToSortBy = 'line_of_business';
        break;
      case 'market':
        fieldToSortBy = 'market';
        break;
      case 'region':
        fieldToSortBy = 'region';
        break;
      case 'status':
        fieldToSortBy = 'status';
        break;
      case 'new business':
        fieldToSortBy = 'new_business';
        break;
      default:
        fieldToSortBy = 'agency';
    }

    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...accountsData].sort((a, b) => {
      if (a[fieldToSortBy] < b[fieldToSortBy]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[fieldToSortBy] > b[fieldToSortBy]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setAccountsData(sortedData);
  };

  const handleEditClick = (customer: TransformedData) => {
    setSelectedCustomer(customer);
  };

  const handleArchiveAccount = async (account: TransformedData) => {
    const confirmed = window.confirm(
      `Are you sure you want to archive this account?`
    );
    if (!confirmed) return;

    setUpdatingAccountId(account.customer_id); // Set the account being updated

    try {
      await axios.post(`/update-status/`, {
        customer_id: account.customer_id,
        status: 'Inactive',
      });
      setAccountsData(
        accountsData.map((a) =>
          a.customer_id === account.customer_id
            ? { ...a, status: 'Inactive' }
            : a
        )
      );
      setError(null);
    } catch (err) {
      setError('Failed to archive the account. Please try again.');
    } finally {
      setUpdatingAccountId(null); // Reset after update
    }
  };

  const handleReactivateAccount = async (account: TransformedData) => {
    const confirmed = window.confirm(
      `Are you sure you want to reactivate this account?`
    );
    if (!confirmed) return;

    setUpdatingAccountId(account.customer_id); // Set the account being updated

    try {
      await axios.post(`/update-status/`, {
        customer_id: account.customer_id,
        status: 'InProgress',
      });
      setAccountsData(
        accountsData.map((a) =>
          a.customer_id === account.customer_id
            ? { ...a, status: 'InProgress' }
            : a
        )
      );
      setError(null);
    } catch (err) {
      setError('Failed to reactivate the account. Please try again.');
    } finally {
      setUpdatingAccountId(null); // Reset after update
    }
  };

  const filteredData = accountsData.filter(
    (account) =>
      (isViewingArchived
        ? account.status === 'Inactive'
        : account.status !== 'Inactive') &&
      (account.agency.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.client.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.line_of_business
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        account.market.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.region.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // Pagination logic
  const indexOfLastAccount = currentPage * itemsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - itemsPerPage;
  const paginatedData = filteredData.slice(
    indexOfFirstAccount,
    indexOfLastAccount
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (selectedCustomer) {
    return <EditPage customer={selectedCustomer} loggedInUser={loggedInUser} />;
  }

  return (
    <div className="bg-light rounded-2xl shadow-sm mt-8 pb-5">
      <div className="flex flex-col lg:flex-row justify-end px-4 pt-2 border-b border-success pb-2">
        <div className="flex flex-col lg:flex-row items-center w-full lg:w-auto">
          <div className="relative text-thirdgray text-sm font-bold border border-gray300 rounded-full w-full lg:w-[401px] mb-4 lg:mb-0">
            <input
              type="search"
              name="search"
              placeholder="Search"
              className="h-10 text-sm pl-10 pr-4 w-full rounded-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/static/img/Trailing_icon.svg`}
              alt="search_ico"
              className="absolute left-3 top-3 h-4 w-4 fill-current"
            />
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 lg:ml-4"
          />
        </div>
      </div>

      {/* Toggle between Active/Archived */}
      <div className="flex bg-gray-200 rounded-lg w-48 h-10 mt-6 ml-4">
        <button
          className={`flex-1 rounded-md text-sm ${
            !isViewingArchived
              ? 'bg-light text-primarygray m-1'
              : 'text-gray400'
          }`}
          onClick={() => setIsViewingArchived(false)}
        >
          Active
        </button>
        <button
          className={`flex-1 rounded-md text-sm ${
            isViewingArchived ? 'bg-light text-primarygray m-1' : 'text-gray400'
          }`}
          onClick={() => setIsViewingArchived(true)}
        >
          Archive
        </button>
      </div>

      {error && <div className="text-error text-sm mb-4">{error}</div>}

      {isLoading ? (
        <OutletSpinner />
      ) : (
        <div className="overflow-x-auto mx-4 px-1">
          <table className="min-w-full bg-light table-auto">
            <thead className="text-primarygray text-xs font-normal">
              <tr>
                {tableHeaders.map((header) => (
                  <th
                    key={header.label}
                    className="py-2 px-4 text-left cursor-pointer"
                    onClick={() =>
                      header.sortable && handleSort(header.label.toLowerCase())
                    }
                  >
                    <div className="flex items-center">
                      {header.label.toUpperCase()}
                      {header.sortable && (
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                          alt="sort-icon"
                          className={`ml-2 transform ${
                            sortConfig.key === header.label.toLowerCase() &&
                            sortConfig.direction === 'desc'
                              ? 'rotate-180'
                              : ''
                          }`}
                        />
                      )}
                    </div>
                  </th>
                ))}
                {platformLogos.map((platform) => (
                  <th key={platform.id} className="py-2 px-4 text-left">
                    <div className="flex items-center space-x-2">
                      <img
                        src={platform.logo}
                        alt={platform.name}
                        className="h-6 w-6"
                      />
                      <span className="whitespace-normal">
                        {platform.name.toUpperCase()}
                      </span>
                    </div>
                  </th>
                ))}
                <th className="py-2 px-2 text-left">ACTIONS</th>
              </tr>
            </thead>
            <tbody className="text-primarygray text-xs font-normal rounded-md">
              {paginatedData.map((account, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}
                >
                  <td className="py-2 px-2">{account.agency}</td>
                  <td className="py-2 px-2">{account.client}</td>
                  <td className="py-2 px-2">{account.line_of_business}</td>
                  <td className="py-2 px-2">{account.market}</td>
                  <td className="py-2 px-2">{account.region}</td>
                  <td className="py-2 px-2">
                    <div
                      className={`flex items-center justify-center ${
                        statusClasses[account.status as keyof StatusClasses]
                      } py-1 px-2 rounded-md`}
                    >
                      {account.status}
                    </div>
                  </td>
                  <td className="py-2 px-10 text-center">
                    <div
                      className={`flex items-center justify-center ${
                        account.new_business
                          ? 'bg-success text-light'
                          : 'bg-secondarygray text-light'
                      } py-1 px-2 rounded-md`}
                    >
                      {account.new_business ? 'YES' : 'NO'}
                    </div>
                  </td>
                  {/* Iterate over platforms to display the correct status icon */}
                  {platformLogos.map((platform, idx) => {
                    // Safely check if account.account_configurations[0] exists and the platform key exists
                    const accountConfig =
                      account.account_configurations[0] || {};
                    const platformKeyString = platform.id.replace(
                      '_status',
                      ''
                    ) as keyof AccountConfigurations;
                    const platformKey =
                      platform.id as keyof AccountConfigurations;
                    let isNotUsed =
                      accountConfig[platformKeyString] === null ||
                      accountConfig[platformKeyString] === undefined;

                    if (platformKeyString === 'google_search_console') {
                      const platformConfig = accountConfig[
                        platformKeyString
                      ] as unknown as { properties: string[] };
                      // Check if the 'properties' array exists and is not empty
                      if (
                        platformConfig &&
                        Array.isArray(platformConfig.properties) &&
                        platformConfig.properties[0] === ''
                      ) {
                        isNotUsed = true;
                      } else {
                        isNotUsed = false;
                      }
                    }

                    return (
                      <td key={idx} className="py-2 px-2 text-center">
                        <div className="flex items-center justify-center">
                          {isNotUsed ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/static/img/Checkbox_onboard.svg`}
                              alt="checkbox_onboard"
                              className="h-4 w-4"
                            />
                          ) : accountConfig[platformKey] ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/static/img/Checkbox_active.svg`}
                              alt="checkbox_active"
                              className="h-4 w-4"
                            />
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/static/img/Checkbox_inactive.svg`}
                              alt="checkbox_inactive"
                              className="h-4 w-4"
                            />
                          )}
                        </div>
                      </td>
                    );
                  })}
                  <td className="py-2 px-2">
                    {!isViewingArchived ? (
                      <>
                        <button onClick={() => handleEditClick(account)}>
                          <img
                            src={`${process.env.PUBLIC_URL}/static/img/edit-03.svg`}
                            alt="edit"
                            className="h-4 w-4 mr-2"
                          />
                        </button>
                        <button onClick={() => handleArchiveAccount(account)}>
                          {updatingAccountId === account.customer_id ? (
                            <LoadingSpinner />
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/static/img/archive.svg`}
                              alt="archive"
                              className="h-4 w-4"
                            />
                          )}
                        </button>
                      </>
                    ) : (
                      <button onClick={() => handleReactivateAccount(account)}>
                        {updatingAccountId === account.customer_id ? (
                          <LoadingSpinner />
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/static/img/archive.svg`}
                            alt="reactivate"
                            className="h-4 w-4"
                          />
                        )}
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Pagination Controls */}
      <div className="flex flex-col lg:flex-row justify-end items-center px-5 py-2">
        <span className="text-xs text-gray700 font-normal mr-3 mb-2 lg:mb-0">
          Showing
          <span className="text-secondarygray font-bold tabular-nums">
            {' '}
            {Math.min(
              (currentPage - 1) * itemsPerPage + 1,
              filteredData.length
            )}
          </span>{' '}
          to
          <span className="text-secondarygray font-bold tabular-nums">
            {' '}
            {Math.min(currentPage * itemsPerPage, filteredData.length)}
          </span>{' '}
          of
          <span className="text-secondarygray font-bold tabular-nums">
            {' '}
            {filteredData.length}
          </span>{' '}
          Entries
        </span>
        <div className="flex items-center relative">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-l-lg"
          >
            PREV
          </button>
          <div
            style={{ width: '1px', backgroundColor: 'white', margin: '0.1px' }}
          ></div>
          <button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(filteredData.length / itemsPerPage)
            }
            className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-r-lg"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
}

export default AccountsManagement;
