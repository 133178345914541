import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend3 from './CustomLegend3';
import { ChartOptions } from 'chart.js';
import RCStackedBar from './RCStackedBar';
import { downloadImage,downloadXLS } from 'utils/download'

interface RetailerDeepDiveProps {
  retailer_deep_dive_timeseries_chart_data: {
    grand_totals: {
      overall_demand: number;
      total_demand: number;
      retail_demand: number;
    };
    total_demand_per_period_retailer_deep_dive: {
      period_formatted: string;
      overall_demand: number;
      total_demand: number;
      retail_demand: number;
    }[];
  };
  retailer_deep_dive_bar_chart_data: {
    total_demand_by_product: {
      [key: string]: {
        Retailer: string;
        total_retailer_demand: number;
      }[];
    };
  };
}

const RetailerDeepDive: React.FC<RetailerDeepDiveProps> = ({
  retailer_deep_dive_timeseries_chart_data,
  retailer_deep_dive_bar_chart_data
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [legendVisibility, setLegendVisibility] = useState<Record<string, boolean>>({
    'Overall Demand': true,
    'Total Demand': true,
    'Retailer Demand': true,
  });
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { grand_totals, total_demand_per_period_retailer_deep_dive } = retailer_deep_dive_timeseries_chart_data;

  const labels = total_demand_per_period_retailer_deep_dive.map(data => data.period_formatted);
  const overallDemandData = total_demand_per_period_retailer_deep_dive.map(data => data.overall_demand);
  const totalDemandData = total_demand_per_period_retailer_deep_dive.map(data => data.total_demand);
  const retailDemandData = total_demand_per_period_retailer_deep_dive.map(data => data.retail_demand);

  const RetailerData = {
    labels: labels,
    datasets: [
      {
        label: 'Overall Demand',
        data: overallDemandData,
        borderColor: '#222222',
        borderWidth: 1.5,
        fill: false,
        pointRadius: 0,
        tension: 0.4,
        hidden: !legendVisibility['Overall Demand'],
      },
      {
        label: 'Total Demand',
        data: totalDemandData,
        borderColor: '#595959',
        borderWidth: 1.5,
        fill: false,
        pointRadius: 0,
        tension: 0.4,
        hidden: !legendVisibility['Total Demand'],
      },
      {
        label: 'Retailer Demand',
        data: retailDemandData,
        borderColor: '#909090CC',
        borderWidth: 1.5,
        fill: false,
        pointRadius: 0,
        tension: 0.4,
        hidden: !legendVisibility['Retailer Demand'],
      },
    ],
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `${(tickValue / 1_000_000).toFixed(1)}M`;
    } else if (tickValue >= 1_000) {
      return `${(tickValue / 1_000).toFixed(1)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const formatNumber = (num: number): string => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + ' B';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + ' M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + ' K';
    } else {
      return num.toString();
    }
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          stepSize: 5000,
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleLegendClick = (label: string) => {
    setLegendVisibility(prev => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadChartData = () => {

    const timeSeriesHeaders = ['Month', 'Overall Demand', 'Total Demand', 'Retailer Demand'];
    const timeSeriesLabels = retailer_deep_dive_timeseries_chart_data.total_demand_per_period_retailer_deep_dive.map(item => item.period_formatted);
    const timeSeriesColumns = [
      retailer_deep_dive_timeseries_chart_data.total_demand_per_period_retailer_deep_dive.map(item => item.overall_demand),
      retailer_deep_dive_timeseries_chart_data.total_demand_per_period_retailer_deep_dive.map(item => item.total_demand),
      retailer_deep_dive_timeseries_chart_data.total_demand_per_period_retailer_deep_dive.map(item => item.retail_demand),
    ];
  
    downloadXLS('Retailer_Deep_Dive_Time_Series', timeSeriesHeaders, timeSeriesLabels, timeSeriesColumns);
  
  const products = Object.keys(retailer_deep_dive_bar_chart_data.total_demand_by_product);
  const barChartHeaders = ['Retailer', ...products];

  const retailerMap: Record<string, number[]> = {};

  products.forEach(product => {
    retailer_deep_dive_bar_chart_data.total_demand_by_product[product].forEach(retailerData => {
      const retailer = retailerData.Retailer;
      if (!retailerMap[retailer]) {
        retailerMap[retailer] = Array(products.length).fill(0);
      }
      const productIndex = products.indexOf(product);
      retailerMap[retailer][productIndex] = retailerData.total_retailer_demand;
    });
  });

  const barChartLabels = Object.keys(retailerMap);
  const barChartColumns = products.map((_, index) => barChartLabels.map(retailer => retailerMap[retailer][index]));

  downloadXLS('Retailer_Deep_Dive_Bar_Chart', barChartHeaders, barChartLabels, barChartColumns);
  };

  const downloadChartImage = () => {
    downloadImage('Retailer_Deep_Dive');
  };

  return (
    <div id="Retailer_Deep_Dive" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">Retailer Deep Dive</h2>
          {/* missing text subtitle */}
          {/* <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">Subtitle</h3> */}
        </div>
        <div className='flex items-center' ref={dropdownRef} id='downloadDropdownMenu-Retailer_Deep_Dive'>
          {/* <select className="bg-light border border-gray300 rounded-lg py-2 pr-4 pl-4 mr-[10px] text-thirdgray text-sm font-bold">
            <option className='text-left'>Retailer</option>
          </select> */}
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartData();
                      closeDropdown();
                    }}
                >
                    Download XLS
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col xl:flex-row gap-4 xl:gap-0 xl:justify-around mt-6">
        <div className="text-center">
          <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
            Overall Demand 
            {/* <div className="relative ml-1 group">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[109px] h-8 bg-gray100 text-thirdgray text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300">
                Tooltip on top
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]" />
              </span>
            </div> */}
          </h4>
          {/* <hr className="w-[280px] border-gray100"/> */}
          <p className="text-primarygray text-2xl font-medium">{formatNumber(grand_totals.overall_demand)}</p>
        </div>
        <div className="text-center">
          <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
            Demand 
            {/* <div className="relative ml-1 group">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[109px] h-8 bg-gray100 text-thirdgray text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300">
                Tooltip on top
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]" />
              </span>
            </div> */}
          </h4>
          {/* <hr className="w-[280px] border-gray100"/> */}
          <p className="text-primarygray text-2xl font-medium">{formatNumber(grand_totals.total_demand)}</p>
        </div>
        <div className="text-center">
          <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
            Retailer Demand 
            {/* <div className="relative ml-1 group">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[109px] h-8 bg-gray100 text-thirdgray text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300">
                Tooltip on top
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]" />
              </span>
            </div> */}
          </h4>
          {/* <hr className="w-[280px] border-gray100"/> */}
          <p className="text-primarygray text-2xl font-medium">{formatNumber(grand_totals.retail_demand)}</p>
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Line data={RetailerData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegend3 
              brandsData={[
                { label: 'Overall Demand', content: "consumer_intent_page.retailer_comparison_tab.retailer_deep_dive.overall_demand", color: '#222222', visibility: legendVisibility['Overall Demand'] },
                { label: 'Total Demand', content: "consumer_intent_page.retailer_comparison_tab.retailer_deep_dive.total_demand", color: '#595959', visibility: legendVisibility['Total Demand'] },
                { label: 'Retailer Demand', content: "consumer_intent_page.retailer_comparison_tab.retailer_deep_dive.retailer_demand", color: '#909090CC', visibility: legendVisibility['Retailer Demand'] },
              ]}
              onLegendClick={handleLegendClick}
              visibility={legendVisibility}
            />
        </div>
      </div>
      <RCStackedBar data={retailer_deep_dive_bar_chart_data}/>
    </div>
  );
};

export default RetailerDeepDive;
