import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartOptions, ChartData as ChartJsData } from 'chart.js';
import CustomLegendES from './CustomLegendES';
import { downloadImage, downloadXLS } from 'utils/download';
import { getUniqueStrings } from 'utils/unique';
import TooltipPopover from 'components/TooltipPopover';
import { t } from 'i18next';

interface LocalChartData {
  date_formatted: string;
  Product: string;
  total_demand: number;
}

interface BrandData {
  label: string;
  color: string;
  data: number[];
}

interface ScorecardData {
  max_demand_growth_percentage: number;
  product: string;
}

interface RealTermsScorecardData {
  max_yoy_growth_real_terms: number;
  product: string;
}

interface ProductGrowthProps {
  data: {
    data: LocalChartData[];
  };
  fastest_growing_product: ScorecardData;
  emerging_products: ScorecardData;
  fastest_growing_product_real_terms: RealTermsScorecardData;
}

const colors = [
  'rgba(34, 34, 34, 0.80)',
  'rgba(89, 89, 89, 0.8)',
  'rgba(144, 144, 144, 0.8)',
  'rgba(50, 215, 111, 0.8)',
  'rgba(3, 197, 255, 0.8)',
  'rgba(84, 79, 197, 0.8)',
  'rgba(254, 106, 53, 0.8)',
  'rgba(107, 138, 188, 0.8)',
  'rgba(213, 104, 251, 0.8)',
  'rgba(47, 224, 202, 0.8)',
  'rgba(250, 75, 66, 0.8)',
  'rgba(254, 181, 106, 0.8)',
  'rgba(254, 181, 106, 0.8)',
];

const normalizeLabel = (label: string) => label.replace(/\s+/g, '_');

const ProductGrowth: React.FC<ProductGrowthProps> = ({
  data,
  fastest_growing_product,
  emerging_products,
  fastest_growing_product_real_terms,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});
  const [brandsData, setBrandsData] = useState<BrandData[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  const handleLegendClick = (label: string) => {
    const normalizedLabel = normalizeLabel(label);
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [normalizedLabel]: !prevVisibility[normalizedLabel],
    }));
  };

  useEffect(() => {
    const chartData: LocalChartData[] = data.data;

    const products = Array.from(
      new Set(chartData.map((item: LocalChartData) => item.Product))
    );
    const dates = Array.from(
      new Set(chartData.map((item: LocalChartData) => item.date_formatted))
    );

    setLabels(dates);

    const formattedData: BrandData[] = products.map((product, index) => {
      const productData = chartData
        .filter((item: LocalChartData) => item.Product === product)
        .map((item: LocalChartData) => item.total_demand);
      return {
        label: product,
        color: colors[index % colors.length],
        data: productData,
      };
    });

    const initialVisibility: Record<string, boolean> = products.reduce(
      (acc, product) => {
        const normalizedLabel = normalizeLabel(product);
        acc[normalizedLabel] = normalizedLabel !== 'uncategorized';
        return acc;
      },
      {} as Record<string, boolean>
    );

    setBrandsData(formattedData);
    setVisibility(initialVisibility);
  }, [data]);

  const GrowData: ChartJsData<'line', number[], string> = {
    labels,
    datasets: brandsData
      .filter((brand) => visibility[normalizeLabel(brand.label)])
      .map((brand) => {
        return {
          label: brand.label,
          data: brand.data,
          borderColor: brand.color,
          borderWidth: 1.5,
          backgroundColor: brand.color,
          fill: false,
          pointRadius: 0,
          tension: 0.4,
        };
      }),
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `${(tickValue / 1_000_000).toFixed(1)}M`;
    } else if (tickValue >= 1_000) {
      return `${(tickValue / 1_000).toFixed(1)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="bg-light rounded-2xl shadow-sm mt-8"
      id="productGrowthAndDecline"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">
            Product growth and decline
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            Evolution of demand per product
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-productGrowthAndDecline"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    closeDropdown();
                    downloadXLS(
                      'productGrowthAndDecline',
                      ['date', ...brandsData.map((item) => item.label)],
                      getUniqueStrings(
                        data.data.map((item) => item.date_formatted)
                      ),
                      brandsData.map((item) => item.data)
                    );
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    closeDropdown();
                    downloadImage('productGrowthAndDecline');
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4 xl:flex-row xl:gap-0 xl:justify-around mt-6">
        <div className="text-center">
          <TooltipPopover
            complexContent={
              <p>{`${t(
                'consumer_intent_page.product_and_feature_analysis_tab.product_growth_and_decline.fastest_growing_product_percentage'
              )} ${fastest_growing_product.max_demand_growth_percentage.toLocaleString(
                undefined,
                { style: 'percent' }
              )}`}</p>
            }
            type="info"
            className="-top-[0.2rem] -right-[1rem]"
            side="top"
          >
            <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
              Fastest growing product (%)
            </h4>
          </TooltipPopover>
          <p className="text-primarygray font-medium">
            {fastest_growing_product.product}
          </p>
        </div>
        <div className="text-center">
          <TooltipPopover
            complexContent={
              <p>{`${t(
                'consumer_intent_page.product_and_feature_analysis_tab.product_growth_and_decline.fastest_growing_product_percentage'
              )} ${emerging_products.max_demand_growth_percentage.toLocaleString(
                undefined,
                { style: 'percent' }
              )}`}</p>
            }
            type="info"
            className="-top-[0.2rem] -right-[1rem]"
            side="top"
          >
            <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
              Emerging products
            </h4>
          </TooltipPopover>
          <p className="text-primarygray font-medium">
            {emerging_products.product}
          </p>
        </div>
        <div className="text-center">
          <TooltipPopover
            complexContent={
              <p>{`${t(
                'consumer_intent_page.product_and_feature_analysis_tab.product_growth_and_decline.fastest_growing_product_percentage'
              )} ${fastest_growing_product_real_terms.max_yoy_growth_real_terms.toLocaleString(
                undefined,
                { style: 'percent' }
              )}`}</p>
            }
            type="info"
            className="-top-[0.2rem] -right-[1rem]"
            side="top"
          >
            <h4 className="text-thirdgray text-sm font-medium flex items-center justify-center underlined-brand-tab">
              Fastest growing product (real terms)
            </h4>
          </TooltipPopover>
          <p className="text-primarygray font-medium">
            {fastest_growing_product_real_terms.product}
          </p>
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Line data={GrowData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegendES
            brandsData={brandsData}
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductGrowth;
