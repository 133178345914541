import { useTranslation } from 'react-i18next';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./Tooltip";

const getDefaultClassName = (type: string) => {
  switch (type) {
    case 'info':
      return '-top-3 -right-3';
    case 'dashed':
      return 'underline decoration-dashed decoration-1';
    default:
      return '';
  }
}

const getDefaultAlign = (type: string): { align: 'start' | 'center' | 'end', alignOffset: number } => {
  switch (type) {
    case 'info':
      return {
        align: 'start',
        alignOffset: -30,
      };
    default:
      return {
        align: 'center',
        alignOffset: 0,
      };
  }
}

const getDefaultDelayDuration = (type: string) => {
  switch (type) {
    case 'info':
      return 0;
    default:
      return 500;
  }
}

export default function TooltipPopover({
  type = 'popup',
  infoStandalone = false,
  children,
  content = '',
  complexContent = null,
  translate = true,
  className = getDefaultClassName(type),
  align = getDefaultAlign(type).align,
  alignOffset = getDefaultAlign(type).alignOffset,
  side = 'bottom',
  sideOffset = 4,
  collisionPadding = 10,
  delayDuration = getDefaultDelayDuration(type),
  tooltipWidth = "",
}: {
  type?: 'popup' | 'info' | 'dashed';
  children?: React.ReactNode;
  content?: string;
  complexContent?: React.ReactNode;
  translate?: boolean;
  className?: string;
  align?: 'start' | 'center' | 'end';
  alignOffset?: number;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  collisionPadding?: number;
  infoStandalone?: boolean;
  delayDuration?: number;
  tooltipWidth?: string;
}) {
  const { t } = useTranslation('tooltips');

  if (type === 'info') {
    return (
      <div className={`${infoStandalone ? '' : 'relative'}`}>
        {infoStandalone ? null: <div>{children}</div>}
        <TooltipProvider delayDuration={delayDuration}>
          <Tooltip>
            <TooltipTrigger asChild>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                alt="tooltip"
                className={`absolute ${className}`}
              />
            </TooltipTrigger>
            <TooltipContent
              avoidCollisions={true}
              collisionPadding={collisionPadding}
              side={side}
              sideOffset={sideOffset}
              align={align}
              alignOffset={alignOffset}
              className={`px-2 py-1 text-xs ${tooltipWidth} shadow-xl`}
            >
              {translate ? t(content) : content}
              {complexContent}
              <TooltipPrimitive.Arrow
                width={11}
                height={5}
                className="fill-[#F5F5F5] -translate-y-[1px]"
              />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  }

  return (
    <TooltipProvider delayDuration={delayDuration}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={`wrapper-for-ref-fix ${className}`}>
            { children }
          </div>
        </TooltipTrigger>
        <TooltipContent
          avoidCollisions={true}
          collisionPadding={collisionPadding}
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          className={`px-2 py-1 text-xs ${tooltipWidth}`}
        >
          {translate ? t(content) : content}
          {complexContent}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
