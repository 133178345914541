import React, { useState, useEffect } from 'react';

interface AutoOptimizeProps {
  customerId: string | null;
  setTriggerAutoOptimizeUpload: React.Dispatch<
    React.SetStateAction<() => Promise<void>>
  >; // Prop to receive the file upload trigger
  setHasUploadedFileOSAB: React.Dispatch<React.SetStateAction<boolean>>;
}

const TEMPLATE_LINK =
  'https://publicisgroupe.sharepoint.com/:x:/r/sites/PerformanceHub/_layouts/15/Doc.aspx?sourcedoc=%7B3114B3B6-F8E0-44D9-A859-6E030EC1D76B%7D&file=OneSearch_Auto_optimize_template_01.2025.xlsx&action=default&mobileredirect=true';

const AutoOptimize: React.FC<AutoOptimizeProps> = ({
  customerId,
  setTriggerAutoOptimizeUpload,
  setHasUploadedFileOSAB,
}) => {
  const [autoOptimizeFile, setAutoOptimizeFile] = useState<File | null>(null); // Store the file object
  const [hasFileUploadedOSAB, setHasFileUploadedOSAB] =
    useState<boolean>(false); // Local state to manage file upload status
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleAutoOptimizeFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setAutoOptimizeFile(event.target.files[0]); // Save the file object
      setHasUploadedFileOSAB(true);
      setHasFileUploadedOSAB(true);
      setUploadError(null);
    } else {
      setAutoOptimizeFile(null);
      setHasUploadedFileOSAB(false);
      setHasFileUploadedOSAB(false);
    }
  };

  useEffect(() => {
    if (autoOptimizeFile === null) {
      setHasUploadedFileOSAB(false);
      setHasFileUploadedOSAB(false);
    }
  }, [autoOptimizeFile]);

  const uploadFile = async () => {
    if (!autoOptimizeFile) {
      console.error('No file selected for upload.');
      throw new Error('No file selected.');
    }

    const formData = new FormData();
    formData.append('file', autoOptimizeFile);
    formData.append('product', 'osab');
    formData.append('date_from', dateFrom);
    formData.append('date_to', dateTo);

    if (customerId) {
      formData.append('customer_id', customerId);
    }

    try {
      const response = await fetch('/upload/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('File upload failed:', errorText);
        setUploadError(`File upload failed: ${errorText}`);
        throw new Error(`File upload failed: ${errorText}`);
      }
      const data = await response.json();
      console.log('File upload successful:', data.message);
    } catch (error) {
      console.error('Error during file upload:', error);
      throw error;
    }
  };

  useEffect(() => {
    // function that will be called to trigger the file upload
    setTriggerAutoOptimizeUpload(() => uploadFile);
  }, [autoOptimizeFile, customerId, setTriggerAutoOptimizeUpload]);

  const handleDateFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateTo(event.target.value);
  };

  return (
    <div className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold pl-4 py-2">
            Auto Optimize
          </h2>
          <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
            Increase holistic brand efficiency.
          </h3>
        </div>
      </div>
      <div className="flex flex-col space-y-4 px-4 py-4">
        <div className="flex flex-row items-center space-x-4">
          <label className="font-bold text-sm">Data Import</label>
          <div className="border border-thirdgray rounded-[20px] px-8">
            <a
              href={TEMPLATE_LINK}
              target="_blank"
              rel="noreferrer"
              className="text-sm font-bold flex items-center text-thirdgray"
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/img/download.svg`}
                alt="download"
                className="mr-[6px]"
              />
              Download template
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-sm font-bold">Add the prelim phase dates</div>
          <div className="flex items-center gap-2">
            <div className="text-sm font-medium">From:</div>
            <input
              onChange={handleDateFromChange}
              type="date"
              className="border border-gray200 rounded px-2 py-1"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="text-sm font-medium">To:</div>
            <input
              onChange={handleDateToChange}
              type="date"
              className="border border-gray200 rounded px-2 py-1"
            />
          </div>
        </div>

        {/* Conditional rendering for the file input based on dateFrom and dateTo */}
        {dateFrom && dateTo && (
          <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-col w-1/2">
              <div className="border border-gray200 rounded p-2 flex items-center">
                <input
                  type="file"
                  id="OSABfileInput"
                  className="hidden"
                  accept=".xlsx"
                  onChange={handleAutoOptimizeFileChange}
                />
                <label
                  htmlFor="OSABfileInput"
                  className="cursor-pointer text-thirdgray text-sm font-bold border border-thirdgray rounded-md px-3"
                >
                  CHOOSE FILE
                </label>
                <span className="text-thirdgray text-sm px-2">
                  {autoOptimizeFile ? autoOptimizeFile.name : 'No file chosen'}
                </span>
              </div>
              {uploadError && (
                <span className="text-error text-sm mt-2">{uploadError}</span>
              )}
            </div>
            {/* <div className="flex flex-col w-1/2">
              <span><a href="#" className="text-gray600 text-xs font-medium underline">or click here to import using plain text</a></span>
            </div> */}
          </div>
        )}
        <p className="text-xs text-thirdgray">
          Note: Maximum import size is 1000 lines
        </p>
        {!hasFileUploadedOSAB && (
          <span className="text-error text-sm">
            Please upload the required file before proceeding.
          </span>
        )}
      </div>
    </div>
  );
};

export default AutoOptimize;
