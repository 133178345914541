import 'chart.js/auto';
import { useEffect, useState, useRef } from 'react';

// Components
import { Bar } from 'react-chartjs-2';
import Dropdown from '../../Dropdown';

// Utils
import { downloadImage, downloadXLS } from '../../../utils';

// Types
import { ChartOptions } from 'chart.js';
import { Top10Words } from '../../../types/comp-intel/comp-intel-content-analysis';

interface TopWordsInAdCopyProps {
  data: Top10Words;
  clientDomain: string;
}

const TopWordsInAdCopy: React.FC<TopWordsInAdCopyProps> = ({
  data,
  clientDomain,
}) => {
  const [selectedCompetitor, setSelectedCompetitor] = useState<string>(
    clientDomain ? clientDomain : Object.keys(data)[0]
  );
  const [selectedNgram, setSelectedNgram] = useState<string>('Unigram');
  const [chartLabels, setChartLabels] = useState<string[]>(
    Object.keys(data[selectedCompetitor]?.['1-gram'] || {})
  );
  const [chartValues, setChartValues] = useState<number[]>(
    Object.values(data[selectedCompetitor]?.['1-gram'] ?? {})
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDataChange = () => {
    if (selectedNgram == 'Unigram') {
      setChartLabels(Object.keys(data[selectedCompetitor]?.['1-gram'] ?? {}));
      setChartValues(Object.values(data[selectedCompetitor]?.['1-gram'] ?? {}));
    } else if (selectedNgram == 'Bigram') {
      setChartLabels(Object.keys(data[selectedCompetitor]?.['2-gram'] ?? {}));
      setChartValues(Object.values(data[selectedCompetitor]?.['2-gram'] ?? {}));
    } else if (selectedNgram == 'Trigram') {
      setChartLabels(Object.keys(data[selectedCompetitor]?.['3-gram'] || {}));
      setChartValues(Object.values(data[selectedCompetitor]?.['3-gram'] || {}));
    } else if (selectedNgram == 'Quadgram') {
      setChartLabels(Object.keys(data[selectedCompetitor]?.['4-gram'] ?? {}));
      setChartValues(Object.values(data[selectedCompetitor]?.['4-gram'] ?? {}));
    }
  };

  useEffect(() => {
    handleDataChange();
  }, [selectedCompetitor, selectedNgram, data]);

  useEffect(() => {
    setSelectedCompetitor(clientDomain ? clientDomain : Object.keys(data)[0]);
  }, [data]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const newAdsData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'New Ads',
        data: chartValues,
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: 56,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Word',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of times word appeared',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          callback: function (value) {
            return value + '';
          },
          stepSize: 5,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const downloadChartImage = () => {
    downloadImage('TopWordsInAdCopy');
  };

  const downloadChartData = () => {
    const headers = ['Word', 'Number of times appeared'];
    const columns = [chartValues];
    const labels = chartLabels;

    downloadXLS(
      `TopWordsInAdCopy_${selectedCompetitor}_${selectedNgram}`,
      headers,
      labels,
      columns
    );
  };

  return (
    <div id="TopWordsInAdCopy" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold pl-4 pt-2">
            Top word(s) present in ad copy
          </h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">
            Understand trending words and phrases in ads
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-YOY_Change_FG"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]">
          <Bar data={newAdsData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <div className="space-y-2">
            <label className="block text-sm font-bold mb-2">Filter:</label>
            <Dropdown
              title={selectedCompetitor}
              options={Object.keys(data)}
              mode="light"
              onOptionClick={setSelectedCompetitor}
              dropdownLength="max-h-44"
            />

            <Dropdown
              className="z-0"
              title={selectedNgram}
              options={['Unigram', 'Bigram', 'Trigram', 'Quadgram']}
              mode="light"
              onOptionClick={setSelectedNgram}
              dropdownLength="max-h-44"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopWordsInAdCopy;
