import axios from 'axios';
import moment from 'moment';
import { DateValueType } from 'react-tailwindcss-datepicker';

export type CompIntelReport =
  | 'brands-report'
  | 'battlefield-report'
  | 'content-report'
  | 'search-term-report';
export type ConsumerIntentReport = 'query-view-report';
export type AutoOptimizeReport = 'osab-change-log-report' | 'osab-cost-report';
export type ReportModule =
  | 'Competitive Intelligence'
  | 'Consumer Intent'
  | 'AutoOptimize';
export type ReportType = {
  'Competitive Intelligence': CompIntelReport;
  AutoOptimize: AutoOptimizeReport;
  'Consumer Intent': ConsumerIntentReport;
};

export type DownloadReportBody<M extends ReportModule> = {
  user_id: number;
  customer_id?: string; // i.e. 1240
  market: string; // i.e. US, UK, etc.
  date_from: string; // YYYY-MM-DD
  date_to: string; // YYYY-MM-DD
  module: M;
  report_type: ReportType[M];
  verticals?: string;
  products?: string;
};

export type DownloadReportParams<T extends ReportModule> = {
  dateRange: DateValueType;
} & Omit<DownloadReportBody<T>, 'date_from' | 'date_to'>;

export const downloadReport = async <T extends ReportModule>(
  params: DownloadReportParams<T>
) => {
  if (!params.dateRange?.endDate || !params.dateRange.startDate) {
    throw new Error('Report start and end dates required.');
  }

  const body: DownloadReportBody<T> = {
    ...params,
    date_from: moment(params.dateRange.startDate).format('YYYY-MM-DD'),
    date_to: moment(params.dateRange.endDate).format('YYYY-MM-DD'),
  };

  const { data } = await axios.post('/download_reports/', body);
  return data;
};
