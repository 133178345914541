import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { APP_ROUTES } from '../constants';

// COMPONENTS
import NavTabs from './NavTabs';

const TAB_OPTIONS = [
  { label: 'Overview', value: APP_ROUTES.AUTO_OPTIMIZE_OVERVIEW },
  { label: 'Reports', value: APP_ROUTES.AUTO_OPTIMIZE_REPORTS },
];

function AutoOptimize() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === APP_ROUTES.AUTO_OPTIMIZE) {
      navigate(APP_ROUTES.AUTO_OPTIMIZE_OVERVIEW);
    }
  }, [pathname, navigate]);
  return (
    <NavTabs options={TAB_OPTIONS}>
      <Outlet />
    </NavTabs>
  );
}

export default AutoOptimize;
