import { useQuery } from 'react-query';
import { useDownloadReport } from 'hooks';
import { useEffect, useMemo, useState } from 'react';

import { fetchStudyList } from '../api';

// COMPONENTS
import TooltipPopover from './TooltipPopover';
import FilterBarWrapper from './FilterBarWrapper';
import Dropdown, { DropdownOption } from './Dropdown';
import DatepickerComponent from './DatepickerComponent';

// TYPES
import { ReportModule, ReportType } from 'api/services';
import { DateValueType } from 'react-tailwindcss-datepicker';

// UTILS
import { formatDateRange } from 'utils';

const MAX_DATE = new Date();
MAX_DATE.setDate(MAX_DATE.getDate() - 1);

const START_DATE = new Date();
START_DATE.setDate(MAX_DATE.getDate() - 6);

const idPrefix = 'module-reports';

const DEFAULT_DATE_RANGE: DateValueType = {
  startDate: START_DATE,
  endDate: MAX_DATE,
};

interface ReportsProps<Mod extends ReportModule, Opt extends ReportType[Mod]> {
  module: Mod;
  minDate?: Date;
  maxDate?: Date;
  options: DropdownOption<Opt>[];
  defaultDateRange?: DateValueType;
  defaultReport?: DropdownOption<Opt> | null;
}

// COMPETITIVE INTEL REPORTS TAB/PAGE
function Reports<Mod extends ReportModule, Opt extends ReportType[Mod]>({
  module,
  options,
  minDate,
  maxDate = MAX_DATE,
  defaultReport = null,
  defaultDateRange = DEFAULT_DATE_RANGE,
}: ReportsProps<Mod, Opt>) {
  const { mutate, isLoading: isLoadingReport } = useDownloadReport(module);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [study, setStudy] = useState<DropdownOption | null>(null);

  const [reportType, setReportType] = useState<DropdownOption<Opt> | null>(
    defaultReport || options[0]
  );

  const [dateRange, setDateRange] = useState<DateValueType | null>(
    defaultDateRange
  );

  const { data: studyList, isLoading: isLoadingStudies } = useQuery(
    ['studyList'],
    () => fetchStudyList(),
    { enabled: module === 'Competitive Intelligence' }
  );

  const isLoading = isLoadingReport || isLoadingStudies;

  const studyOptions = useMemo(() => {
    const options: DropdownOption[] = [];

    for (const study of studyList || []) {
      options.push({
        label: study.study_name,
        value: study.study_id.toString(),
      });
    }
    if (!study) {
      setStudy(options[0] || null);
    }
    return options;
  }, [studyList, study, setStudy]);

  useEffect(() => {
    if (!defaultDateRange) return;
    setDateRange(defaultDateRange);
  }, [defaultDateRange]);

  // HANDLE REPORT SELECTION
  const onChangeReport = (report: DropdownOption<Opt>) => {
    if (errorMsg) {
      setErrorMsg(null);
    }
    setReportType(report);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    const formattedDateRange = formatDateRange(newValue);

    setDateRange(formattedDateRange);
  };

  const onSubmitForm = (evt: React.FormEvent) => {
    evt.preventDefault();
    if (errorMsg) {
      setErrorMsg(null);
    }
    if (module === 'Competitive Intelligence' && !study?.label) {
      throw new Error('Study selection required.');
    }

    if (!reportType) {
      throw new Error('Report selection required.');
    }
    mutate({
      report_type: reportType.value,
      dateRange,
    });
  };

  // JSX
  return (
    <form
      id={idPrefix + '__form'}
      className={`flex flex-col gap-8 ${
        module !== 'Consumer Intent' ? 'pt-12' : 'pb-4'
      }`}
      onSubmit={onSubmitForm}
    >
      {errorMsg ? (
        <div className="text-center text-error text-sm -mb-8">{errorMsg}</div>
      ) : null}
      <div>
        <FilterBarWrapper>
          <div className="flex justify-between w-full">
            <TooltipPopover
              content={`${
                module === 'Competitive Intelligence'
                  ? 'competitive_intelligence'
                  : 'auto_optimize'
              }_page.reports_tab.filter.report`}
              type="popup"
              side="top"
            >
              <Dropdown
                title={
                  reportType ? `Report: ${reportType.label}` : 'Select Report'
                }
                onOptionClick={onChangeReport}
                options={options}
                dropdownLength="h-42"
                position="left-0"
                className="min-w-48"
              />
            </TooltipPopover>
            {module === 'Competitive Intelligence' ? (
              <TooltipPopover
                content="competitive_intelligence_page.reports_tab.filter.study"
                type="popup"
                side="top"
              >
                <Dropdown
                  title={`Study: ${study?.label}`}
                  options={studyOptions}
                  onOptionClick={(selected) => setStudy(selected)}
                  dropdownWidth={'min-w-96'}
                  dropdownOuterWidth={'max-w-96'}
                />
              </TooltipPopover>
            ) : null}
            <div className="flex gap-2">
              <TooltipPopover
                content="competitive_intelligence_page.reports_tab.filter.date_range"
                type="popup"
                side="top"
              >
                <DatepickerComponent
                  onChange={onChangeDatePicker}
                  value={dateRange}
                  product="comp_int"
                  maxDate={maxDate}
                  minDate={minDate}
                />
              </TooltipPopover>
            </div>
          </div>
        </FilterBarWrapper>
      </div>
      <div className="flex justify-center">
        <button
          id={idPrefix + '__btn-submit'}
          type="submit"
          className="text-sm font-bold py-2 px-4 rounded-full items-center lg:flex bg-success text-light w-24 h-10 block"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex justify-center w-full">
              <div className="animate-spin rounded-full h-5 w-5 border-t-4 border-b-4 border-light"></div>
            </div>
          ) : (
            'Download'
          )}
        </button>
      </div>
    </form>
  );
}

export default Reports;
