import { useState, useEffect, ReactNode, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Components
import UsersManagement from "./SettingsComp/UsersManagement";
import AccountsManagement from "./SettingsComp/AccountsManagement";

// Types
import { User } from "protectedRoute";

interface SettingsPageProps {
  setTopBarButton: (button: ReactNode) => void;
  loggedInUser: User | null;
}
// TODO: Create a constants file & put the TabNames there for reusing
enum TabNames {
  Accounts = "AccountsManagement",
  Users = "UsersManagement",
}

const TopBarButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => (
  <button
    className="text-sm font-bold py-2 px-4 rounded-full items-center hidden lg:flex bg-success text-light"
    onClick={onClick}
  >
    <img
      src={`${process.env.PUBLIC_URL}/static/img/plus_icon.svg`}
      alt="plus_icon"
      className="w-[18px] h-[18px] mr-2"
    />
    {label}
  </button>
);

const TabMenu = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}) => (
  <div className="sticky top-[94px] z-10 flex bg-body border-b border-gray300 whitespace-nowrap">
    {Object.values(TabNames).map((tab) => (
      <div
        key={tab}
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${
          activeTab === tab ? "border-b-2 border-primarygray" : ""
        }`}
        onClick={() => setActiveTab(tab)}
      >
        {tab.replace("Management", " Management")}
      </div>
    ))}
  </div>
);

function SettingsPage({ setTopBarButton, loggedInUser }: SettingsPageProps) {
  const [activeTab, setActiveTab] = useState<string>(TabNames.Accounts);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnboardNewClick = () => {
    setTopBarButton(null);
    navigate("/onboard");
  };

  const handleAddNewUserClick = () => {
    window.dispatchEvent(new CustomEvent("addNewUser"));
  };

  const topBarButton = useMemo(() => {
    if (location.pathname !== "/settings") return null;
    
    const isAccountsTab = activeTab === TabNames.Accounts;
    return (
      <TopBarButton
        label={isAccountsTab ? "ONBOARD NEW" : "ADD NEW USER"}
        onClick={isAccountsTab ? handleOnboardNewClick : handleAddNewUserClick}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location.pathname]);

  useEffect(() => {
    setTopBarButton(topBarButton);
    return () => setTopBarButton(null);
  }, [topBarButton, setTopBarButton]);

  return (
    <div className="flex flex-col">
      <TabMenu activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="flex-1">
        {activeTab === TabNames.Accounts ? (
          <AccountsManagement
            setTopBarButton={setTopBarButton}
            loggedInUser={loggedInUser}
          />
        ) : (
          <UsersManagement loggedInUser={loggedInUser} />
        )}
      </div>
    </div>
  );
}

export default SettingsPage;
