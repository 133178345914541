import React, { FC } from 'react';
import TooltipPopover from 'components/TooltipPopover';

interface BrandData {
  label: string;
  content: string;
  color: string;
  visibility: boolean;
}

interface CustomLegendProps {
  brandsData: BrandData[];
  onLegendClick?: (label: string) => void;
  visibility?: Record<string, boolean>;
}

const LineIcon = ({ color }: { color: string }) => (
  <svg
    width="16"
    height="2"
    viewBox="0 0 16 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: color }}
  >
    <path
      d="M0 1H16"
      strokeOpacity="0.8"
      strokeWidth="2"
    />
  </svg>
);

// Utility function to fade RGBA color
const fadeRgbaColor = (color: string, alpha: number) => {
  const rgba = color.replace(/[^\d,]/g, '').split(',');
  return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${alpha})`;
};

// Utility function to fade HEX color
const fadeHexColor = (color: string, alpha: number) => {
  const num = parseInt(color.replace("#", ""), 16);
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// Main utility function to handle both HEX and RGBA
const fadeColor = (color: string, alpha: number) => {
  if (color.startsWith('#')) {
    return fadeHexColor(color, alpha);
  } else if (color.startsWith('rgba')) {
    return fadeRgbaColor(color, alpha);
  }
  return color;
};

const CustomLegend3: FC<CustomLegendProps> = ({ brandsData, onLegendClick, visibility }) => {
  return (
    <div className="flex flex-col items-start pl-2 text-xs">
      {brandsData.map((brand, index) => (
        <div
          key={index}
          className="flex items-center mb-2 cursor-pointer"
          onClick={() => onLegendClick && onLegendClick(brand.label)}
        >
          <LineIcon
            color={
              visibility && visibility[brand.label]
                ? brand.color
                : fadeColor(brand.color, 0.5)
            }
          />
          <TooltipPopover 
            content={brand.content}
            type='dashed'
            side='top'
          >
          <span
            className="ml-2"
            style={{
              color: visibility && visibility[brand.label]
                ? '#000000'
                : fadeColor('#000000', 0.5),
            }}
          >
            {brand.label.replace('_', ' ')}
          </span>
          </TooltipPopover>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend3;
