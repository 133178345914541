// COMPONENTS
import TooltipPopover from '../TooltipPopover';
import Dropdown from '../Dropdown';

interface GoogleEmailDropdownProps {
  options: string[];
  value: string;
  onChange:
    | ((value: string) => void)
    | React.Dispatch<React.SetStateAction<string>>;
  isRequired?: boolean;
  isDisabled?: boolean;
}

// GOOGLE API ACCOUT EMAIL
function GoogleEmailDropdown({
  options,
  value,
  onChange,
  isRequired = false,
  isDisabled = false,
}: GoogleEmailDropdownProps) {
  return (
    <div>
      <label className="text-primarygray font-bold inline-flex items-center gap-1">
        <img
          src={`${process.env.PUBLIC_URL}/static/img/search_console.png`}
          alt="plus_icon"
          className="w-[24px] h-[24px] mr-1"
        />
        <TooltipPopover
          type="info"
          content="settings_page.account_management_tab.google_account"
          side="top"
        >
          Google Account {isRequired ? '*' : ''}
        </TooltipPopover>
      </label>
      <div className="flex gap-2 items-center">
        <span className="text-sm font-bold">Email</span>
        <Dropdown
          title={value}
          dropdownOuterWidth="w-72"
          dropdownWidth="w-96"
          options={options}
          onOptionClick={onChange}
          disabled={isDisabled}
          value={value}
        />
      </div>
      {isRequired && !value ? (
        <span className="text-error text-sm">
          Google account email required.
        </span>
      ) : null}
    </div>
  );
}

export default GoogleEmailDropdown;
