import { useQuery } from 'react-query';
import { useCmpIntContext } from 'hooks';
import { DEFAULT_STALE_TIME } from '../../constants';
import { useState, useRef, useEffect, useMemo } from 'react';

// API REQUESTS
import { fetchCompOverviewData } from 'api';

import { OVERVIEW_TABS, COMP_INTEL_AD_TYPES } from '../../constants/comp-intel';

// COMPONENTS
import OutletSpinner from '../OutletSpinner';
import TooltipPopover from 'components/TooltipPopover';
import OverviewChart from './OVComponents/OverviewChart';
import OverviewLineChart from './OVComponents/OverviewLineChart';

// TYPES
import { OverviewTab, CompIntelAdType } from '../../types';

// UTILS
import {
  downloadImage,
  filterDailyData,
  downloadChartData,
  filterOverviewData,
  downloadCoverageCompData,
} from 'utils';

// COMP. INTEL OVERVIEW COMPONENT
function CompIntelOverview() {
  const {
    dateRange,
    competitorOptions,
    selectedCompetitors,
    selectedSearchOptions,
    setCompetitorOptions,
  } = useCmpIntContext();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [view, setView] = useState<'Desktop' | 'Mobile'>('Desktop');

  const [activeTab, setActiveTab] = useState<OverviewTab>('Coverage');
  const [selectedFilters, setSelectedFilters] = useState<CompIntelAdType[]>([
    'PAID',
  ]);

  // FETCH COMPETITOR CHART (BAR) DATA
  const { data: compOverviewData, isLoading: isLoadingOverviewData } = useQuery(
    [
      'compCompetitorsData',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedSearchOptions.terms,
    ],
    () => fetchCompOverviewData(dateRange, selectedSearchOptions.terms, false),
    {
      enabled: !!dateRange && !!competitorOptions.length,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  // FETCH DAILY CHART (LINE) DATA
  const {
    data: compDailyData,
    isLoading: isLoadingDailyData,
    // error: compDataDailyError,
  } = useQuery(
    [
      'compDailyData',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedSearchOptions.terms,
    ],
    () => fetchCompOverviewData(dateRange, selectedSearchOptions.terms, true),
    {
      enabled: !!dateRange && !!competitorOptions.length,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  // SET UNIQUE COMPETITOR (DOMAIN) OPTIONS BASED ON THOSE FOUND IN COMPETITOR GROUPS & DATA
  useEffect(() => {
    setCompetitorOptions((prev) => {
      return Array.from(
        new Set([
          ...prev,
          ...(compOverviewData?.unique_brands || []),
          ...(compDailyData?.unique_brands || []),
        ])
      );
    });
  }, [compOverviewData, compDailyData, setCompetitorOptions]);

  // FILTERED DAILY CHART DATA
  const filteredDailyData = useMemo(
    () => filterDailyData(compDailyData, selectedCompetitors),
    [compDailyData, selectedCompetitors]
  );

  // FILTERED COMPETITOR CHART DATA
  const filteredOverviewData = useMemo(
    () => filterOverviewData(compOverviewData, selectedCompetitors),
    [compOverviewData, selectedCompetitors]
  );

  // HANDLE FILTER TOGGLE (updated to support single selection)
  const toggleFilter = (filter: CompIntelAdType) => {
    setSelectedFilters([filter]); // Replace with the newly selected filter
  };

  // DROWDOWN (IS THIS STILL NEEDED?)
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadChartImage = () => {
    downloadImage('CompetitiveIntelligenceOverview');
  };

  // JSX
  return (
    <div
      id="CompetitiveIntelligenceOverview"
      className="mb-4 bg-light border rounded-2xl py-2"
    >
      <div className="flex justify-between items-center mb-2 px-4">
        <div>
          <h2 className="text-2xl font-bold">Overview</h2>
          <p className="text-gray800 text-sm font-bold mt-[2px]">
            How the competition and market is evolving
          </p>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-CompetitiveIntelligenceOverview"
        >
          <div className="flex items-center mr-4 text-xs">
            <span
              className={
                view === 'Desktop'
                  ? 'text-primarygray mr-2'
                  : 'text-gray400 mr-2'
              }
            >
              Desktop
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={view === 'Mobile'}
                onChange={() =>
                  setView(view === 'Desktop' ? 'Mobile' : 'Desktop')
                }
              />
              <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
            </label>
            <span
              className={
                view === 'Mobile'
                  ? 'text-primarygray ml-2'
                  : 'text-gray400 ml-2'
              }
            >
              Mobile
            </span>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 ml-2 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    closeDropdown();
                    downloadChartData({
                      activeTab,
                      filters: selectedFilters,
                      overviewData: filteredOverviewData,
                      device: view.toLowerCase() as 'desktop' | 'mobile',
                    });
                    downloadCoverageCompData({
                      activeTab,
                      filters: selectedFilters,
                      dailyData: filteredDailyData,
                      device: view.toLowerCase() as 'desktop' | 'mobile',
                    });
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <hr className="border-success mb-6" />
      <div className="flex flex-col lg:flex-row mb-4">
        <div className="flex justify-between items-center p-2 w-full px-4 whitespace-nowrap flex-wrap gap-4">
          <div className="flex space-x-2 bg-fourthgray py-1 px-1 rounded-lg">
            {OVERVIEW_TABS.map((tab) => (
              <TooltipPopover
                key={tab}
                content={`competitive_intelligence_page.overview_tab.${tab
                  .replace('# ', '')
                  .replace(' ', '_')
                  .toLowerCase()}`}
                type="popup"
                side="bottom"
                tooltipWidth="max-w-64"
              >
                <div key={tab} className="relative group inline-block">
                  <button
                    className={`${
                      activeTab === tab
                        ? 'px-4 py-[9px] rounded bg-light text-primarygray'
                        : 'text-thirdgray px-4 py-[9px]'
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                </div>
              </TooltipPopover>
            ))}
          </div>
          <div className="flex space-x-1">
            {COMP_INTEL_AD_TYPES.map((filter) => (
              <TooltipPopover
                key={filter}
                content={`competitive_intelligence_page.overview_tab.${filter.toLowerCase()}`}
                type="popup"
                side="top"
              >
                <span
                  key={filter}
                  className={`text-thirdgray rounded px-3 py-1 cursor-pointer ${
                    selectedFilters.includes(filter)
                      ? 'border border-success rounded'
                      : 'border border-disgreen100 rounded'
                  }`}
                  onClick={() => toggleFilter(filter)} // Call the updated toggleFilter
                >
                  {filter}
                  {selectedFilters.includes(filter) && (
                    <span className="ml-1">✕</span>
                  )}
                </span>
              </TooltipPopover>
            ))}
          </div>
        </div>
      </div>
      {isLoadingOverviewData ? (
        <div className="py-10 mb-8">
          <OutletSpinner />
        </div>
      ) : filteredOverviewData ? (
        <OverviewChart
          data={filteredOverviewData}
          device={view.toLowerCase()}
          activeTab={activeTab}
          selectedFilters={selectedFilters}
          competitorsToBeIncluded={selectedCompetitors}
        />
      ) : (
        <div>No data available.</div>
      )}

      {isLoadingDailyData ? (
        <div className="py-10 mb-8">
          <OutletSpinner />
        </div>
      ) : filteredDailyData ? (
        <OverviewLineChart
          data={filteredDailyData}
          device={view.toLowerCase()}
          activeTab={activeTab}
          selectedFilters={selectedFilters}
          competitorsToBeIncluded={selectedCompetitors}
        />
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}

export default CompIntelOverview;
