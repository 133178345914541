import React, { useRef } from 'react';
import { Chart as ChartJS, registerables, ChartData, ChartOptions } from 'chart.js';
import { Chart as ReactChart } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend from 'components/consumer_int_comp/RC_comp/CustomLegend';

ChartJS.register(...registerables);

interface MindsetData {
  Mindset: string;
  total_mindset_demand: number;
  demand_percentage: number;
}

interface RetailMindsetData {
  Mindset: string;
  total_retail_demand: number;
  retail_demand_percentage: number;
}

interface MindsetBarChartData {
  total_demand_per_mindset: MindsetData[];
  total_retail_demand_per_mindset: RetailMindsetData[];
}

interface RCBarChartProps {
  data: MindsetBarChartData;
}

const scatterRedrawPlugin = {
  id: 'scatterRedraw',
  afterDatasetsDraw(chart: ChartJS<'bar' | 'scatter'>) {
    const { ctx } = chart;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (dataset.type === 'scatter') {
        const meta = chart.getDatasetMeta(datasetIndex);
        meta.data.forEach((element) => {
          const { x: pointX, y: pointY } = element.getProps(['x', 'y'], true);
          ctx.beginPath();
          ctx.arc(pointX, pointY, 6, 0, 2 * Math.PI);
          ctx.fillStyle = 'rgba(255, 255, 255, 1)';
          ctx.fill();
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(89, 89, 89, 0.80)';
          ctx.stroke();
        });
      }
    });
  }
};

const RCBarChart: React.FC<RCBarChartProps> = ({ data }) => {
  const chartRef = useRef<ChartJS<'bar' | 'scatter'> | null>(null);

    // Filter and map the data
    const filteredData = data.total_demand_per_mindset
    .filter(item => item.Mindset !== 'Uncategorized')
    .map(item => ({
      ...item,
      Mindset: item.Mindset === 'Return' ? 'Enjoy' : item.Mindset,
    }));
  
  const filteredRetailData = data.total_retail_demand_per_mindset
    .filter(item => item.Mindset !== 'Uncategorized')
    .map(item => ({
      ...item,
      Mindset: item.Mindset === 'Return' ? 'Enjoy' : item.Mindset,
      retail_demand_percentage: parseFloat(item.retail_demand_percentage.toFixed(2)),
    }));

  const labels = filteredData.map(item => item.Mindset);
  const demandData = filteredRetailData.map(item => item.total_retail_demand);
  const retailDemandData = filteredRetailData.map(item => item.retail_demand_percentage);
  const chartWidth = (chartRef.current as HTMLElement | null)?.offsetWidth || 800;
  const calculatedBarThickness = Math.max(10, chartWidth / (labels.length * 2));

  const DemandData: ChartData<'bar' | 'scatter'> = {
    labels,
    datasets: [
      {
        type: 'bar' as const,
        label: 'Demand',
        data: demandData,
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: calculatedBarThickness,
        order: 1,
        yAxisID: 'y1',
      },
      {
        type: 'scatter' as const,
        label: '% Retail Demand',
        data: retailDemandData.map((value, index) => ({ x: index, y: value })),
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'rgba(89, 89, 89, 0.80)',
        borderWidth: 1,
        pointRadius: 6,
        pointHoverRadius: 8,
        order: 2,
        yAxisID: 'y',
      },
    ],
  };

  const formatYAxisTick = (tickValue: number) => {
    if (tickValue >= 1_000_000) {
      return `${(tickValue / 1_000_000).toFixed(1)}M`;
    } else if (tickValue >= 1_000) {
      return `${(tickValue / 1_000).toFixed(1)}K`;
    } else {
      return tickValue.toString();
    }
  };

  const options: ChartOptions<'bar' | 'scatter'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mindset',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
          maxRotation: 90,
          minRotation: 45,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: '% Retail Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
          align: 'center',
          callback: function (value) {
            return value + '%';
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        afterBuildTicks: function(scale) {
          const maxTicks = 10;
          const max = Math.max(...scale.ticks.map(tick => tick.value));
          const stepSize = Math.ceil(max / maxTicks);
          scale.ticks = Array.from({ length: maxTicks + 1 }, (_, i) => ({ value: i * stepSize }));
          scale.max = maxTicks * stepSize;
        },
      },
      y1: {
        position: 'right',
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        afterBuildTicks: function(scale) {
          const maxTicks = 10;
          const max = Math.max(...scale.ticks.map(tick => tick.value));
          const stepSize = Math.ceil(max / maxTicks);
          scale.ticks = Array.from({ length: maxTicks + 1 }, (_, i) => ({ value: i * stepSize }));
          scale.max = maxTicks * stepSize;
        },
      },
    },
  };

  return (
    <div className="bg-light rounded-2xl">
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <ReactChart type='bar' data={DemandData} options={options} plugins={[scatterRedrawPlugin]} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <div className="flex items-center">
            <div></div>
            <CustomLegend legendSet="set1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RCBarChart;