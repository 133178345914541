import React from 'react';
import TooltipPopover from 'components/TooltipPopover';

interface LegendItem {
  label: string;
  content?: string;
  icon: string;
}

interface LegendsData {
  [key: string]: LegendItem[];
}

const legendsData: LegendsData = {
  set1: [
    { label: '% Retail Demand', content:"consumer_intent_page.retailer_comparison_tab.retailer_overview.percentage_retailer_demand", icon: `${process.env.PUBLIC_URL}/static/img/circle_rc.svg` },
    { label: 'Demand', content:"consumer_intent_page.retailer_comparison_tab.retailer_overview.demand", icon: `${process.env.PUBLIC_URL}/static/img/organic.svg` },
  ],
  set2: [
    { label: 'Overall Demand', icon: `${process.env.PUBLIC_URL}/static/img/total_ao.svg` },
    { label: 'Demand', icon: `${process.env.PUBLIC_URL}/static/img/organic_ao.svg` },
    { label: 'Retailer Demand', icon: `${process.env.PUBLIC_URL}/static/img/paid_ao.svg` },
  ],
  set3: [
    { label: 'Retail Demand', icon: `${process.env.PUBLIC_URL}/static/img/total_ao.svg` },
    { label: 'Demand', icon: `${process.env.PUBLIC_URL}/static/img/organic_ao.svg` },
  ],
  set4: [
    { label: 'Ebay', icon: `${process.env.PUBLIC_URL}/static/img/ebay.svg` },
    { label: 'Lowes', icon: `${process.env.PUBLIC_URL}/static/img/lowes.svg` },
    { label: 'Target', icon: `${process.env.PUBLIC_URL}/static/img/target.svg` },
    { label: 'Costco', icon: `${process.env.PUBLIC_URL}/static/img/costco.svg` },
    { label: 'Walmart', icon: `${process.env.PUBLIC_URL}/static/img/walmart.svg` },
    { label: 'Best Buy', icon: `${process.env.PUBLIC_URL}/static/img/bestbuy.svg` },
    { label: 'Home Depot', icon: `${process.env.PUBLIC_URL}/static/img/homedepot.svg` },
    { label: 'Amazon', icon: `${process.env.PUBLIC_URL}/static/img/amazon.svg` },
  ],
};

interface CustomLegendProps {
  legendSet: keyof LegendsData;
}

const CustomLegend: React.FC<CustomLegendProps> = ({ legendSet }) => {
  const legends = legendsData[legendSet];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '10px'
      }}
    >
      {legends.map((legend, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '5px'
          }}
        >
          <img
            src={legend.icon}
            alt={legend.label}
            style={{ width: '16px', height: '16px', marginRight: '10px' }}
          />
          <TooltipPopover
            key={index}
            type="dashed"
            content={legend.content}
            side="top"
          >
            <span>{legend.label}</span>
          </TooltipPopover>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;