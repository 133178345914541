import { useOutletContext } from 'react-router-dom';

// Types
import { CompIntSearchOptions } from 'types';
import { TimeFrameDateRange } from '../components/TimeFramePicker';

export type CompIntContextState = {
  dateRange: TimeFrameDateRange;
  clientDomain: string;
  competitorOptions: string[];
  selectedCompetitors: string[];
  selectedSearchOptions: CompIntSearchOptions;
  setCompetitorOptions: React.Dispatch<React.SetStateAction<string[]>>;
};

export const useCmpIntContext = () => useOutletContext<CompIntContextState>();
