import ReportsDownload from '../ReportsDownload';

// TYPES
import { CompIntelReport } from 'api';
import { DropdownOption } from '../Dropdown';

const REPORT_OPTIONS: DropdownOption<CompIntelReport>[] = [
  { label: 'Battlefield', value: 'battlefield-report' },
  { label: 'Brands', value: 'brands-report' },
  { label: 'Content', value: 'content-report' },
  { label: 'Search Term', value: 'search-term-report' },
];

// COMPETITIVE INTEL REPORTS TAB/PAGE
function CompIntelReports() {
  return (
    <ReportsDownload
      options={REPORT_OPTIONS}
      module="Competitive Intelligence"
    />
  );
}

export default CompIntelReports;
