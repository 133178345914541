import { APP_ROUTES } from '../constants';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import TooltipPopover from './TooltipPopover';

interface TopBarProps {
  isSidebarOpen: boolean;
  button: React.ReactNode;
}

const tooltipTexts: { [key: string]: string } = {
  '/auto_optimize': 'auto_optimize_page.increased_holistic_brand_efficiency',
  '/insights': 'Daily data updates.',
  '/resources': 'resources_page.definitions_and_faq',
  '/insights/overview':
    'insights_page.holistic_search_data_and_recommendations',
  '/insights/cluster': 'insights_page.holistic_search_data_and_recommendations',
  '/insights/recommendations':
    'insights_page.holistic_search_data_and_recommendations',
};

function TopBar({ isSidebarOpen, button }: TopBarProps) {
  const { pathname } = useLocation();

  let pageTitle = '';
  let titleDesc: React.ReactNode;
  let showTooltip = false;
  let tooltipText = '';
  let tooltipClassName = '';

  switch (pathname) {
    case APP_ROUTES.HOME:
      pageTitle = 'Home';
      titleDesc =
        'Explore tactics that can help you uncover insights to maximize your account performance.';
      break;

    case APP_ROUTES.CONSUMER_INTENT:
    case APP_ROUTES.CON_INTENT_OVERVIEW:
    case APP_ROUTES.CON_INTENT_PFA:
    case APP_ROUTES.CON_INTENT_RETAILER_COMP:
    case APP_ROUTES.CON_INTENT_BRAND_COMP:
    case APP_ROUTES.CON_INTENT_QUERY_VIEW:
      pageTitle = 'Consumer Intent';
      titleDesc =
        'Search Intelligence to fuel consumer and trend driven strategies.';
      break;

    case APP_ROUTES.COMPETITIVE_INTEL:
    case APP_ROUTES.COMP_INTEL_OVERVIEW:
    case APP_ROUTES.COMP_INTEL_BRAND_FOCUS:
    case APP_ROUTES.COMP_INTEL_CONTENT_ANALYSIS:
    case APP_ROUTES.COMP_INTEL_REPORTS:
      pageTitle = 'Competitive Intelligence';
      titleDesc =
        'Competitor and market insights to understand the competitive landscape.';
      break;
    case APP_ROUTES.AUTO_OPTIMIZE:
    case APP_ROUTES.AUTO_OPTIMIZE_REPORTS:
    case APP_ROUTES.AUTO_OPTIMIZE_OVERVIEW:
      pageTitle = 'Auto Optimize';
      titleDesc = 'Increase holistic brand efficiency.';
      showTooltip = true;
      tooltipText = tooltipTexts['/auto_optimize'];
      tooltipClassName = '-top-[2px] right-[-15px]';
      break;
    case APP_ROUTES.INSIGHTS:
    case APP_ROUTES.INSIGHTS_OVERVIEW:
    case APP_ROUTES.INSIGHTS_CLUSTER:
    case APP_ROUTES.INSIGHTS_RECOMMENDATIONS:
    case APP_ROUTES.INSIGHTS_CONFIGURATION:
      pageTitle = 'Insights';
      titleDesc = 'Holistic search data and recommendations.';
      showTooltip = true;
      tooltipText = tooltipTexts[location.pathname];
      tooltipClassName = '-top-[2px] right-[-15px]';
      break;
    case APP_ROUTES.SETTINGS:
      pageTitle = 'Settings';
      titleDesc = 'Overview of your settings.';
      break;
    case APP_ROUTES.ON_BOARD:
      pageTitle = 'Onboarding';
      titleDesc = 'Setup and integration of search tools.';
      break;
    case APP_ROUTES.RESOURCES:
      pageTitle = 'Resources';
      titleDesc =
        'The most important data definitions and frequently asked questions.';
      showTooltip = true;
      tooltipText = tooltipTexts[location.pathname];
      tooltipClassName = '-top-[2px] right-[-15px]';
      break;
    case APP_ROUTES.USECASES:
      pageTitle = 'Use Cases';
      titleDesc = "Use cases for the platform's capabilities.";
      break;
    case APP_ROUTES.NO_ACCESS:
      pageTitle =
        'You do not have access - Contact support to set up your user.';
      titleDesc = (
        <>
          <p>Please reach out to your regional enablement team:</p>
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            <li>
              US:{' '}
              <a
                href="mailto:onesearch_enablement_us@publicismedia.com"
                style={{ color: '#007bff', textDecoration: 'underline' }}
              >
                onesearch_enablement_us@publicismedia.com
              </a>
            </li>
            <li>
              EMEA:{' '}
              <a
                href="mailto:onesearch_enablement_EMEA@publicisgroupe.net"
                style={{ color: '#007bff', textDecoration: 'underline' }}
              >
                onesearch_enablement_EMEA@publicisgroupe.net
              </a>
            </li>
            <li>
              All others:{' '}
              <a
                href="mailto:onesearch_global@performics.com"
                style={{ color: '#007bff', textDecoration: 'underline' }}
              >
                onesearch_global@performics.com
              </a>
            </li>
          </ul>
        </>
      );
      break;
    default:
      pageTitle = 'Page Not Found';
      titleDesc = 'The page you are looking for does not exist.';
  }

  return (
    <>
      <Helmet>
        <title>OneSearch - {pageTitle}</title>
      </Helmet>
      <div
        className={`fixed top-0 ${
          location.pathname === '/please-wait' || location.pathname === '/final'
            ? 'right-0 w-full'
            : location.pathname === '/onboard'
            ? 'right-[280px] w-[calc(100vw-280px)]'
            : isSidebarOpen
            ? 'left-[280px] w-[calc(100vw-280px)]'
            : 'left-[60px] w-[calc(100vw-60px)]'
        } bg-light shadow-md p-6 z-50 flex justify-between items-center transition-all duration-300`}
      >
        <div>
          <div className="text-primarygray font-semibold text-base">
            {pageTitle}
          </div>
          <div className="flex items-center text-gray400 font-medium text-sm">
            {showTooltip ? (
              <TooltipPopover
                content={tooltipText}
                type="info"
                side="top"
                className={tooltipClassName}
              >
                <span>{titleDesc}</span>
              </TooltipPopover>
            ) : (
              <span>{titleDesc}</span>
            )}
          </div>
        </div>
        {button && <div>{button}</div>}
      </div>
    </>
  );
}

export default TopBar;
