import 'chartjs-adapter-date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import {
  Chart,
  Scale,
  ChartDataset,
  registerables,
  Chart as ChartJS,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import LoadingSpinner from './LoadingSpinner';
import TooltipPopover from 'components/TooltipPopover';

Chart.register(...registerables);

// Define and register the custom plugin
const extendedGridLinePlugin = {
  id: 'extendedGridLinePlugin',
  afterDraw: (chart: ChartJS) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales['x'] as Scale;
    const yAxis = chart.scales['y'] as Scale;

    // Define the color for the extended part of the grid
    const extendedColor = '#333333';

    // Draw extended grid lines for x-axis ticks
    xAxis.ticks.forEach((_, index: number) => {
      const x = xAxis.getPixelForTick(index);
      ctx.save();
      ctx.strokeStyle = extendedColor;
      ctx.lineWidth = 1;
      ctx.setLineDash([]);
      ctx.beginPath();
      ctx.moveTo(x, yAxis.bottom);
      ctx.lineTo(x, yAxis.bottom + 10); // Extend the grid line by 10 pixels
      ctx.stroke();
      ctx.restore();
    });

    // Draw extended grid lines for y-axis ticks
    yAxis.ticks.forEach((_, index: number) => {
      const y = yAxis.getPixelForTick(index);
      ctx.save();
      ctx.strokeStyle = extendedColor;
      ctx.lineWidth = 1;
      ctx.setLineDash([]);
      ctx.beginPath();
      ctx.moveTo(xAxis.left, y);
      ctx.lineTo(xAxis.left - 10, y); // Extend the grid line by 10 pixels
      ctx.stroke();
      ctx.restore();
    });
  },
};

Chart.register(extendedGridLinePlugin);

interface ConsumerData {
  labels: string[];
  datasets: ChartDataset<'line'>[];
}

interface Info {
  demand: string;
  demandChange: string;
  uniqueQueryCount: string;
  uniqueQueryChange: string;
  yoyGrowth: string;
  yoyGrowthChange: string;
}

interface ConsumerIntentProps {
  consumer_data?: ConIntentHomeData;
  error?: unknown;
  isLoading?: boolean;
}

function ConsumerIntent({
  consumer_data,
  error,
  isLoading,
}: ConsumerIntentProps) {
  const [consumerBlur, setBlur] = useState(false);

  useEffect(() => {
    if (!consumer_data) return;

    setBlur(consumer_data.consumerBlur);
  }, [consumer_data]);

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/consumer_intent');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error fetching consumer data: {(error as Error).message}</div>;
  }

  if (!consumer_data || consumer_data?.data.length === 0) {
    return <div></div>;
  }

  const labels = consumer_data.data.map(
    (item: { label: string; value: number }) => item.label
  );
  const values = consumer_data.data.map(
    (item: { label: string; value: number }) => item.value
  );

  const chartData: ConsumerData = {
    labels: labels,
    datasets: [
      {
        label: 'Demand',
        data: values,
        borderColor: '#222222CC',
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        fill: false,
        pointRadius: 2,
        pointBackgroundColor: '#FFFFFF',
        pointBorderWidth: 1,
        borderWidth: 1,
        tension: 0.0,
      },
    ],
  };

  const info: Info = {
    demand: consumer_data.details.demand.title,
    demandChange: consumer_data.details.demand.percentage.toString(),
    uniqueQueryCount: consumer_data.details.uqc.title,
    uniqueQueryChange: consumer_data.details.uqc.percentage.toString(),
    yoyGrowth: consumer_data.details.yoy.title,
    yoyGrowthChange: consumer_data.details.yoy.percentage.toString(),
  };

  const formatNumber = (num: number): string => {
    const thresholds = [
      { value: 1e9, suffix: 'B' },
      { value: 1e6, suffix: 'M' },
      { value: 1e3, suffix: 'K' },
    ];

    for (const threshold of thresholds) {
      if (num >= threshold.value) {
        return (num / threshold.value).toFixed(1) + threshold.suffix;
      }
    }

    return num.toString();
  };

  return (
    <div className="bg-light rounded-lg shadow-md h-[413px] w-full lg:h-auto">
      {consumerBlur && (
        <span className="block bg-light text-primarygray p-4 mb-4 rounded">
          NO DATA FOR THIS PRODUCT
        </span>
      )}
      <div className={`relative ${consumerBlur ? 'filter blur-sm' : ''}`}>
        <div className="px-4 py-2 flex justify-between items-center border-b border-success">
          <div>
              <TooltipPopover 
                content='homepage.consumer_intent.consumer_intent' 
                type='info'
                className='top-1 right-8'
                tooltipWidth='max-w-80'
                side='top'
              >
              <h3
                className="text-primarygray text-2xl font-bold cursor-pointer tracking-[0.48px]"
                onClick={handleRedirect}
              >
                Consumer Intent
              </h3>
              </TooltipPopover>
            <h6 className="text-sm text-gray400 font-semibold flex items-center">
              Consumer insights and market trends
            </h6>
          </div>
          <h6 className="text-sm font-semibold text-gray400 mt-8">
            Past 12 months
          </h6>
        </div>
        <div className="flex">
          <div className="py-6 px-4 flex-1 min-w-[200px] flex flex-col justify-center">
            <div className="mb-4">
              <TooltipPopover 
                content='homepage.consumer_intent.demand' 
                type='dashed'
                side='top'
                className='underline decoration-dashed decoration-1 w-fit'
              >
              <h6 className="text-sm font-medium text-thirdgray w-fit">Demand</h6>
              </TooltipPopover>
              <div className="flex items-center mt-2">
                <h3 className="text-primarygray text-3xl font-medium">
                  {info.demand}
                </h3>
                <div
                  className={`flex justify-center items-center ml-1 ${
                    info.demandChange === 'N/A' || info.demandChange === '0%'
                      ? 'text-gray500'
                      : info.demandChange.startsWith('-')
                      ? 'text-error'
                      : 'text-success'
                  }`}
                >
                  {info.demandChange !== 'N/A' &&
                    info.demandChange !== '0%' && (
                      <img
                        src={
                          info.demandChange.startsWith('-')
                            ? `${process.env.PUBLIC_URL}/static/img/Arrow_negative.svg`
                            : `${process.env.PUBLIC_URL}/static/img/Arrow_positive.svg`
                        }
                        alt="Arrow Icon"
                      />
                    )}
                  <h6 className="text-sm font-medium">
                    {info.demandChange === 'N/A' || info.demandChange === '0%'
                      ? info.demandChange
                      : `${info.demandChange.startsWith('-') ? '' : '+'}${
                          info.demandChange
                        }`}
                  </h6>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <TooltipPopover 
                content='homepage.consumer_intent.unique_query_count' 
                type='dashed'
                side='top'
                className='underline decoration-dashed decoration-1 w-fit'
              >
              <h6 className="text-sm font-medium text-thirdgray w-fit">
                Unique Query Count
              </h6>
              </TooltipPopover>
              <div className="flex items-center mt-2">
                <h3 className="text-primarygray text-3xl font-medium">
                  {info.uniqueQueryCount}
                </h3>
                <div
                  className={`flex justify-center items-center ml-1 ${
                    info.uniqueQueryChange === 'N/A' ||
                    info.uniqueQueryChange === '0%'
                      ? 'text-gray500'
                      : info.uniqueQueryChange.startsWith('-')
                      ? 'text-error'
                      : 'text-success'
                  }`}
                >
                  {info.uniqueQueryChange !== 'N/A' &&
                    info.uniqueQueryChange !== '0%' && (
                      <img
                        src={
                          info.uniqueQueryChange.startsWith('-')
                            ? `${process.env.PUBLIC_URL}/static/img/Arrow_negative.svg`
                            : `${process.env.PUBLIC_URL}/static/img/Arrow_positive.svg`
                        }
                        alt="Arrow Icon"
                      />
                    )}
                  <h6 className="text-sm font-medium">
                    {info.uniqueQueryChange === 'N/A' ||
                    info.uniqueQueryChange === '0%'
                      ? info.uniqueQueryChange
                      : `${info.uniqueQueryChange.startsWith('-') ? '' : '+'}${
                          info.uniqueQueryChange
                        }`}
                  </h6>
                </div>
              </div>
            </div>
            <div>
              <TooltipPopover 
                content='homepage.consumer_intent.yoy_growth' 
                type='dashed'
                side='top'
                className='underline decoration-dashed decoration-1 w-fit'
              >
              <h6 className="text-sm font-medium text-thirdgray w-fit">YoY Growth</h6>
              </TooltipPopover>
              <div className="flex items-center mt-2">
                <h3 className="text-primarygray text-3xl font-medium">
                  {info.yoyGrowth}
                </h3>
                <div
                  className={`flex justify-center items-center ml-1 ${
                    info.yoyGrowthChange === 'N/A' ||
                    info.yoyGrowthChange === '0%'
                      ? 'text-gray500'
                      : info.yoyGrowthChange.startsWith('-')
                      ? 'text-error'
                      : 'text-success'
                  }`}
                >
                  {info.yoyGrowthChange !== 'N/A' &&
                    info.yoyGrowthChange !== '0%' && (
                      <img
                        src={
                          info.yoyGrowthChange.startsWith('-')
                            ? `${process.env.PUBLIC_URL}/static/img/Arrow_negative.svg`
                            : `${process.env.PUBLIC_URL}/static/img/Arrow_positive.svg`
                        }
                        alt="Arrow Icon"
                      />
                    )}
                  <h6 className="text-sm font-medium">
                    {info.yoyGrowthChange === 'N/A' ||
                    info.yoyGrowthChange === '0%'
                      ? info.yoyGrowthChange
                      : `${info.yoyGrowthChange.startsWith('-') ? '' : '+'}${
                          info.yoyGrowthChange
                        }`}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 py-6 px-4">
            <div className="w-[307px] h-[251px] 2xl:w-[370px] 3xl:w-[500px]">
              <Line
                data={chartData}
                options={{
                  scales: {
                    x: {
                      type: 'time',
                      time: {
                        unit: 'month',
                        displayFormats: {
                          month: 'MM/yy',
                        },
                      },
                      ticks: {
                        display: true,
                        color: '#333333',
                        maxRotation: 0,
                        minRotation: 0,
                        font: {
                          size: 12, // Set font size
                          style: 'normal', // Set font style
                          weight: 400, // Set font weight
                          lineHeight: 1.33, // Set line height (16px / 12px = 1.33)
                        },
                      },
                      border: {
                        color: '#333333',
                        display: true,
                      },
                      grid: {
                        color: '#B3B3B3',
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Demand',
                        font: {
                          size: 14, // Set font size
                          style: 'normal', // Set font style
                          weight: 700, // Set font weight
                          lineHeight: 1.28, // Set line height (18px / 14px = 1.28)
                        },
                        align: 'center',
                      },
                      ticks: {
                        display: true,
                        color: '#000', // Set color for y-axis ticks
                        callback: function (value) {
                          const numericValue = Number(value);
                          return formatNumber(numericValue);
                        },
                        font: {
                          size: 12, // Set font size
                          style: 'normal', // Set font style
                          weight: 400, // Set font weight
                          lineHeight: 1.33, // Set line height (16px / 12px = 1.33)
                        },
                        align: 'center',
                      },
                      border: {
                        color: '#333333',
                        display: true,
                      },
                      grid: {
                        color: '#B3B3B3', // Display horizontal grid lines
                      },
                    },
                  },
                  plugins: {
                    legend: { display: false },
                    // extendedGridLinePlugin: true // Enable the custom plugin
                  },
                  interaction: {
                    mode: 'index',
                    intersect: false,
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex px-4 py-2 justify-end">
          <a
            className="inline-flex items-center w-[110px] h-[30px] justify-center text-thirdgray text-sm font-normal bg-fourthgray rounded-[8px] cursor-pointer"
            onClick={handleRedirect}
          >
            See more
            <img
              src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
              className="w-4 h-4 bg-contain bg-center ml-1"
              alt="right-arrow"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ConsumerIntent;
