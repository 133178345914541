import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend3 from './CustomLegend3';
import { ChartOptions } from 'chart.js';

interface TimeSeriesData {
  period_formatted: string;
  total_demand: number;
  total_brand_demand: number;
}

interface BrandData {
  label: string;
  content: string;
  color: string;
  visibility: boolean;
}

interface BCLineChartProps {
  data: {
    total_demand_per_period: TimeSeriesData[];
  };
}

const formatYAxisTick = (tickValue: number) => {
  if (tickValue >= 1_000_000) {
    return `${(tickValue / 1_000_000).toFixed(1)}M`;
  } else if (tickValue >= 1_000) {
    return `${(tickValue / 1_000).toFixed(1)}K`;
  } else {
    return tickValue.toString();
  }
};

const BCLineChart: React.FC<BCLineChartProps> = ({ data }) => {
  const labels = data.total_demand_per_period.map(period => period.period_formatted);

  const initialBrandsData: BrandData[] = [
    { label: 'Brand Demand', content:'consumer_intent_page.brand_comparison_tab.share_of_search.brand_demand', color: '#222222', visibility: true },
    { label: 'Total Demand', content:'consumer_intent_page.brand_comparison_tab.share_of_search.total_demand', color: '#595959', visibility: true },
  ];

  const [brandsData, setBrandsData] = useState(initialBrandsData);

  const handleLegendClick = (label: string) => {
    setBrandsData(brandsData.map(brand => (
      brand.label === label ? { ...brand, visibility: !brand.visibility } : brand
    )));
  };

  const visibility = brandsData.reduce((acc, brand) => {
    acc[brand.label] = brand.visibility;
    return acc;
  }, {} as Record<string, boolean>);

  const visibleDatasets = brandsData
    .filter(brand => brand.visibility)
    .map(brand => {
      if (brand.label === 'Brand Demand') {
        return {
          label: brand.label,
          data: data.total_demand_per_period.map(period => period.total_brand_demand),
          borderColor: brand.color,
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: brand.color,
          pointRadius: 1.5,
          tension: 0.4,
          yAxisID: 'y',
        };
      } else {
        return {
          label: brand.label,
          data: data.total_demand_per_period.map(period => period.total_demand),
          borderColor: brand.color,
          borderWidth: 1.5,
          fill: false,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: brand.color,
          pointRadius: 1.5,
          tension: 0.4,
          yAxisID: 'y1',
        };
      }
    });

  const chartData = {
    labels: labels,
    datasets: visibleDatasets,
  };

  const maxDataValue = Math.max(
    ...data.total_demand_per_period.map(period => Math.max(period.total_demand, period.total_brand_demand))
  );

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        min: 0,
        max: maxDataValue,
      },
      y1: {
        position: 'right',
        title: {
          display: true,
          text: 'Demand',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              return formatYAxisTick(tickValue);
            }
            return tickValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        min: 0,
        max: maxDataValue,
      },
    },
  };

  return (
    <div className="bg-light rounded-2xl">
      <div className="flex relative">
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%]">
          <Line data={chartData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <CustomLegend3
            brandsData={brandsData}
            onLegendClick={handleLegendClick}
            visibility={visibility}
          />
        </div>
      </div>
    </div>
  );
};

export default BCLineChart;
