import 'chart.js/auto';
import React, { useState, useRef, useEffect } from 'react';

// COMPONENTS
import { Bar } from 'react-chartjs-2';
import TooltipPopover from '../../TooltipPopover';

// UTILS
import { downloadImage, downloadXLS } from 'utils/download';

// TYPES
import { ChartOptions, ChartData } from 'chart.js';
import { NewAds } from '../../../types/comp-intel/comp-intel-content-analysis';

interface AdData {
  [key: string]: number;
}

interface NewAdsProps {
  data: NewAds;
}

const TOOLTIP =
  'The number of ads run by competitors can be important due to responsive search ads and other automated ad formats.';

function NewAdsSeenLast7Days({ data }: NewAdsProps) {
  const [view, setView] = useState<'desktop' | 'mobile'>('desktop');
  const [selectedFilter, setSelectedFilter] = useState<
    'PAID' | 'PLA' | 'ORGANIC'
  >('PAID');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  let currentData: AdData = {};
  if (selectedFilter === 'PAID') {
    currentData = data['SEM']?.[view] ?? {};
  } else if (selectedFilter === 'PLA') {
    currentData = data['PLA']?.[view] ?? {};
  } else if (selectedFilter === 'ORGANIC') {
    currentData = data['SEO']?.[view] ?? {};
  }

  const calculateBarThickness = () => {
    const chartWidth = chartContainerRef.current?.offsetWidth || 800;
    const numBars = Object.keys(currentData).length;
    return Math.max(10, chartWidth / (numBars * 2));
  };

  const calculatedBarThickness = calculateBarThickness();

  const allData = {
    labels: Object.keys(currentData),
    datasets: [
      {
        label: 'New Ads',
        data: {
          PAID: Object.values(currentData),
          PLA: Object.values(currentData),
          ORGANIC: Object.values(currentData),
        },
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 1,
        borderRadius: 8,
        barThickness: calculatedBarThickness,
      },
    ],
  };

  const getFilteredData = (
    data: typeof allData,
    filter: 'PAID' | 'PLA' | 'ORGANIC'
  ): ChartData<'bar'> => ({
    labels: data.labels,
    datasets: [
      {
        ...data.datasets[0],
        data: data.datasets[0].data[filter],
      },
    ],
  });

  const desktopData = getFilteredData(allData, selectedFilter);
  const mobileData = getFilteredData(allData, selectedFilter);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Competitor',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of ads',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFilterClick = (filter: 'PAID' | 'PLA' | 'ORGANIC') => {
    setSelectedFilter(filter);
  };

  const downloadChartImage = () => {
    downloadImage('Newadsseenlast7days');
  };

  const downloadChartData = () => {
    const chartData = view === 'desktop' ? desktopData : mobileData;

    const headers = ['Competitor', 'New Ads'];
    const labels = chartData.labels || [];
    const dataValues = chartData.datasets[0].data || [];

    const formatDataValues = dataValues.map((value) => {
      if (Array.isArray(value)) {
        return value[0];
      }
      return value !== null ? value : 0;
    });

    const columns = [formatDataValues];

    downloadXLS(
      `New_Ads_Seen_Last_7_Days_${view}_${selectedFilter}`,
      headers,
      labels as string[],
      columns
    );
  };

  return (
    <div
      id="Newadsseenlast7days"
      className="bg-light rounded-2xl shadow-sm mt-4"
    >
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <TooltipPopover
            type="info"
            content={TOOLTIP}
            className="top-2 right-12"
            tooltipWidth="max-w-96"
            side="top"
          >
            <h2 className="text-primarygray text-2xl font-bold pl-4 pt-2">
              New ads seen last 7 days{' '}
            </h2>
          </TooltipPopover>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">
            Understand number of ads run by chosen competitors
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-Newadsseenlast7days"
        >
          <div className="flex items-center mr-4 text-xs">
            <span
              className={
                view === 'desktop'
                  ? 'text-primarygray mr-2'
                  : 'text-gray400 mr-2'
              }
            >
              Desktop
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={view === 'mobile'}
                onChange={() =>
                  setView(view === 'desktop' ? 'mobile' : 'desktop')
                }
              />
              <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
            </label>
            <span
              className={
                view === 'mobile'
                  ? 'text-primarygray ml-2'
                  : 'text-gray400 ml-2'
              }
            >
              Mobile
            </span>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  View data table
                </li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <div
          ref={chartContainerRef}
          className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[295px]"
        >
          <Bar
            data={view === 'desktop' ? desktopData : mobileData}
            options={options}
          />
        </div>
        <div className="text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <div className="text-sm font-bold mb-2">Filter:</div>
          <div className="flex flex-wrap gap-2">
            {(['PAID', 'PLA', 'ORGANIC'] as const).map((filter) => (
              <TooltipPopover
                key={filter}
                content={`competitive_intelligence_page.content_analysis_tab.new_adds_seen_last_7_days.${filter.toLowerCase()}`}
                type="popup"
                side="top"
              >
                <span
                  key={filter}
                  className={`text-thirdgray rounded px-3 py-1 cursor-pointer ${
                    selectedFilter === filter
                      ? 'border border-success rounded-lg'
                      : 'border border-disgreen100 rounded'
                  }`}
                  onClick={() => handleFilterClick(filter)}
                >
                  {filter === 'PLA' ? 'SHOPPING' : filter}{' '}
                  {selectedFilter === filter && (
                    <span className="ml-1 ">✕</span>
                  )}
                </span>
              </TooltipPopover>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAdsSeenLast7Days;
