import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import LoadingSpinner from '../LoadingSpinner';
import MultiDropdown from './MultiDropdown';
import ArchiveUsers from './ArchiveUsers';

import {
  User,
  TableHeader,
  UserWithPassword,
  UsersManagementProps,
} from './types';

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

const fetchUsers = async (): Promise<User[]> => {
  const response = await fetch('/users/');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const users: (Omit<User, 'agencies' | 'clients' | 'markets'> & {
    agencies: Agency[];
    clients: Client[];
    markets: Market[];
  })[] = await response.json();

  return users
    .filter((user) => user.is_active)
    .map((user) => {
      const agencies = user.agencies.map((agency) => agency.name).join(', ');
      const clients = user.clients.map((client) => client.name).join(', ');
      const markets = user.markets.map((market) => market.name).join(', ');

      return {
        ...user,
        agencies: agencies,
        clients: clients,
        markets: markets,
      };
    });
};

const updateUser = async (user: User): Promise<User> => {
  const response = await fetch(`/update-user/${user.id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const createUser = async (user: Partial<User>): Promise<User> => {
  const response = await fetch('/create-user/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  return response.json();
};

const fetchAvailableAgencies = async () => {
  const response = await fetch('/get_available_agency/');
  if (!response.ok) {
    throw new Error('Failed to fetch available agencies, clients, and markets');
  }
  return response.json();
};

function UsersManagement({ loggedInUser }: UsersManagementProps) {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery<User[]>(
    'users',
    fetchUsers
  );
  const { data: availableData, isLoading: isLoadingAvailableData } = useQuery(
    'availableAgencies',
    fetchAvailableAgencies
  );

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

  const createUserMutation = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

  const [editUser, setEditUser] = useState<User | null>(null);
  const [newUser, setNewUser] = useState<UserWithPassword | null>(null);
  const [updatedUser, setUpdatedUser] = useState<User | null>(null);
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{
    key: keyof User;
    direction: 'asc' | 'desc';
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedNavigation, setSelectedNavigation] = useState<
    'Active' | 'Archive'
  >('Active');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set the number of users per page

  const handleNavChange = (navigation: 'Active' | 'Archive') => {
    setSelectedNavigation(navigation);
  };

  useEffect(() => {
    const handleAddNewUserEvent = () => {
      setNewUser({
        is_superuser: false,
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        is_staff: false,
        is_active: true,
        user_type: 'Standard',
        agencies: '',
        clients: '',
        markets: '',
      });
      setSelectedAgencies([]);
      setSelectedClients([]);
      setSelectedMarkets([]);
    };

    window.addEventListener('addNewUser', handleAddNewUserEvent);

    return () => {
      window.removeEventListener('addNewUser', handleAddNewUserEvent);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (newUser) {
      setNewUser({ ...newUser, [name]: value });
    } else if (updatedUser) {
      setUpdatedUser({ ...updatedUser, [name]: value });
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (newUser) {
      setNewUser({ ...newUser, [name]: value });
    } else if (updatedUser) {
      setUpdatedUser({ ...updatedUser, [name]: value });
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (updateUserMutation.isSuccess || createUserMutation.isSuccess) {
      refetch();
    }
  }, [updateUserMutation.isSuccess, createUserMutation.isSuccess, refetch]);

  const handleEditUser = (user: User) => {
    setEditUser(user);
    setUpdatedUser(user);
    setSelectedAgencies(user.agencies.split(', '));
    setSelectedClients(user.clients.split(', '));
    setSelectedMarkets(user.markets.split(', '));
  };

  const handleSaveEditUser = async () => {
    if (updatedUser) {
      try {
        await updateUserMutation.mutateAsync({
          ...updatedUser,
          agencies: selectedAgencies.join(', '),
          clients: selectedClients.join(', '),
          markets: selectedMarkets.join(', '),
        });
        setEditUser(null);
        setUpdatedUser(null);
        setError(null);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      }
    }
  };

  const handleSaveNewUser = async () => {
    if (newUser) {
      if (!newUser.username || newUser.username.trim() === '') {
        setError('Username is required');
        return;
      }
      if (!newUser.email || newUser.email.trim() === '') {
        setError('Email is required');
        return;
      }
      if (!newUser.password || newUser.password.trim() === '') {
        setError('Password is required');
        return;
      }
      if (newUser.password.length < 8) {
        setError('Your password must contain at least 8 characters.');
        return;
      }
      if (/^\d+$/.test(newUser.password)) {
        setError('Your password can’t be entirely numeric.');
        return;
      }
      if (newUser.password !== newUser.confirm_password) {
        setError('Passwords do not match.');
        return;
      }

      const commonPasswords = ['123456', 'password', '12345678'];
      if (commonPasswords.includes(newUser.password)) {
        setError('Your password can’t be a commonly used password.');
        return;
      }

      try {
        await createUserMutation.mutateAsync({
          ...newUser,
          agencies: selectedAgencies.join(', '),
          clients: selectedClients.join(', '),
          markets: selectedMarkets.join(', '),
        });
        setNewUser(null);
        setError(null);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      }
    }
  };

  const handleArchiveUser = async (user: User) => {
    const confirmed = window.confirm(
      `Are you sure you want to archive the user?`
    );

    if (!confirmed) {
      return;
    }

    try {
      await updateUserMutation.mutateAsync({
        ...user,
        is_active: false,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
  };

  const handleCancel = () => {
    setEditUser(null);
    setUpdatedUser(null);
    setNewUser(null);
    setError(null);
  };

  const handleSort = (key: keyof User) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortUsers = (users: User[]) => {
    if (!sortConfig) {
      return users;
    }

    return [...users].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleCloseModal = () => {
    setEditUser(null);
    setUpdatedUser(null);
    setNewUser(null);
    setError(null);
  };

  if (isLoading || isLoadingAvailableData) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Failed to fetch data</div>;
  }

  if (!data || !availableData) {
    return null;
  }

  const agencyOptions = availableData.agencies.map(
    (agency: Agency) => agency.name
  );
  const clientOptions = availableData.agencies.flatMap((agency: Agency) =>
    agency.clients.map((client: Client) => client.name)
  );
  const marketOptions = availableData.markets.map(
    (market: Market) => market.name
  );

  const filteredUsers = data.filter(
    (user: User) =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (user.first_name &&
        user.first_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.last_name &&
        user.last_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.agencies &&
        user.agencies.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.clients &&
        user.clients.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.markets &&
        user.markets.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const sortedUsers = sortUsers(filteredUsers);

  // Pagination Logic
  const paginatedUsers = sortedUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const tableHeaders: TableHeader[] = [
    { label: 'User', key: 'first_name', sortable: true },
    { label: 'Username', key: 'username', sortable: true },
    { label: 'Agency', key: 'agencies', sortable: true },
    { label: 'Client', key: 'clients', sortable: true },
    { label: 'Market', key: 'markets', sortable: true },
    { label: 'Created On', key: 'date_joined', sortable: true },
    { label: 'User Type', key: 'user_type', sortable: true },
  ];

  return (
    <div>
      {selectedNavigation === 'Active' ? (
        <div className="bg-light my-8 rounded-2xl shadow-sm">
          <div className="flex flex-col lg:flex-row justify-end px-4 pt-2 border-b border-success pb-2">
            <div className="flex flex-col lg:flex-row items-center w-full lg:w-auto">
              <div className="relative text-thirdgray text-sm font-bold border border-gray300 rounded-full w-full lg:w-[401px] mb-4 lg:mb-0">
                <input
                  type="search"
                  name="search"
                  placeholder="Search"
                  className="h-10 text-sm pl-10 pr-4 w-full rounded-full"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/Trailing_icon.svg`}
                  alt="search_ico"
                  className="absolute left-3 top-3 h-4 w-4 fill-current"
                />
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
                alt="dots"
                className="h-6 w-6 lg:ml-4"
              />
            </div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex bg-fourthgray rounded-lg w-48 h-10 mt-6 ml-4">
            {['Active', 'Archive'].map((navigation) => (
              <button
                key={navigation}
                className={`flex-1 rounded-md text-sm ${
                  selectedNavigation === navigation
                    ? 'bg-light text-primarygray m-1'
                    : 'text-gray400'
                }`}
                onClick={() =>
                  handleNavChange(navigation as 'Active' | 'Archive')
                }
              >
                {navigation}
              </button>
            ))}
          </div>

          <div className="overflow-auto py-6 px-4">
            <table className="min-w-full bg-light table-auto">
              <thead className="text-primarygray text-xs font-normal">
                <tr>
                  {tableHeaders.map((header) => (
                    <th key={header.label} className="py-2 px-4 text-left">
                      <div className="flex items-center">
                        {header.label.toUpperCase()}
                        {header.sortable && (
                          <img
                            src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                            alt="sort-icon"
                            className="ml-2 cursor-pointer"
                            onClick={() => handleSort(header.key)}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                  <th className="py-2 px-2 text-right">ACTIONS</th>
                </tr>
              </thead>
              <tbody className="text-primarygray text-xs">
                {paginatedUsers.map((user, index) => (
                  <tr
                    key={user.id}
                    className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}
                  >
                    <td className="py-2 px-4">
                      {user.first_name || user.last_name
                        ? `${user.first_name} ${user.last_name}`
                        : 'N/A'}
                    </td>
                    <td className="py-2 px-4">
                      <a
                        href={`mailto:${user.username}`}
                        className="font-medium underline"
                      >
                        {user.username || 'N/A'}
                      </a>
                    </td>
                    <td className="py-2 px-4">
                      {truncateText(user.agencies, 30)}
                    </td>
                    <td className="py-2 px-4">
                      {truncateText(user.clients, 30)}
                    </td>
                    <td className="py-2 px-4">
                      {truncateText(user.markets, 30)}
                    </td>
                    <td className="py-2 px-4 text-thirdgray">
                      {user.date_joined}
                    </td>
                    <td className="py-2 px-4">
                      <div
                        className={`rounded-md px-4 py-1 inline-block ${
                          user.user_type === 'Admin'
                            ? 'bg-blue100 text-blue800'
                            : user.user_type === 'Standard'
                            ? 'bg-yellow100 text-yellow800'
                            : user.user_type === 'Regional Admin'
                            ? 'bg-orange-100 text-orange-800'
                            : 'bg-disgreen100 text-disgreen800'
                        }`}
                      >
                        {user.user_type}
                      </div>
                    </td>
                    <td className="py-2 px-2 flex justify-end">
                      {(user.user_type !== 'Admin' ||
                        (loggedInUser &&
                          loggedInUser.role === 'Admin' &&
                          user.user_type === 'Admin')) && (
                        <button onClick={() => handleEditUser(user)}>
                          <img
                            src={`${process.env.PUBLIC_URL}/static/img/edit-03.svg`}
                            alt="edit"
                            className="h-4 w-4 mr-2"
                          />
                        </button>
                      )}
                      <button onClick={() => handleArchiveUser(user)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/archive.svg`}
                          alt="archive"
                          className="h-4 w-4"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex flex-col lg:flex-row justify-end items-center px-5 py-2">
            <span className="text-xs text-gray700 font-normal mr-3 mb-2 lg:mb-0">
              Showing
              <span className="text-secondarygray font-bold tabular-nums">
                {' '}
                {Math.min(
                  (currentPage - 1) * itemsPerPage + 1,
                  sortedUsers.length
                )}
              </span>{' '}
              to
              <span className="text-secondarygray font-bold tabular-nums">
                {' '}
                {Math.min(currentPage * itemsPerPage, sortedUsers.length)}
              </span>{' '}
              of
              <span className="text-secondarygray font-bold tabular-nums">
                {' '}
                {sortedUsers.length}
              </span>{' '}
              Entries
            </span>
            <div className="flex items-center relative">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-l-lg"
              >
                PREV
              </button>
              <div
                style={{
                  width: '1px',
                  backgroundColor: 'white',
                  margin: '0.1px',
                }}
              ></div>
              <button
                onClick={handleNextPage}
                disabled={
                  currentPage === Math.ceil(sortedUsers.length / itemsPerPage)
                }
                className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-r-lg"
              >
                NEXT
              </button>
            </div>
          </div>

          {/* Edit User Modal */}
          {editUser && updatedUser && (
            <div className="fixed inset-0 bg-gray600 bg-opacity-75 flex items-center justify-center z-10">
              <div className="bg-light rounded-lg p-6 w-1/3">
                <div className="flex justify-between items-center mb-4 border-b border-success pb-4">
                  <h2 className="text-lg font-bold">Edit User</h2>
                  <button
                    onClick={handleCloseModal}
                    className="text-gray-300 w-6 h-6 hover:text-gray-700"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/x.svg`}
                      alt="close_icon"
                      className=""
                    />
                  </button>
                </div>
                {error && (
                  <div className="text-error text-sm mb-4">{error}</div>
                )}
                <div className="mb-2">
                  <label className="block text-sm font-bold mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={updatedUser.first_name}
                    onChange={handleInputChange}
                    className="border p-1 w-full rounded text-sm"
                  />
                  <label className="block text-sm font-bold mb-1 mt-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={updatedUser.last_name}
                    onChange={handleInputChange}
                    className="border p-1 w-full rounded text-sm "
                  />
                </div>
                <div className="grid grid-cols-2 space-x-2">
                  <div className="mb-2">
                    <label className="block text-sm font-bold mb-1">
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={updatedUser.username}
                      onChange={handleInputChange}
                      className="border p-1 w-full rounded text-sm "
                    />
                  </div>
                  <div className="mb-2">
                    <label className="block text-sm font-bold mb-1">
                      User Type
                    </label>
                    <select
                      name="user_type"
                      value={updatedUser.user_type}
                      onChange={handleSelectChange}
                      className="border p-1 w-full rounded text-sm "
                    >
                      {loggedInUser && loggedInUser.role == 'Admin' && (
                        <option value="Admin">Admin</option>
                      )}
                      {loggedInUser && loggedInUser.role == 'Admin' && (
                        <option value="Regional Admin">Regional Admin</option>
                      )}
                      {loggedInUser &&
                        (loggedInUser.role == 'Admin' ||
                          loggedInUser.role == 'Regional Admin') && (
                          <option value="Agency Manager">Agency Manager</option>
                        )}
                      <option value="Standard">Standard</option>
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-2 space-x-2">
                  <div className="mb-2" title={selectedAgencies.join(', ')}>
                    <label className="block text-sm font-bold mb-1">
                      Agency
                    </label>
                    <MultiDropdown
                      options={agencyOptions}
                      preselectedOptions={selectedAgencies}
                      onOptionChange={setSelectedAgencies}
                      allOption="Select All"
                    />
                  </div>

                  <div className="mb-2" title={selectedClients.join(', ')}>
                    <label className="block text-sm font-bold mb-1">
                      Client
                    </label>
                    <MultiDropdown
                      options={clientOptions}
                      preselectedOptions={selectedClients}
                      onOptionChange={setSelectedClients}
                      allOption="Select All"
                    />
                  </div>

                  <div
                    className="mb-2"
                    title={selectedMarkets.slice(6).join(', ')}
                  >
                    <label className="block text-sm font-bold mb-1">
                      Market
                    </label>
                    <MultiDropdown
                      options={marketOptions}
                      preselectedOptions={selectedMarkets}
                      onOptionChange={setSelectedMarkets}
                      allOption="Select All"
                    />
                  </div>
                </div>
                <div className="flex justify-end text-sm border-t border-success pt-2">
                  <button
                    onClick={handleCancel}
                    className="text-success px-8 rounded mr-2"
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleSaveEditUser}
                    className="bg-success text-light px-8 rounded-[20px]"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Add User Modal */}
          {newUser && (
            <div className="fixed inset-0 bg-gray600 bg-opacity-75 flex items-center justify-center z-10">
              <div className="bg-white rounded-lg p-6 w-1/3 mt-20 max-h-[75%] overflow-auto">
                <div className="flex justify-between items-center mb-4 border-b border-success pb-4">
                  <h2 className="text-lg font-bold">Add New User</h2>
                  <button
                    onClick={handleCloseModal}
                    className="text-gray-300 w-6 h-6 hover:text-gray-700"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/x.svg`}
                      alt="close_icon"
                      className=""
                    />
                  </button>
                </div>
                {error && (
                  <div className="text-error text-sm mb-4">{error}</div>
                )}
                <div className="mb-2">
                  <label className="block text-sm font-bold mb-1">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={newUser.first_name || ''}
                    onChange={handleInputChange}
                    className="border p-1 w-full rounded text-sm"
                  />
                  <label className="block text-sm font-bold mb-1 mt-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={newUser.last_name || ''}
                    onChange={handleInputChange}
                    className="border p-1 w-full rounded text-sm"
                  />
                </div>
                <div className="grid grid-cols-2 space-x-2">
                  <div className="mb-2">
                    <label className="block text-sm font-bold mb-1">
                      Username*{' '}
                      <span className="text-thirdgray text-xs">
                        (LionLogin)
                      </span>
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={newUser.username || ''}
                      onChange={handleInputChange}
                      className="border p-1 w-full rounded text-sm "
                    />
                  </div>
                  <div className="mb-2">
                    <label className="block text-sm font-bold mb-1">
                      Email*
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={newUser.email || ''}
                      onChange={handleInputChange}
                      className="border p-1 w-full rounded text-sm "
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 space-x-2">
                  <div className="mb-2">
                    <label className="block text-sm font-bold mb-1">
                      Password*
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={newUser.password || ''}
                      onChange={handleInputChange}
                      className="border p-1 w-full rounded text-sm "
                    />
                  </div>
                  <div className="mb-2">
                    <label className="block text-sm font-bold mb-1">
                      Confirm Password*
                    </label>
                    <input
                      type="password"
                      name="confirm_password"
                      value={newUser.confirm_password || ''}
                      onChange={handleInputChange}
                      className="border p-1 w-full rounded text-sm "
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label className="block text-sm font-bold mb-1">
                    User Type
                  </label>
                  <select
                    name="user_type"
                    value={newUser.user_type || 'User'}
                    onChange={handleSelectChange}
                    className="border p-1 w-full rounded text-sm "
                  >
                    {loggedInUser && loggedInUser.role == 'Admin' && (
                      <option value="Admin">Admin</option>
                    )}
                    {loggedInUser && loggedInUser.role == 'Admin' && (
                      <option value="Regional Admin">Regional Admin</option>
                    )}
                    {loggedInUser &&
                      (loggedInUser.role == 'Admin' ||
                        loggedInUser.role == 'Regional Admin') && (
                        <option value="Agency Manager">Agency Manager</option>
                      )}
                    <option value="Standard">Standard</option>
                  </select>
                </div>
                <div className="grid grid-cols-2 space-x-2">
                  <div className="mb-2" title={selectedAgencies.join(', ')}>
                    <label className="block text-sm font-bold mb-1">
                      Agency
                    </label>
                    <MultiDropdown
                      options={agencyOptions}
                      preselectedOptions={selectedAgencies}
                      onOptionChange={setSelectedAgencies}
                      allOption="Select All"
                    />
                  </div>

                  <div className="mb-2" title={selectedClients.join(', ')}>
                    <label className="block text-sm font-bold mb-1">
                      Client
                    </label>
                    <MultiDropdown
                      options={clientOptions}
                      preselectedOptions={selectedClients}
                      onOptionChange={setSelectedClients}
                      allOption="Select All"
                    />
                  </div>

                  <div
                    className="mb-2"
                    title={selectedMarkets.slice(6).join(', ')}
                  >
                    <label className="block text-sm font-bold mb-1">
                      Market
                    </label>
                    <MultiDropdown
                      options={marketOptions}
                      preselectedOptions={selectedMarkets}
                      onOptionChange={setSelectedMarkets}
                      allOption="Select All"
                    />
                  </div>
                </div>
                <div className="flex justify-end text-sm border-t border-success pt-2">
                  <button
                    onClick={handleCancel}
                    className="text-success px-8 rounded mr-2"
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleSaveNewUser}
                    className="bg-success text-light px-8 rounded-[20px]"
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <ArchiveUsers />
      )}
    </div>
  );
}

export default UsersManagement;
