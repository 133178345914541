// CONSTANTS
import {
  DEFAULT_DAILY_DATA,
  COMP_INTEL_AD_TYPES,
  DEFAULT_DEVICE_DATA,
  DEFAULT_OVERVIEW_DATA,
} from 'constants/comp-intel';

// TYPES
import {
  OverviewTab,
  OverviewData,
  CompIntelAdType,
  CompetitorGroup,
  MetricInnerData,
  OverviewDailyData,
  coverageCompInner,
  OverviewDeviceData,
} from 'types';

// UTILS
import { downloadXLS } from 'utils/download';

// -------------------- DATA FILTERING BASED ON DROPDOWN SECTION --------------------

const filterDomainData = (data: MetricInnerData, selectedDomains: string[]) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc: MetricInnerData, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {});
};

const filterCoverageComp = (
  coverageComp: coverageCompInner,
  selectedDomains: string[]
): coverageCompInner => {
  return Object.keys(coverageComp)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = coverageComp[domain];
      return acc;
    }, {} as coverageCompInner);
};

const unifyCompetitorsData = (
  currentData: MetricInnerData,
  previousData: MetricInnerData
) => {
  // Get all unique competitors from both current and previous data
  const allCompetitors = Array.from(
    new Set([...Object.keys(currentData), ...Object.keys(previousData)])
  );

  // Ensure both datasets have the same competitors, initializing missing competitors with 0
  const unifiedCurrentData = allCompetitors.reduce(
    (acc: MetricInnerData, competitor) => {
      acc[competitor] =
        currentData[competitor] !== undefined ? currentData[competitor] : 0;
      return acc;
    },
    {}
  );

  const unifiedPreviousData = allCompetitors.reduce(
    (acc: MetricInnerData, competitor) => {
      acc[competitor] =
        previousData[competitor] !== undefined ? previousData[competitor] : 0;
      return acc;
    },
    {}
  );

  return { unifiedCurrentData, unifiedPreviousData };
};

// FILTER OVERVIEW DAILY DATA (LINE CHART)
export const filterDailyData = (
  data?: OverviewDailyData,
  selectedDomains: string[] = []
): OverviewDailyData => {
  const newData = { ...DEFAULT_DAILY_DATA };

  if (data && selectedDomains.length) {
    newData.desktop.coverageComp = filterCoverageComp(
      data.desktop.coverageComp ?? {},
      selectedDomains
    );
    newData.mobile.coverageComp = filterCoverageComp(
      data.mobile.coverageComp ?? {},
      selectedDomains
    );
  }
  return newData;
};

// FILTER OVERVIEW COMPETITOR DATA (BAR CHART)
const filterOverviewDeviceData = (
  data: OverviewDeviceData,
  selectedDomains: string[]
): OverviewDeviceData => {
  const filteredData: OverviewDeviceData = JSON.parse(
    JSON.stringify(DEFAULT_DEVICE_DATA)
  );

  for (const filterKey of COMP_INTEL_AD_TYPES) {
    const currentCoverage = filterDomainData(
      data.current[filterKey]?.coverage ?? {},
      selectedDomains
    );
    const previousCoverage = filterDomainData(
      data.previous[filterKey]?.coverage ?? {},
      selectedDomains
    );

    const {
      unifiedCurrentData: unifiedCoverageCurrent,
      unifiedPreviousData: unifiedCoveragePrevious,
    } = unifyCompetitorsData(currentCoverage, previousCoverage);

    filteredData.current[filterKey].coverage = unifiedCoverageCurrent;
    filteredData.previous[filterKey].coverage = unifiedCoveragePrevious;

    const currentRank = filterDomainData(
      data.current[filterKey]?.rank ?? {},
      selectedDomains
    );
    const previousRank = filterDomainData(
      data.previous[filterKey]?.rank ?? {},
      selectedDomains
    );

    const {
      unifiedCurrentData: unifiedRankCurrent,
      unifiedPreviousData: unifiedRankPrevious,
    } = unifyCompetitorsData(currentRank, previousRank);

    filteredData.current[filterKey].rank = unifiedRankCurrent;
    filteredData.previous[filterKey].rank = unifiedRankPrevious;

    const currentSearchTerms = filterDomainData(
      data.current[filterKey]?.search_terms ?? {},
      selectedDomains
    );
    const previousSearchTerms = filterDomainData(
      data.previous[filterKey]?.search_terms ?? {},
      selectedDomains
    );

    const {
      unifiedCurrentData: unifiedSearchTermsCurrent,
      unifiedPreviousData: unifiedSearchTermsPrevious,
    } = unifyCompetitorsData(currentSearchTerms, previousSearchTerms);

    filteredData.current[filterKey].search_terms = unifiedSearchTermsCurrent;
    filteredData.previous[filterKey].search_terms = unifiedSearchTermsPrevious;
  }

  return filteredData;
};

export const filterOverviewData = (
  data?: OverviewData,
  selectedDomains: string[] = []
): OverviewData => {
  const newData: OverviewData = { ...DEFAULT_OVERVIEW_DATA };

  if (data && selectedDomains.length) {
    newData.desktop = filterOverviewDeviceData(data.desktop, selectedDomains);
    newData.mobile = filterOverviewDeviceData(data.mobile, selectedDomains);
  }

  newData.unique_brands = data?.unique_brands ?? [];

  return newData;
};

// -------------------- END OF: DATA FILTERING BASED ON DROPDOWN SECTION --------------------

// Function to get the top N competitors by coverage
export const getTopDailyCompsByCoverage = (
  data: OverviewDailyData,
  limit: number
): string[] => {
  const allCompetitors = Object.keys(data.desktop.coverageComp);

  const sortedCompetitors = allCompetitors
    .map((competitor) => {
      const totalCoverage = Object.values(
        data.desktop.coverageComp[competitor]
      ).reduce((sum, adTypeData) => {
        return (
          sum +
          Object.values(adTypeData).reduce(
            (acc, { coverage }) => acc + coverage,
            0
          )
        );
      }, 0);
      return { competitor, totalCoverage };
    })
    .sort((a, b) => b.totalCoverage - a.totalCoverage)
    .slice(0, limit)
    .map(({ competitor }) => competitor);

  return sortedCompetitors;
};

export type DownlownloadOverviewProps = {
  overviewData?: OverviewData;
  dailyData?: OverviewDailyData;
  device: 'desktop' | 'mobile';
  filters: CompIntelAdType[];
  activeTab: OverviewTab;
};

export const downloadCoverageCompData = ({
  dailyData,
  device,
  filters,
  activeTab,
}: DownlownloadOverviewProps) => {
  const selectedFilter = filters[0];
  const activeTabKey =
    activeTab === 'Coverage'
      ? 'coverage'
      : activeTab === 'Rank'
      ? 'rank'
      : 'search_terms_count';

  if (!dailyData || !dailyData[device].coverageComp) {
    alert('No data available for the selected filter or coverageComp.');
    console.warn('No data available for the selected filter or coverageComp.');
    return;
  }

  const competitors = Object.keys(dailyData[device].coverageComp);

  if (competitors.length === 0) {
    return;
  }

  const headers = ['Competitor', 'Date', `${selectedFilter} ${activeTabKey}`];

  const labels: string[] = [];
  const columns: Array<Array<string | number>> = [[], []];

  competitors.forEach((competitor) => {
    if (dailyData[device].coverageComp[competitor][selectedFilter]) {
      const dates = Object.keys(
        dailyData[device].coverageComp[competitor][selectedFilter]
      );

      dates.forEach((date) => {
        const metricValue =
          dailyData[device].coverageComp[competitor][selectedFilter][date][
            activeTabKey
          ];

        labels.push(competitor); // Competitor
        columns[0].push(date); // Date
        columns[1].push(metricValue); // Metric (coverage, rank, or search_terms)
      });
    }
  });

  downloadXLS(
    `Competitive_${selectedFilter}_${activeTabKey}_${device}_TimeSeriesOverview`,
    headers,
    labels,
    columns
  );
};

export const downloadChartData = ({
  device,
  filters,
  activeTab,
  overviewData,
}: DownlownloadOverviewProps) => {
  if (!overviewData || !overviewData[device]) {
    alert('No data available for the selected filter or coverageComp.');
    console.warn('No data available for the selected filter or coverageComp.');
    return;
  }

  const selectedFilter = filters[0];

  const activeTabKey =
    activeTab === 'Coverage'
      ? 'coverage'
      : activeTab === 'Rank'
      ? 'rank'
      : 'search_terms';

  // Dynamically access the data based on the selected filter and active tab
  const currentData =
    overviewData[device]?.current?.[selectedFilter]?.[activeTabKey] || {};
  const priorData =
    overviewData[device]?.previous?.[selectedFilter]?.[activeTabKey] || {};

  // Get all competitors from both current and prior data
  const allCompetitors = Array.from(
    new Set([...Object.keys(currentData), ...Object.keys(priorData)])
  );

  const headers = [
    'Competitor',
    `${selectedFilter}_${activeTabKey.toUpperCase()}_CURRENT`,
    `${selectedFilter}_${activeTabKey.toUpperCase()}_PRIOR`,
  ];

  // Prepare data for both current and prior values
  const labels = allCompetitors;

  // Map current and prior values for each competitor, using 0 if data is missing
  const currentValues = allCompetitors.map((competitor) =>
    currentData[competitor] !== undefined ? currentData[competitor] : '0'
  );
  const priorValues = allCompetitors.map((competitor) =>
    priorData[competitor] !== undefined ? priorData[competitor] : '0'
  );

  // Download the data in XLS format
  downloadXLS(
    `Competitive_${activeTabKey}_${selectedFilter}_${device}_Overview`,
    headers,
    labels,
    [currentValues, priorValues]
  );
};

export const getSelectionsFromCompGroups = (
  options: CompetitorGroup[],
  selections: string[],
  clientDomain = ''
) => {
  const selectedCompetitors: string[] = [];
  const selectedGroups: string[] = [];

  for (const groupName of selections) {
    const matchedGroup = options.find(
      ({ group_name }) => group_name === groupName
    );
    if (matchedGroup) {
      selectedCompetitors.push(...matchedGroup.domains.split(','));
      selectedGroups.push(groupName);
    }
  }

  if (clientDomain && !selectedCompetitors.includes(clientDomain)) {
    selectedCompetitors.push(clientDomain);
  }

  return { selectedCompetitors, selectedGroups };
};
