import React, { useState, useEffect, useRef } from 'react';
import { Chart, registerables, ChartOptions, TooltipItem } from 'chart.js';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import { Top100WordsInterface } from '../../../types/comp-intel/comp-intel-content-analysis';
import Dropdown from '../../Dropdown';
import { downloadImage, downloadXLS } from 'utils/download';

interface CustomPluginOptions extends ChartOptions<'wordCloud'> {
  plugins: {
    extendedGridLinePlugin?: boolean;
    legend: {
      display: boolean;
    };
    tooltip: {
      enabled: boolean;
      callbacks: {
        label: (tooltipItem: TooltipItem<'wordCloud'>) => string;
      };
    };
  };
  fit?: boolean;
}

interface Top100WordsProps {
  data: Top100WordsInterface;
  clientDomain: string;
}

Chart.register(...registerables, WordCloudController, WordElement);

const Top100Words: React.FC<Top100WordsProps> = ({ data, clientDomain }) => {
  const competitors = Object.keys(data);

  const [selectedCompetitor, setSelectedCompetitor] = useState<string>(
    clientDomain ? clientDomain : Object.keys(data)[0]
  );
  const [ngramValue, setNgramValue] = useState<number>(4);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  const increaseNgramValue = () => {
    setNgramValue((prev) => (prev < 4 ? prev + 1 : 4));
  };

  const decreaseNgramValue = () => {
    setNgramValue((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const transformData = (
    data: Top100WordsInterface,
    competitor: string,
    gramType: string
  ) => {
    if (!data || !data[competitor] || !data[competitor][gramType]) return [];
    return Object.entries(data[competitor][gramType]).map(([text, value]) => ({
      text,
      value,
    }));
  };

  const words = transformData(data, selectedCompetitor, `${ngramValue}-gram`);

  const maxWordValue = Math.max(...words.map((word) => word.value));
  const minFontSize = 2; // Set a minimum font size
  const maxFontSize = 50; // Set a maximum font size
  const normalizeValue = (value: number) =>
    (Math.log(value + 1) / Math.log(maxWordValue + 1)) *
      (maxFontSize - minFontSize) +
    minFontSize;

  const renderWordCloud = () => {
    if (chartRef.current) {
      // Destroy previous chart instance if it exists
      if (chartInstance.current && words.length > 0) {
        chartInstance.current.destroy();
        chartInstance.current = null; // Ensure the reference is reset
      }

      const customOptions: CustomPluginOptions = {
        plugins: {
          extendedGridLinePlugin: false,
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItem: TooltipItem<'wordCloud'>) => {
                const index = tooltipItem.dataIndex;
                const originalValue = words[index].value;
                return `${originalValue.toFixed(2)}`;
              },
            },
          },
        },
        elements: {
          word: {
            padding: 5, // Increased padding for better separation between words
            minRotation: 0,
            maxRotation: 0, // No rotation for cleaner look
            rotationSteps: 0,
          },
        },
        layout: {
          padding: {
            left: 50,
            right: 50,
          },
        },
        fit: true, // Ensure that the word cloud fits within the chart area
        maintainAspectRatio: false, // Allows better fit in the container
      };
      // Create a new chart instance
      chartInstance.current = new Chart(chartRef.current, {
        type: 'wordCloud',
        data: {
          labels: words.map((word) => word.text),
          datasets: [
            {
              data: words.map((word) => normalizeValue(word.value)),
              color: () =>
                '#' + Math.floor(Math.random() * 16777215).toString(16), // Random color
            },
          ],
        },
        options: customOptions,
      });
    }
  };

  useEffect(() => {
    if (selectedCompetitor && !isLoading && words.length > 0) {
      renderWordCloud();
    }

    // Cleanup chart instance on component unmount
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null; // Reset the chart instance to null
      }
    };
  }, [selectedCompetitor, ngramValue, isLoading, words]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setIsLoading(false); // Set loading to false once data is ready
      setSelectedCompetitor(clientDomain || Object.keys(data)[0]);
    }
  }, [data]);

  // const useWindowWidth = () => {
  //   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //   useEffect(() => {
  //     const handleResize = () => setWindowWidth(window.innerWidth);
  //     window.addEventListener('resize', handleResize);

  //     return () => window.removeEventListener('resize', handleResize);
  //   }, []);

  //   return windowWidth;
  // };

  // const windowWidth = useWindowWidth();
  // const getDivisionFactor = () => {
  //   if (windowWidth < 600) {
  //     return 20;
  //   } else if (windowWidth >= 600 && windowWidth < 1500) {
  //     return 5;
  //   } else {
  //     return 3;
  //   }
  // };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedCompetitor(clientDomain ? clientDomain : Object.keys(data)[0]);
  }, [data]);

  const downloadChartImage = () => {
    downloadImage('Top20Words');
  };

  if (!data) {
    return null;
  }

  const downloadChartData = () => {
    const headers = ['Word', 'Frequency'];
    const labels = words.map((word) => word.text);
    const values = words.map((word) => word.value);

    downloadXLS(
      `Top20Words_${selectedCompetitor}_${ngramValue}-gram`,
      headers,
      labels,
      [values]
    );
  };

  return (
    <div id="Top20Words" className="bg-light rounded-2xl shadow-sm mt-8">
      <div className="flex justify-between items-center border-b border-success mb-4">
        <div>
          <h2 className="text-primarygray text-2xl font-bold pl-4 pt-2">
            Top 20 Words
          </h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">
            Understand trending words and phrases in ads
          </h3>
        </div>
        <div
          className="flex items-center"
          ref={dropdownRef}
          id="downloadDropdownMenu-Top20Words"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
              style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartData();
                    closeDropdown();
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    downloadChartImage();
                    closeDropdown();
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex relative">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[80%] xl:w-[80%] md:w-[80%] w-[80%]">
          <canvas ref={chartRef}></canvas>
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <div className="space-y-2">
            <label className="block text-sm font-bold mb-2">Filter:</label>
            <Dropdown
              title={selectedCompetitor}
              options={competitors}
              onOptionClick={setSelectedCompetitor}
              dropdownLength="max-h-44"
            />
            <div className="items-center mt-2 flex w-full bg-light border border-gray300 rounded py-2 px-3 text-thirdgray mb-2">
              <label className="block mr-2 text-thirdgray font-bold">
                N-gram
              </label>
              <button
                onClick={increaseNgramValue}
                className="bg-success text-white rounded-full w-6 h-6 flex items-center justify-center cursor-pointer"
              >
                +
              </button>
              <span className="mx-2">{ngramValue}</span>
              <button
                onClick={decreaseNgramValue}
                className="bg-red100 text-white rounded-full w-6 h-6 flex items-center justify-center cursor-pointer"
              >
                -
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top100Words;
