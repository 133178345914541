import { useState } from 'react';

const CLIENT_REGEX = /^[\w+ _]*$/;

const sortOptionsFunc = (
  a: Agency | Region | Market | LineOfBusiness,
  b: Agency | Region | Market | LineOfBusiness
) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
};

function AddClient({ agencies, formData, setFormData }: BaseSetupProps) {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = evt.target;

    if (value && errors[name]) {
      setErrors((prev) => {
        const copy = { ...prev };
        delete copy[name];
        return copy;
      });
    }

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onBlurValidation = (
    evt: React.FocusEvent<HTMLSelectElement | HTMLInputElement, Element>
  ) => {
    const { name, value } = evt.currentTarget;

    if (name === 'client' && !CLIENT_REGEX.test(value)) {
      setErrors((prev) => ({
        ...prev,
        [name]:
          'Value may only contain letters, numbers, underscores, and/or spaces.  Special characters not allowed.',
      }));
    }

    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: `${
          name[0].toUpperCase() + name.slice(1).toLowerCase()
        } is required.`,
      }));
    }
  };

  // JSX
  return (
    <div>
      <h2 className="text-[32px] font-bold">Add Client</h2>
      <h3 className="font-bold text-grayscale70 mt-2">Initial configuration</h3>
      <form className="mt-6 space-y-6">
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">
            Agency
          </label>
          <select
            name="agency"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.agency}
            onChange={onChange}
            onBlur={onBlurValidation}
          >
            <option value="">Select</option>
            {agencies
              .slice()
              .sort(sortOptionsFunc)
              .map((agency) => (
                <option key={agency.id} value={agency.id}>
                  {agency.name}
                </option>
              ))}
          </select>
          {errors.agency && (
            <span className="text-error text-sm">{errors.agency}</span>
          )}
        </div>
        <div>
          <div className="flex justify-between items-center">
            <label className="font-bold text-primarygray text-2xl mb-2">
              Client
            </label>
          </div>
          <input
            name="client"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.client}
            onChange={onChange}
            onBlur={onBlurValidation}
          />
          {errors.client && (
            <span className="text-error text-sm">{errors.client}</span>
          )}
        </div>

        <div className="inline-flex items-center mt-4 cursor-not-allowed">
          <input
            type="checkbox"
            name="new_business"
            className="w-3 h-3 cursor-not-allowed"
            checked={formData.new_business}
            disabled
          />
          <label className="ml-2 text-thirdgray cursor-not-allowed">
            Is New Business
          </label>
        </div>
      </form>
    </div>
  );
}

export default AddClient;
