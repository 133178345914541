import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export interface User {
  user_id: number;
  username: string;
  logged_in: boolean;
  // isAuthenticated: boolean;
  role?: string;
  has_settings_access: boolean;
  has_app_access: boolean;
}

interface ProtectedRouteProps {
  user: User | null;
  children?: ReactElement;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  user,
  children,
}) => {
  if (!user || !user.has_settings_access) {
    return <Navigate to="/home" replace />;
  }
  return <>{children}</>;
};

export const WrapRoute: React.FC<ProtectedRouteProps> = ({ user }) => {
  if (!user || !user.has_app_access) {
    return <Navigate to="/no-access" replace />;
  }

  return <Outlet />;
};

export const NoAccessPageRoute: React.FC<ProtectedRouteProps> = ({
  user,
  children,
}) => {
  if (user && user.has_app_access) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};
