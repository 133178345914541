import { useCurrentUserAtom } from 'atoms';
import { useState, useEffect } from 'react';

// UTILS
import { sortFuncDropdownOptions } from 'utils';

interface CompIntProps {
  customerId: string | null;
  isNewBusiness?: boolean;
  setTriggerCompetitiveUpload: React.Dispatch<
    React.SetStateAction<() => Promise<void>>
  >; // Prop to receive the file upload trigger
  setHasUploadedFileComInt: React.Dispatch<React.SetStateAction<boolean>>;
}

const providerChoices = [
  { value: 'SerpAPI', label: 'SerpAPI' },
  { value: 'Oxylabs', label: 'Oxylabs' },
];

const TEMPLATE_LINK =
  'https://publicisgroupe.sharepoint.com/:x:/r/sites/PerformanceHub/_layouts/15/Doc.aspx?sourcedoc=%7B219B1971-A78B-430F-9023-289BBA022EAF%7D&file=OneSearch_Competitive_intelligence_template_01.2025.xlsx&action=default&mobileredirect=true';

function CompetitiveInt({
  customerId,
  isNewBusiness = false,
  setHasUploadedFileComInt,
  setTriggerCompetitiveUpload,
}: CompIntProps) {
  const [frequency] = useState<number>(3);
  const [currentUser] = useCurrentUserAtom();

  // Store the file object
  const [CompIntfile, setCompIntFile] = useState<File | null>(null);

  // Local state to manage file upload status
  const [hasFileUploadedComInt, setHasFileUploadedComInt] =
    useState<boolean>(false);

  const [studies, setStudies] = useState<number[]>([]);

  const [domain, setDomain] = useState<string>('');
  const [studyName, setStudyName] = useState<string>('');
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [provider, setProvider] = useState<string>(providerChoices[1].value);

  const handleCompIntFileNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setCompIntFile(event.target.files[0]); // Save the file object
      setHasUploadedFileComInt(true);
      setHasFileUploadedComInt(true);
      setUploadError(null);
    } else {
      setCompIntFile(null);
      setHasUploadedFileComInt(false);
      setHasFileUploadedComInt(false);
    }
  };

  useEffect(() => {
    if (CompIntfile === null) {
      setHasUploadedFileComInt(false);
      setHasFileUploadedComInt(false);
    }
  }, [CompIntfile]);

  const uploadFile = async () => {
    if (!CompIntfile) {
      console.error('No file selected for upload.');
      throw new Error('No file selected.');
    }

    const formData = new FormData();
    formData.append('file', CompIntfile);
    formData.append('product', 'comp_int');

    if (customerId) {
      formData.append('customer_id', customerId);
    }
    formData.append('study_name', studyName);
    formData.append('domain', domain);
    formData.append('frequency', frequency.toString());
    formData.append('provider', provider);

    try {
      const response = await fetch('/upload/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('File upload failed:', errorText);
        setUploadError(`File upload failed: ${errorText}`);
        throw new Error(`File upload failed: ${errorText}`);
      }

      const data = await response.json();
      console.log('File upload successful:', data.message);
    } catch (error) {
      console.error('Error during file upload:', error);
      throw error;
    }
  };

  useEffect(() => {
    // function that will be called to trigger the file upload
    setTriggerCompetitiveUpload(() => uploadFile);
  }, [
    CompIntfile,
    customerId,
    studyName,
    domain,
    frequency,
    provider,
    setTriggerCompetitiveUpload,
  ]);

  const addStudy = () => {
    if (studies.length < 2) {
      setStudies([...studies, studies.length + 1]);
    }
  };

  const removeStudy = (index: number) => {
    const newStudies = studies.filter((_, studyIndex) => studyIndex !== index);
    setStudies(newStudies);
  };

  return (
    <>
      <div className="bg-light rounded-2xl shadow-sm mt-8">
        <div className="flex justify-between items-center border-b border-success mb-2">
          <div>
            <h2 className="text-primarygray text-2xl font-bold pl-4 py-2">
              Competitive Intelligence
            </h2>
            <h3 className="text-primarygray text-sm font-bold mb-2 pl-4">
              Competitor and market insights to understand the competitive
              landscape.
            </h3>
          </div>
          {/* <div className="flex items-center border border-thirdgray rounded-[20px] px-2 mr-4">
            <button
              onClick={addStudy}
              className="text-sm text-thirdgray font-bold flex items-center"
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
                alt="plus_icon"
                className="w-[18px] h-[18px] mr-1"
              />
              ADD STUDY
            </button>
          </div> */}
        </div>
        <div
          className={`flex flex-col space-y-4 px-4 py-6 ${
            isNewBusiness ? 'pt-3' : ''
          }`}
        >
          {isNewBusiness ? (
            <div className="text-center text-sm font-bold text-primarygray">
              <span>
                New Business clients are limited to 100K scrapes per month for
                60 days.
              </span>
            </div>
          ) : null}
          <div className="flex flex-row space-x-4">
            <label className="font-bold text-sm">Data Import</label>
            <div className="border border-thirdgray rounded-[20px] px-8">
              <a
                href={TEMPLATE_LINK}
                target="_blank"
                rel="noreferrer"
                className="text-sm font-bold flex items-center text-thirdgray"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/download.svg`}
                  alt="download"
                  className="mr-[6px]"
                />
                Download template
              </a>
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="flex flex-col w-1/2">
              <label className="font-bold text-sm mb-2">Study Name</label>
              <input
                type="text"
                className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                placeholder="Us-Test-demo - Market Analysis"
                value={studyName}
                onChange={(e) => setStudyName(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="font-bold text-sm mb-2">Domain</label>
              <input
                type="text"
                className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                placeholder="example.com"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>
          </div>
          {currentUser?.role?.toLowerCase() === 'admin' ? (
            <div className="flex flex-row space-x-4">
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Frequency</label>
                <input
                  type="number"
                  className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                  value={3}
                  readOnly
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="font-bold text-sm mb-2">Provider</label>
                <select
                  className="border border-gray200 rounded p-2.5 text-thirdgray text-sm"
                  value={provider}
                  onChange={(e) => setProvider(e.target.value)}
                >
                  {providerChoices
                    .sort(sortFuncDropdownOptions)
                    .map((choice) => (
                      <option key={choice.value} value={choice.value}>
                        {choice.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          ) : null}

          {/* Conditional rendering for the file input based on studyName, domain, and frequency */}
          {studyName && domain && frequency > 0 && (
            <div className="flex flex-row items-center space-x-4">
              <div className="flex flex-col w-1/2">
                <div className="border border-gray200 rounded p-2 flex items-center">
                  <input
                    type="file"
                    id="compIntfileInput"
                    className="hidden"
                    accept=".xlsx"
                    onChange={handleCompIntFileNameChange}
                  />
                  <label
                    htmlFor="compIntfileInput"
                    className="cursor-pointer text-thirdgray text-sm font-bold border border-thirdgray rounded-md px-3"
                  >
                    CHOOSE FILE
                  </label>
                  <span className="text-thirdgray text-sm px-2">
                    {CompIntfile?.name || 'No file chosen'}
                  </span>
                </div>
                {uploadError && (
                  <span className="text-error text-sm mt-2">{uploadError}</span>
                )}
              </div>
              {/* <div className="flex flex-col w-1/2">
                <span>
                  <a
                    href="#"
                    className="text-gray600 text-xs font-medium underline"
                  >
                    or click here to import using plain text
                  </a>
                </span>
              </div> */}
            </div>
          )}

          <p className="text-xs text-thirdgray">
            Note: Maximum import size is 1000 lines
          </p>
          {!hasFileUploadedComInt && (
            <span className="text-error text-sm">
              Please upload the required file before proceeding.
            </span>
          )}

          {studies.map((study, index) => (
            <div
              key={index}
              className="border-t border-gray200 mt-4 pt-4 relative"
            >
              {index >= 0 && (
                <div className="absolute -top-2 right-0">
                  <button
                    onClick={() => removeStudy(index)}
                    className="text-error px-2 py-1"
                  >
                    &times;
                  </button>
                </div>
              )}
              <div className="flex flex-row space-x-4">
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Study Name</label>
                  <input
                    type="text"
                    className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                    placeholder="Us-Test-demo - Market Analysis"
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Domain</label>
                  <input
                    type="text"
                    className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                    placeholder="example.com"
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Frequency</label>
                  <input
                    type="number"
                    className="border border-gray200 rounded p-2 text-thirdgray text-sm"
                    placeholder="3"
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-sm mb-2">Provider</label>
                  <select
                    className="border border-gray200 rounded p-2.5 text-thirdgray text-sm"
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                  >
                    {providerChoices
                      .sort(sortFuncDropdownOptions)
                      .map((choice) => (
                        <option key={choice.value} value={choice.value}>
                          {choice.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-row items-center space-x-4">
                <div className="flex flex-col w-1/2">
                  <div className="border border-gray200 rounded p-2 flex items-center">
                    <input
                      type="file"
                      id={`compIntfileInput-${index}`}
                      className="hidden"
                      accept=".xlsx"
                      onChange={handleCompIntFileNameChange}
                    />
                    <label
                      htmlFor={`compIntfileInput-${index}`}
                      className="cursor-pointer text-thirdgray text-sm font-bold border border-thirdgray rounded-md px-3"
                    >
                      CHOOSE FILE
                    </label>
                    <span className="text-thirdgray text-sm px-2">
                      {CompIntfile?.name || 'No file chosen'}
                    </span>
                  </div>
                  {uploadError && (
                    <span className="text-error text-sm mt-2">
                      {uploadError}
                    </span>
                  )}
                </div>
                {/* <div className="flex flex-col w-1/2">
                  <span>
                    <a
                      href="#"
                      className="text-gray600 text-xs font-medium underline"
                    >
                      or click here to import using plain text
                    </a>
                  </span>
                </div> */}
              </div>
              <p className="text-xs text-thirdgray">
                Note: Maximum import size is 1000 lines
              </p>
              {!hasFileUploadedComInt && (
                <span className="text-error text-sm">
                  Please upload the required file before proceeding.
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CompetitiveInt;
