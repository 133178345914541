import { Chart } from "chart.js";

export interface NoDataPlugin {
    id: string;
    afterDatasetsDraw: (chart: Chart) => void;
}

export const noDataPlugin: NoDataPlugin = {
    id: 'noData',
    afterDatasetsDraw: (chart: Chart) => {
      const { ctx, data, chartArea: {top, left, width, height} } = chart;
  
      if (data.datasets[0].data.length <= 1 && !data.datasets[0].data[0]) {
        ctx.save();
        ctx.font = 'bold 16px sans-serif';
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.fillText('No data available', left + width / 2, top + height / 2);
        return;
      }
    },
  };