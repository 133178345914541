// Import i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import enTooltips from '../../translations/en/tooltips.json';
import esTooltips from '../../translations/es/tooltips.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { tooltips: enTooltips },
      es: { tooltips: esTooltips },
      // Add other languages here
    },
    fallbackLng: 'en', // Fallback language
    ns: ['tooltips'],
    defaultNS: 'tooltips', // Default namespace
    interpolation: {
      escapeValue: false,
    },
    detection: {
      // Order to check languages
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],

      // Keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',

      // Cache user language on
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;