import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ButtonProps {
    name: string;
    navigateTo: string;
}

interface CardProps {
    title: string;
    question: string;
    buttons: ButtonProps[];
}

const Card: React.FC<CardProps> = ({ title, question, buttons }) => {
    const navigate = useNavigate();

    return (
        <div className="bg-white shadow-md rounded-lg p-5 w-full border border-gray-200 flex flex-col h-full">
            <div className="flex items-center mb-2">
                <h3 className="capitalize text-lg font-semibold text-gray-900">{title}</h3>
            </div>
            <p className="text-gray-600 text-sm mb-4 flex-grow">
                {question}
            </p>
            <div className='mt-auto flex flex-row flex-wrap justify-start gap-4'>
                {buttons.map((button, index) => (
                    <div key={index} className='border-2 border-success shadow-md rounded-full hover:bg-blue-50 transition w-fit whitespace-nowrap'>
                        <button
                            className="text-sm font-semibold px-3 py-1"
                            onClick={() => navigate(button.navigateTo)}
                        >
                            View {button.name}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Card;
