import Dropdown from './Dropdown';
import { User } from 'protectedRoute';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useCurrentClientAtom } from 'atoms/clientAtom';
import { useQuery } from 'react-query';

interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  submitLogout: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loggedInUser: User | null;
  setActivatedModules: (activatedModules: string[]) => void;
}

const currentVersion = process.env.REACT_APP_RELEASE_VERSION || '2024.11.25';

const Sidebar: React.FC<SidebarProps> = ({
  submitLogout,
  isSidebarOpen,
  toggleSidebar,
  loggedInUser,
  setActivatedModules,
}) => {
  const navigate = useNavigate();
  const [, setClient] = useCurrentClientAtom();
  const [userPlatforms, setUserPlatforms] = useState<number[] | null>(null);

  const [currentClient, setCurrentClient] = useState('');
  const [clientOptions, setClientOptions] = useState([]);
  const [clientOptionsCleared, setClientOptionsCleared] = useState([]);

  const buttons = [
    {
      icon: `${process.env.PUBLIC_URL}/static/img/help-circle.svg`,
      text: 'Resources',
    },
    {
      icon: `${process.env.PUBLIC_URL}/static/img/icon.svg`,
      text: 'Sign-out',
    },
  ];

  // Conditionally add the Settings button if the user has access
  if (loggedInUser) {
    if (loggedInUser.has_settings_access) {
      buttons.splice(1, 0, {
        // Insert at the second position or adjust as needed
        icon: `${process.env.PUBLIC_URL}/static/img/settings-01.svg`,
        text: 'Settings',
      });
    }
  }

  const { data } = useQuery(['get_customer_info_sidebar'], {
    queryFn: () =>
      fetch('/get_customer_info_sidebar/').then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      }),
    onError: (error) => {
      console.error('Error fetching client and line of business', error);
    },
  });

  useEffect(() => {
    if (!data) return;
    setClient(data);
    setCurrentClient(data.current_client);
    setClientOptions(data.all_combinations);
    setClientOptionsCleared(data.cleared_options);
    setUserPlatforms(data.platforms);
    setActivatedModules(data.platforms);
  }, [data, setClient]);

  const handleClientChange = (option: string) => {
    let customer_id = '';
    for (const client of clientOptions) {
      if (client[0] === option) {
        customer_id = client[1];
        break;
      }
    }

    fetch('/set_user_profile/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customer_id: customer_id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        setCurrentClient(option);
        // Perform a full page reload and navigate to /home
        window.location.href = '/home'; // This reloads the page to the /home route
      })
      .catch((error) => {
        console.error('Error updating client and line of business', error);
      });
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleResourcesClick = () => {
    navigate('/resources');
  };

  const menuItems = [
    {
      to: '/home',
      icon: `${process.env.PUBLIC_URL}/static/img/home-03.svg`,
      text: 'Home',
      platform: 0,
    },
    {
      to: '/consumer_intent',
      icon: `${process.env.PUBLIC_URL}/static/img/users-03.svg`,
      text: 'Consumer Intent',
      platform: 3,
    },
    {
      to: '/competitive_intelligence',
      icon: `${process.env.PUBLIC_URL}/static/img/chart-breakout-circle.svg`,
      text: 'Competitive Intelligence',
      platform: 2,
    },
    {
      to: '/auto_optimize',
      icon: `${process.env.PUBLIC_URL}/static/img/star-06.svg`,
      text: 'Auto Optimize',
      platform: 4,
    },
    {
      to: '/insights',
      icon: `${process.env.PUBLIC_URL}/static/img/pie-chart-02.svg`,
      text: 'Insights',
      platform: 1,
    },
  ];

  const accessibleMenuItems = menuItems.filter((item) =>
    userPlatforms?.includes(item.platform)
  );

  return (
    <div
      className={`fixed top-0 left-0 h-full max-h-screen z-50 ${
        isSidebarOpen ? 'w-[280px]' : 'w-[60px]'
      } bg-secondarygray text-light flex flex-col justify-between rounded-br-3xl transition-width duration-300`}
    >
      <div className="w-full flex items-center justify-between p-0 m-0 bg-transparent relative">
        {isSidebarOpen ? (
          <div className="relative w-full ml-5">
            <img
              src={`${process.env.PUBLIC_URL}/static/img/Logo.svg`}
              alt="Logo"
              className="absolute top-[17px] left-[20px] z-[100] w-[123px] h-[20px] flex-shrink-0"
            />
            <div className="absolute top-0 left-[-20px] w-[280px] mb-[-20px] z-[1]">
              <img
                src={`${process.env.PUBLIC_URL}/static/img/Exclude_new.svg`}
                alt="Vector"
                className="w-full h-full"
              />
            </div>
          </div>
        ) : (
          <div className="absolute top-1/2 transform -translate-y-1/2 w-full flex justify-center">
            <button
              onClick={toggleSidebar}
              className="flex justify-center items-center w-8 h-8 pt-12 z-40"
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/img/Expand.svg`}
                alt="Expand"
                className={`w-6 h-6 transform transition-transform duration-300 ${
                  !isSidebarOpen ? 'rotate-180' : ''
                }`}
              />
            </button>
          </div>
        )}
        {isSidebarOpen && (
          <button
            onClick={toggleSidebar}
            className="flex justify-center items-center w-8 h-8 mb-[-24px] z-40 -mt-2 pt-8"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/Expand.svg`}
              alt="Expand"
              className="w-6 h-6"
            />
          </button>
        )}
      </div>
      <ul className="mt-[91px] flex-1 text-base font-medium">
        {accessibleMenuItems.map((item, index) => (
          <li className="mb-3.5" key={index}>
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                isActive
                  ? "relative flex items-center px-6 py-3 bg-primarygray rounded font-bold whitespace-nowrap before:content-[''] before:absolute before:right-0 before:top-0 before:bottom-0 before:w-1 before:bg-success"
                  : "relative flex items-center px-6 py-3 hover:bg-primarygray rounded whitespace-nowrap hover:before:content-[''] hover:before:absolute hover:before:right-0 hover:before:top-0 hover:before:bottom-0 hover:before:w-1 hover:before:bg-success"
              }
            >
              <span className="sidebar-icon w-6 h-6 flex-shrink-0">
                <img src={item.icon} alt={item.text} />
              </span>
              {isSidebarOpen && <span className="ml-2">{item.text}</span>}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="flex flex-col items-center gap-4 px-4 pb-4">
        {isSidebarOpen && (
          <Dropdown
            mode="dark"
            title={`${currentClient}`}
            options={clientOptionsCleared || []}
            onOptionClick={handleClientChange}
            position="left-full"
            positionY="top-sidebar-calc"
            dropdownLength="max-h-60"
          />
        )}

        {buttons.map((item, index) => (
          <button
            onClick={
              item.text === 'Settings'
                ? handleSettingsClick
                : item.text === 'Sign-out'
                ? submitLogout
                : item.text === 'Resources'
                ? handleResourcesClick
                : undefined
            }
            key={index}
            className={`flex items-center justify-center bg-primarygray text-sm py-2 px-4 rounded-lg ${
              isSidebarOpen ? 'w-52' : 'w-8'
            } h-8`}
          >
            <span className="button-icon w-6 h-6 flex-shrink-0">
              <img src={item.icon} alt={item.text} />
            </span>
            {isSidebarOpen && <span className="ml-2">{item.text}</span>}
          </button>
        ))}
      </div>
      <div
        className={`ps-3 mb-1 text-xs${
          isSidebarOpen ? '' : ' w-12 text-wrap break-words'
        }`}
      >
        v{currentVersion}
      </div>
    </div>
  );
};

export default Sidebar;
