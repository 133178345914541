import { useQuery } from 'react-query';
import { useCmpIntContext } from 'hooks';
import { DEFAULT_STALE_TIME } from '../../constants';
import { useState, useRef, useEffect, useMemo } from 'react';

// API REQUESTS
import { fetchCompBFData } from 'api';

// COMPONENTS
import OutletSpinner from '../OutletSpinner';
import LoadingSpinner from '../LoadingSpinner';
import BattleField from './BFComponents/BattleField';
import BrandFocusBarChart from './BFComponents/BrandFocusBarChart';
import BrandPresenceChart from './BFComponents/BrandPresenceChart';
import BFLocationAnalysis from './BFComponents/BFLocationAnalysis';
import SearchTermsOverlap from './BFComponents/SearchTermsOverlap';
import BFPaidVsOrganicVsPLA from './BFComponents/BFPaidVsOrganicVsPLA';
import BrandFocusDoughnutChart from './BFComponents/BrandFocusDoughnutChart';
import TooltipPopover from 'components/TooltipPopover';

// TYPES
import { BrandFocusTabData, CompIntelAdType } from '../../types';

// UTILS
import {
  downloadXLS,
  downloadImage,
  xlsBFGetAverageCoverage,
  xlsBFGetAveragePosition,
  filterBFDataBasedOnDropdown,
} from '../../utils';

const DEFAULT_DATA: BrandFocusTabData = {
  battlefield: {
    desktop: {},
    mobile: {},
  },
  brand_focus: {
    average_coverage: {},
    average_position: {},
    calculated_diff: {},
  },
  brand_presence: {},
  location_analysis: {
    desktop: {},
    mobile: {},
  },
  paid_vs_organic_vs_pla: {
    desktop: {},
    mobile: {},
  },
};

// COMP. INTEL BRAND FOCUS TAB/PAGE
function CompIntelBrandFocus() {
  // STATE
  const {
    dateRange,
    clientDomain,
    selectedCompetitors,
    selectedSearchOptions,
    setCompetitorOptions,
  } = useCmpIntContext();

  // DROPDOWN
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState<CompIntelAdType>('PAID');

  const [filteredData, setFilteredData] = useState<BrandFocusTabData>({
    ...DEFAULT_DATA,
  });

  const queryKey = useMemo(
    () =>
      `compDataBrandFocus_${dateRange.endDate?.toDateString()}_${dateRange.startDate?.toDateString()}_${selectedCompetitors
        .slice(0)
        .sort()}_${selectedSearchOptions.terms.slice(0).sort()}`,
    [dateRange, selectedCompetitors, selectedSearchOptions]
  );

  const { data, error, isLoading } = useQuery(
    [queryKey],
    () => {
      return fetchCompBFData(
        dateRange,
        selectedCompetitors.join(','),
        selectedSearchOptions.terms
      );
    },
    {
      enabled: !!dateRange.startDate && !!dateRange.endDate,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  useEffect(() => {
    setCompetitorOptions((prev) =>
      Array.from(new Set([...prev, ...(data?.unique_brands || [])]))
    );
  }, [data, setCompetitorOptions]);

  useEffect(() => {
    if (selectedCompetitors.length > 0) {
      if (data) {
        const newData = filterBFDataBasedOnDropdown(data, selectedCompetitors);
        setFilteredData(newData);
      }
    } else {
      setFilteredData({ ...DEFAULT_DATA });
    }
  }, [data, selectedCompetitors]);

  const filterButtonClasses = (filter: string) =>
    `text-thirdgray rounded px-3 py-1 cursor-pointer ${
      selectedFilter === filter
        ? 'border border-success rounded-lg'
        : 'border border-disgreen100 rounded'
    }`;

  const toggleFilter = (filter: CompIntelAdType) => {
    setSelectedFilter(filter);
  };

  const arrowSrc = (isPositive: boolean) =>
    isPositive
      ? `${process.env.PUBLIC_URL}/static/img/Arrow_positive.svg`
      : `${process.env.PUBLIC_URL}/static/img/Arrow_red.svg`;

  const changeColor = (isPositive: boolean) =>
    isPositive ? 'text-success' : 'text-error';

  // DROPDOWN (IS THIS NEEDED?)
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // JSX
  return isLoading ? (
    <OutletSpinner />
  ) : error ? (
    <div>Error fetching data</div>
  ) : data ? (
    <>
      <div className="pb-8">
        {/* <BrandPresenceChart data={filteredData.brand_presence} /> */}
        {filteredData && filteredData.brand_presence ? (
          <BrandPresenceChart data={filteredData.brand_presence} />
        ) : (
          <OutletSpinner />
        )}
      </div>

      <div
        className="pb-10 bg-light border rounded-2xl px-4 py-2"
        id="competitiveIntelligenceBrandFocus"
      >
        <div className="flex justify-between items-center mb-2">
          <div>
            {/* missing text subtitle */}
            <h2 className="text-2xl font-bold">Brand Focus</h2>
            {/* <p className="text-gray800 text-sm font-bold mt-[2px]">
                  Subtitle
                </p> */}
          </div>
          <div
            className="relative"
            ref={dropdownRef}
            id="downloadDropdownMenu-competitiveIntelligenceBrandFocus"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      closeDropdown();
                      downloadXLS(
                        'competitiveIntelligenceBrandFocusAverageCoverage',
                        ['website', 'device', 'type', 'coverage', 'percentage'],
                        xlsBFGetAverageCoverage(
                          data.brand_focus,
                          selectedFilter,
                          selectedCompetitors
                        )['label'],
                        xlsBFGetAverageCoverage(
                          data.brand_focus,
                          selectedFilter,
                          selectedCompetitors
                        )['columns']
                      );
                      downloadXLS(
                        'competitiveIntelligenceBrandFocusAveragePosition',
                        [
                          'website',
                          'device',
                          'type',
                          'position1',
                          'position2',
                          'position3',
                        ],
                        xlsBFGetAveragePosition(
                          data.brand_focus,
                          selectedFilter,
                          selectedCompetitors
                        )['label'],
                        xlsBFGetAveragePosition(
                          data.brand_focus,
                          selectedFilter,
                          selectedCompetitors
                        )['columns']
                      );
                    }}
                  >
                    Download XLS
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      closeDropdown();
                      downloadImage('competitiveIntelligenceBrandFocus');
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="mx-[-16px]">
          <hr className="border-success mb-4" />
        </div>
        <div className="flex justify-between items-center p-1">
          <div className="flex space-x-2">
            {/* <select className={selectClasses}>
                  <option>Time</option>
                </select> */}
          </div>
          <div className="flex space-x-2">
            {['PAID', 'ORGANIC', 'PLA'].map((filter) => (
              <TooltipPopover
                key={filter}
                content={`competitive_intelligence_page.brand_focus_tab.brand_focus.${filter.toLowerCase()}`}
                type="popup"
                side="top"
              >
                <span
                  key={filter}
                  className={filterButtonClasses(filter)}
                  onClick={() => toggleFilter(filter as CompIntelAdType)}
                >
                  {filter === 'PLA' ? 'SHOPPING' : filter}{' '}
                  {selectedFilter === filter && <span className="ml-1">✕</span>}
                </span>
              </TooltipPopover>
            ))}
          </div>
        </div>
        <div className="space-y-20">
          {/* <BrandFocusDoughnutChart
                data={data.brand_focus.average_coverage}
                percData={data.brand_focus.calculated_diff}
                selectedFilter={selectedFilter}
                arrowSrc={arrowSrc}
                changeColor={changeColor}
              /> */}

          {filteredData &&
          filteredData.brand_focus.average_coverage &&
          filteredData.brand_focus.calculated_diff ? (
            <BrandFocusDoughnutChart
              data={filteredData.brand_focus.average_coverage}
              percData={filteredData.brand_focus.calculated_diff}
              selectedFilter={selectedFilter}
              arrowSrc={arrowSrc}
              changeColor={changeColor}
              clientDomain={clientDomain}
            />
          ) : (
            <LoadingSpinner />
          )}

          {/* <BrandFocusBarChart
                data={data.brand_focus.average_position}
                selectedFilters={[selectedFilter]}
              /> */}

          {filteredData && filteredData.brand_focus.average_position ? (
            <BrandFocusBarChart
              data={filteredData.brand_focus.average_position}
              selectedFilters={[selectedFilter]}
              clientDomain={clientDomain}
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>

      {/* <BFPaidVsOrganicVsPLA data={data.paid_vs_organic_vs_pla} /> */}
      {filteredData && filteredData.paid_vs_organic_vs_pla ? (
        <BFPaidVsOrganicVsPLA
          data={filteredData.paid_vs_organic_vs_pla}
          clientDomain={clientDomain}
        />
      ) : (
        <LoadingSpinner />
      )}

      {/* <BFLocationAnalysis data={data.location_analysis} /> */}
      {filteredData && filteredData.location_analysis ? (
        <BFLocationAnalysis
          data={filteredData.location_analysis}
          clientDomain={clientDomain}
        />
      ) : (
        <LoadingSpinner />
      )}

      {/* <BattleField data={data.battlefield} /> */}
      {filteredData && filteredData.battlefield ? (
        <BattleField
          data={filteredData.battlefield}
          clientDomain={clientDomain}
        />
      ) : (
        <LoadingSpinner />
      )}

      <SearchTermsOverlap
        data={data.search_term_overlaps}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        compBrands={selectedCompetitors}
        searchTerms={selectedSearchOptions.terms}
      />
    </>
  ) : (
    <div>No data available.</div>
  );
}

export default CompIntelBrandFocus;
