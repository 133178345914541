import axios from 'axios';
import { useQuery } from 'react-query';
import { useCurrentClientAtom } from 'atoms/clientAtom';
import moment from 'moment';
import { addDays, subDays } from 'date-fns';

// COMPONENTS
import Insights from '../components/homepage/Insights';
import AutoOptimize from '../components/homepage/AutoOptimize';
import ConsumerIntent from '../components/homepage/ConsumerIntent';
import CompetitiveIntelligence from '../components/homepage/CompetitiveIntelligence';
import Card from '../pages/CardUseCases';

// FUNCTIONS
import { fetchRecommendations } from '../components/insights_components/Recommendations';

const fetchHomepageData = async () => {
  const { data } = await axios<GETHomepageData>('/api/home/');

  return data;
};

interface APIResponse {
  recommendations: {
    [category: string]: Array<{
      cluster_category: string;
      recommendations: string;
      organic_impressions: number;
      organic_clicks: number;
      organic_transactions: number;
      organic_revenue: number;
      paid_impressions: number;
      paid_clicks: number;
      paid_transactions: number;
      paid_revenue: number;
      top_search_terms: string[];
    }>;
  };
}

function Home({ activatedModules }: { activatedModules: string[] }) {
  const [{ current_client }] = useCurrentClientAtom();

  const { isLoading: isLoadingSidebar } = useQuery([
    'get_customer_info_sidebar',
  ]);

  const { data, error, isLoading } = useQuery(
    ['homepageData', current_client],
    {
      queryFn: fetchHomepageData,
    }
  );

  // Define Usecases
  const useCasesData = [
      { 
        title: 'Micro landscape shifts', 
        question: 'What domains gained coverage, position or search demand over your brand?', 
        buttons: [
          { name: 'Competitive Intelligence', navigateTo: '/competitive_intelligence/overview' },
          { name: 'Consumer Intent', navigateTo: '/consumer_intent/brand_comparision?usecases=micro_landscape_shifts' },
        ]
      },
      { 
        title: 'Saving trends', 
        question: 'How much did Auto-Optimize save this week vs last?', 
        buttons: [
          { name: 'Auto Optimize', navigateTo: '/auto_optimize/overview?usecases=saving_trends' },
        ]
      },
      { 
        title: 'AO Performance', 
        question: 'Is my auto-optimize strategy affecting my holistic search traffic, revenue etc for the chosen KW?',
        buttons: [
          { name: 'AO/Insights', navigateTo: '/insights/recommendations?usecases=auto_optimize_performance' },
        ]
      },
      { 
        title: 'Retail Demand', 
        question: 'What part of the search demand includes retailers in the queries? And which ones?', 
        buttons: [
          { name: 'Consumer Intent', navigateTo: '/consumer_intent/retailer_comparision' },
        ]
      },
      { 
        title: 'Macro trends shifts', 
        question: 'What feature is most searched for and what is their evolution?', 
        buttons: [
          { name: 'Consumer Intent', navigateTo: '/consumer_intent/product_feature_analysis?usecases=macro_trends_shifts' },
        ]
      },
      { 
        title: 'Holistic perf data alert', 
        question: 'Where is my holistic revenue/performance data decreasing vs prior month?', 
        buttons: [
          { name: 'Insights', navigateTo: '/insights/overview?usecases=holistic_perf_data_alert' },
        ]
      },
      // {  // This usecase is not needed for now.
      //   title: 'Increase Savings potential', 
      //   question: 'What opportunities do we have to increase AO saving potential?', 
      //   buttons: [
      //     { name:'AO/Insights', navigateTo: '/' },
      //   ]
      // },
      { 
        title: 'Holistic Optimization opportunities', 
        question: 'What are the top opportunities from the insights recommendation panel?', 
        buttons: [
          {name:'Insights', navigateTo: '/insights/recommendations' }
        ],
      },
  ];

  // Based on the data existing filter out usecases
  // First fiter out buttons and if no buttons are left, filter out the usecase
  const moduleButtonMapping = {
    '1': ['Insights', 'AO/Insights'], // Platform 1 for Insights
    '2': ['Competitive Intelligence'], // Platform 2 for Competitive Intelligence
    '3': ['Consumer Intent'], // Platform 3 for Consumer Intent
    '4': ['Auto Optimize'], // Platform 4 for Auto Optimize
  };
  
  // Filter out use cases and buttons dynamically based on activatedModules
  let filteredUseCasesData = useCasesData
    .map((useCase) => {
      if (!data) return useCase; // Keep all if `data` is undefined
  
      // Filter out buttons if their module is not in `activatedModules`
      const filteredButtons = useCase.buttons.filter((button) => {
        return Object.entries(moduleButtonMapping).some(([id, buttonNames]) => {
          return activatedModules.includes(id) && buttonNames.includes(button.name);
        });
      });
  
      // If no buttons remain after filtering, remove the entire use case
      return filteredButtons.length > 0 ? { ...useCase, buttons: filteredButtons } : null;
    })
    .filter((useCase): useCase is NonNullable<typeof useCase> => useCase !== null); // Ensures that `useCase` is not null
  
  // If no Auto Optimize term exist in Insights>Recommendations, remove the AO Performance use case
  // Use default dates from the recommendation page
  const startDate = addDays(new Date(), -90);
  const endDate = subDays(new Date(), 3);
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

  const { data: aopData } = useQuery(
    ['autoOptimizeCheck', formattedStartDate, formattedEndDate],
    () => fetchRecommendations(formattedStartDate, formattedEndDate),
    {
      enabled: !!startDate && !!endDate,
      select: (data) => 
        Object.keys(data?.recommendations || {}).some(category =>
          category.includes('Auto Optimize') || 
          category.includes('Auto Optimise') || 
          category.includes('AutoOptimize') || 
          category.includes('AutoOptimise')
        ),
    }
  );
  
  if (!aopData) {
    filteredUseCasesData = filteredUseCasesData.filter(
      (item) => item.title !== "AO Performance"
    );
  }
  
  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <div className="flex flex-col py-8">
            {/* Title */}
            <h2 className="text-2xl font-bold mb-6">Explore Use Cases</h2>

            {/* Grid Layout for the Use Cases cards */}
            <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4 md:gap-6">
                {filteredUseCasesData.map((useCase, index) => (
                <Card 
                    key={index} 
                    title={useCase.title} 
                    question={useCase.question} 
                    buttons={useCase.buttons}
                />
                ))}
            </div>
        </div>
        <div className="w-[99%] mx-auto border-b border-[#D3D3D3] mb-2" />
        <div className="grid grid-cols-1 xl-1400:grid-cols-2 gap-6 justify-items-center">
          <div className="w-full">
            <ConsumerIntent
              consumer_data={data?.consumer_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
          <div className="w-full">
            <CompetitiveIntelligence
              competitive_data={data?.competitive_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
          <div className="w-full">
            <AutoOptimize
              auto_data={data?.auto_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
          <div className="w-full">
            <Insights
              insights_data={data?.insights_data}
              error={error}
              isLoading={isLoading || isLoadingSidebar}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
