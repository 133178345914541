import React, { FC } from 'react';
import TooltipPopover from 'components/TooltipPopover';

interface LegendItemBase {
  label: string;
  content?: string;
}

interface LegendItemWithIcon extends LegendItemBase {
  icon: string;
}

interface LegendItemWithColorClass extends LegendItemBase {
  colorClass: string;
}

type LegendItem = LegendItemWithIcon | LegendItemWithColorClass;

interface CustomLegendProps {
  legendSet: keyof typeof legendsData;
  onLegendClick?: (label: string) => void;
  visibility?: Record<string, boolean>;
}

const legendsData = {
  set1: [
    {
      label: 'Position',
      content: 'competitive_intelligence_page.brand_focus_tab.brand_focus.position',
      icon: `${process.env.PUBLIC_URL}/static/img/currentLine.svg`,
    },
  ],
  set2: [
    { label: 'Paid', content:'competitive_intelligence_page.brand_focus_tab.paid_vs_organic_vs_shopping.paid', icon: `${process.env.PUBLIC_URL}/static/img/organic.svg` },
    { label: 'Organic', content:'competitive_intelligence_page.brand_focus_tab.paid_vs_organic_vs_shopping.organic', icon: `${process.env.PUBLIC_URL}/static/img/paid.svg` },
    { label: 'Shopping', content:'competitive_intelligence_page.brand_focus_tab.paid_vs_organic_vs_shopping.shopping', icon: `${process.env.PUBLIC_URL}/static/img/PLA.svg` },
  ],
  set3: [
    {
      label: 'Prior',
      content:'competitive_intelligence_page.overview_tab.prior',
      icon: `${process.env.PUBLIC_URL}/static/img/priorLine.svg`,
    },
    {
      label: 'Current',
      content:'competitive_intelligence_page.overview_tab.current',
      icon: `${process.env.PUBLIC_URL}/static/img/currentLine.svg`,
    },
  ],
  set4: [
    { label: 'Competitor 1', colorClass: 'checkbox-competitor1' },
    { label: 'Competitor 2', colorClass: 'checkbox-competitor2' },
    { label: 'Competitor 3', colorClass: 'checkbox-competitor3' },
    { label: 'Competitor 4', colorClass: 'checkbox-competitor4' },
    { label: 'Competitor 5', colorClass: 'checkbox-competitor5' },
  ],
  set5: [
    {
      label: 'Coverage',
      content: 'competitive_intelligence_page.brand_focus_tab.brand_focus.coverage',
      icon: `${process.env.PUBLIC_URL}/static/img/currentLine.svg`,
    },
  ],
  set6: [
    { label: 'Product 1', colorClass: 'checkbox-competitor1' },
    { label: 'Product 2', colorClass: 'checkbox-competitor2' },
    { label: 'Product 3', colorClass: 'checkbox-competitor3' },
    { label: 'Product 4', colorClass: 'checkbox-competitor4' },
    { label: 'Product 5', colorClass: 'checkbox-competitor5' },
  ],
};

const CustomLegend: FC<CustomLegendProps> = ({
  legendSet,
  onLegendClick,
  visibility,
}) => {
  const legends = legendsData[legendSet] as LegendItem[];

  return (
    <div className="flex flex-col items-start pl-2">
      {legends.map((legend, index) => (
        <div key={index} className="flex items-center mb-2">
          {'colorClass' in legend ? (
            <label
              className={`flex items-center custom-checkbox ${legend.colorClass}`}
            >
              <input
                type="checkbox"
                onChange={() =>
                  onLegendClick && onLegendClick(legend.label.replace(' ', ''))
                }
                checked={
                  visibility ? visibility[legend.label.replace(' ', '')] : true
                }
              />
              <span className="checkbox-icon" />
              <span className="text-sm ml-2">{legend.label}</span>
            </label>
          ) : (
            <>
              <img
                src={legend.icon}
                alt={legend.label}
                className="w-4 h-4 mr-2"
              />
              <TooltipPopover 
                content={legend.content} 
                type='dashed'
                side='top'
              >
              <span className="text-sm">{legend.label}</span>
              </TooltipPopover>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
