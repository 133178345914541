import { useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { useDownloadReport, useIntentContext } from '../../hooks';

// API REQUESTS
import { fetchIntentQueryViewData } from 'api';

// COMPONENTS
import MultiDropdown from '../MultiDropdown';
import TooltipPopover from 'components/TooltipPopover';
import DataTable, { ColumnSort, TableColumns } from '../DataTable';

// TYPES
import { QueryViewData, QueryViewTableFilters } from 'types';

const DEFAULT_OPTIONS: QueryViewTableFilters = {
  queries: null,
  brand: null,
  mindset: null,
  feature_group: null,
};
const DEFAULT_FILTERS: QueryViewTableFilters = {
  queries: null,
  brand: null,
  mindset: null,
  feature_group: null,
};

const columns: TableColumns<QueryViewData>[] = [
  { header: 'Query', bind: 'Queries' },
  { header: 'Mindset', bind: 'Mindset' },
  { header: 'Vertical', bind: 'Vertical' },
  { header: 'Brand', bind: 'Brand' },
  { header: 'Product', bind: 'Product' },
  { header: 'Feature Group', bind: 'Feature_Group' },
  { header: 'Feature', bind: 'Feature' },
  { header: 'Retailer', bind: 'Retailer' },
  { header: 'Demand', bind: 'Demand' },
];

const DEFAULT_SORT: ColumnSort<QueryViewData> = {
  sort_column: 'Demand',
  sort_direction: 'desc',
};

const getSelectionsStr = (arr: string[]) =>
  arr.join(',').replace('uncategorized', 'null');

// CONSUMER INTENT QUERY VIEW TAB
function QueryView() {
  const { mutate, isLoading: isDownloading } =
    useDownloadReport('Consumer Intent');
  const { options, dateRange, selectedProducts, selectedVerticals } =
    useIntentContext();

  const [filterValues, setFilterValues] = useState({ ...DEFAULT_FILTERS });
  const [filterOptions, setFilterOptions] =
    useState<QueryViewTableFilters | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [columnSort, setColumnSort] = useState<ColumnSort<QueryViewData>>({
    ...DEFAULT_SORT,
  });

  const dataFilters = useMemo(
    () => ({
      ...filterValues,
      verticals: [...selectedVerticals],
      products: [...selectedProducts],
    }),
    [filterValues, selectedProducts, selectedVerticals]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [dateRange]);

  const { data, isLoading } = useQuery(
    [
      'consumerIntentQueryView',
      dateRange,
      dataFilters,
      currentPage,
      columnSort,
    ],
    () =>
      fetchIntentQueryViewData(dateRange!, dataFilters, {
        pagination: { page: currentPage },
        columnSort,
      }),
    {
      enabled: !!dateRange?.startDate && !!dateRange.endDate,
      staleTime: 1000 * 60 * 60,
    }
  );

  useEffect(() => {
    if (!data || filterOptions) return;
    const options = { ...DEFAULT_OPTIONS };

    if (data) {
      options.brand = data.all_filters.all_brands;
      options.feature_group = data.all_filters.all_feature_groups;
      options.mindset = data.all_filters.all_mindsets;
      options.queries = data.all_filters.all_queries;
    }
    setFilterOptions(options);
  }, [data, filterOptions]);

  const onClearFilters = () => {
    setFilterValues({ ...DEFAULT_FILTERS });
    setCurrentPage(1);
  };

  const onApplyFilters = (
    selections: string[],
    key: keyof QueryViewTableFilters
  ) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: selections.length ? [...selections] : null,
    }));
    setCurrentPage(1);
  };

  const onSortColumn = (sortInfo: ColumnSort<QueryViewData>) => {
    setColumnSort({ ...sortInfo });
  };

  const onDownloadReport = () => {
    const products = getSelectionsStr(
      selectedProducts.length ? selectedProducts : options.products
    );
    const verticals = getSelectionsStr(
      selectedVerticals.length ? selectedVerticals : options.verticals
    );

    mutate({
      report_type: 'query-view-report',
      dateRange,
      verticals,
      products,
    });
  };

  // JSX
  return (
    <div className={isLoading || isDownloading ? 'cursor-wait' : ''}>
      <button
        className="m-auto text-sm font-bold py-2 px-4 rounded-full items-center lg:flex bg-success text-light w-24 h-10 block mb-4 disabled:cursor-not-allowed disabled:bg-disgreen800 disabled:opacity-40"
        onClick={onDownloadReport}
        disabled={isLoading || isDownloading}
      >
        {isLoading ? (
          <div className="flex justify-center w-full">
            <div className="animate-spin rounded-full h-5 w-5 border-t-4 border-b-4 border-light"></div>
          </div>
        ) : (
          'Download'
        )}
      </button>

      <DataTable
        above={
          <div>
            <div className="flex justify-end mb-2 -translate-y-2">
              <button
                className="bg-thirdgray text-xs hover:bg-[#848484] active:bg-[#646464] text-white py-1 px-2 rounded"
                onClick={onClearFilters}
              >
                Clear Filters
              </button>
            </div>
            <div className="flex justify-between">
              <TooltipPopover
                content="consumer_intent_page.query_view_tab.filter.queries"
                type="popup"
                side="top"
              >
                <MultiDropdown
                  placeholder={'Queries'}
                  options={filterOptions?.queries || []}
                  preselectedOptions={filterValues?.queries || []}
                  onSelectionChange={(selections) => {
                    onApplyFilters(selections, 'queries');
                  }}
                  dropdownLength="max-h-60"
                  alignMenu="start"
                  className="max-w-48"
                />
              </TooltipPopover>
              <TooltipPopover
                content="consumer_intent_page.query_view_tab.filter.brand"
                type="popup"
                side="top"
              >
                <MultiDropdown
                  placeholder={'Brand'}
                  options={filterOptions?.brand || []}
                  preselectedOptions={filterValues.brand || []}
                  onSelectionChange={(selections) => {
                    onApplyFilters(selections, 'brand');
                  }}
                  dropdownLength="max-h-60"
                  alignMenu="center"
                  className="max-w-48"
                />
              </TooltipPopover>
              <TooltipPopover
                content="consumer_intent_page.query_view_tab.filter.feature_groupe"
                type="popup"
                side="top"
              >
                <MultiDropdown
                  placeholder={'Feature Group'}
                  options={filterOptions?.feature_group || []}
                  preselectedOptions={filterValues.feature_group || []}
                  onSelectionChange={(selections) => {
                    onApplyFilters(selections, 'feature_group');
                  }}
                  dropdownLength="max-h-60"
                  alignMenu="center"
                  className="max-w-48"
                />
              </TooltipPopover>
              <TooltipPopover
                content="consumer_intent_page.query_view_tab.filter.mindset"
                type="popup"
                side="top"
              >
                <MultiDropdown
                  placeholder={'Mindset'}
                  options={filterOptions?.mindset || []}
                  preselectedOptions={filterValues.mindset || []}
                  onSelectionChange={(selections) => {
                    onApplyFilters(selections, 'mindset');
                  }}
                  dropdownLength="max-h-60"
                  className="max-w-48"
                />
              </TooltipPopover>
            </div>
          </div>
        }
        isLoading={isLoading}
        data={data?.filtered_data || []}
        columns={columns}
        currentPage={data?.page}
        itemsPerPage={data?.page_size || 50}
        totalItems={data?.total_records}
        onPageChange={setCurrentPage}
        onSort={onSortColumn}
      />
    </div>
  );
}

export default QueryView;
