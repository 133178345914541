// COMPONENTS
import { DropdownOption } from '../../components/Dropdown';
import ReportsDownload from '../../components/ReportsDownload';

// TYPES
import { AutoOptimizeReport, currentENV } from '../../api';
import { DateValueType } from 'react-tailwindcss-datepicker';

const REPORT_OPTIONS: DropdownOption<AutoOptimizeReport>[] = [
  { label: 'Change Log', value: 'osab-change-log-report' },
  { label: 'Cost, Savings, Clicks, Impressions', value: 'osab-cost-report' },
];

const maxDate = new Date();
maxDate.setDate(maxDate.getDate() - 1);

const startDate = new Date();
startDate.setDate(maxDate.getDate() - 6);

const MAX_DATE = new Date();
MAX_DATE.setDate(MAX_DATE.getDate() - 4);

const START_DATE = new Date();
START_DATE.setDate(MAX_DATE.getDate() - 6);

const DEFAULT_DATE_RANGE: DateValueType = {
  startDate: START_DATE,
  endDate: MAX_DATE,
};

// COMPETITIVE INTEL REPORTS TAB/PAGE
function AutoOptimizeReports() {
  // JSX
  return (
    <ReportsDownload
      options={REPORT_OPTIONS}
      module="AutoOptimize"
      maxDate={MAX_DATE}
      defaultDateRange={DEFAULT_DATE_RANGE}
    />
  );
}

export default AutoOptimizeReports;
