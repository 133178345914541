import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import { Line } from 'react-chartjs-2';
import LoadingSpinner from './LoadingSpinner';
import { Chart, registerables, ChartData } from 'chart.js';
import TooltipPopover from 'components/TooltipPopover';

Chart.register(...registerables);

interface Info {
  savings: string;
  savings30: string;
  savings30Change: string;
}

interface AutoOptHomeProps {
  auto_data?: AutoOptHomeData;
  error?: unknown;
  isLoading?: boolean;
}

function AutoOptimize({ auto_data, error, isLoading }: AutoOptHomeProps) {
  const [auto_blur, setBlur] = useState(false);

  useEffect(() => {
    if (!auto_data) return;

    setBlur(auto_data.autoBlur);
  }, [auto_data]);

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/auto_optimize');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div>Error fetching auto optimize data: {(error as Error).message}</div>
    );
  }

  if (!auto_data || auto_data?.data.length === 0) {
    return <div></div>;
  }

  // Update the chart data
  const labels = auto_data.data.map(
    (item: { label: string; value: number }) => item.label
  );
  const values = auto_data.data.map(
    (item: { label: string; value: number }) => item.value
  );

  const chartData: ChartData<'line'> = {
    labels: labels,
    datasets: [
      {
        label: 'Savings',
        data: values,
        borderColor: '#222222CC',
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        fill: false,
        pointRadius: 2,
        pointBackgroundColor: '#FFFFFF',
        pointBorderWidth: 1,
        borderWidth: 1,
        tension: 0.0,
      },
    ],
  };

  const info: Info = {
    savings: auto_data.details.savings.title,
    savings30: auto_data.details.savings30.title,
    savings30Change: auto_data.details.savings30.percentage.toString(),
  };

  const formatNumber = (num: number): string => {
    const thresholds = [
      { value: 1e9, suffix: 'B' },
      { value: 1e6, suffix: 'M' },
      { value: 1e3, suffix: 'K' },
    ];

    for (const threshold of thresholds) {
      if (num >= threshold.value) {
        return (num / threshold.value).toFixed(1) + threshold.suffix;
      }
    }

    return num.toString();
  };

  return (
    <div className="bg-light rounded-lg shadow-md h-[413px] w-full lg:h-auto">
      {auto_blur && (
        <span className="block bg-light text-primarygray p-4 mb-4 rounded">
          NO DATA FOR THIS PRODUCT
        </span>
      )}
      <div className={`relative ${auto_blur ? 'filter blur-sm' : ''}`}>
        <div className="px-4 py-2 flex justify-between items-center border-b border-success">
          <div>
            <TooltipPopover 
              content='homepage.auto_optimize.auto_optimize' 
              type='info'
              className='top-1 right-8'
              tooltipWidth='max-w-72'
              side='top'
            >
            <h3
              className="text-primarygray text-2xl font-bold cursor-pointer tracking-[0.48px]"
              onClick={handleRedirect}
            >
              Auto Optimize
            </h3>
            </TooltipPopover>
            <h6 className="text-sm text-gray400 font-semibold flex items-center">
              Increase holistic brand efficiency
            </h6>
          </div>
          <h6 className="text-sm font-semibold text-gray400 mt-8">
            Past 12 months
          </h6>
        </div>
        <div className="flex">
          <div className="py-6 px-4 flex-1 min-w-[200px] flex flex-col justify-center">
            <div className="mb-4">
              <TooltipPopover 
                content='homepage.auto_optimize.all_time_savings' 
                type='dashed'
                side='top'
                className='underline decoration-dashed decoration-1 w-fit'
              >
              <h6 className="text-sm font-medium text-thirdgray w-fit">
                All time Savings
              </h6>
              </TooltipPopover>
              <div className="flex items-center mt-2">
                <h3 className="text-primarygray text-3xl font-medium">
                  {info.savings}
                </h3>
              </div>
            </div>
            <div>
              <TooltipPopover 
                content='homepage.auto_optimize.savings_for_current_month' 
                type='dashed'
                side='top'
                className='underline decoration-dashed decoration-1 w-fit'
              >
              <h6 className="text-sm font-medium text-thirdgray w-fit">
                Savings for current month
              </h6>
              </TooltipPopover>
              <div className="flex items-center mt-2">
                <h3 className="text-primarygray text-3xl font-medium">
                  {info.savings30}
                </h3>
                <div
                  className={`flex justify-center items-center ml-1 ${
                    info.savings30Change === 'N/A' ||
                    info.savings30Change === '0%'
                      ? 'text-gray500'
                      : info.savings30Change.startsWith('-')
                      ? 'text-error'
                      : 'text-success'
                  }`}
                >
                  {info.savings30Change !== 'N/A' &&
                    info.savings30Change !== '0%' && (
                      <img
                        src={
                          info.savings30Change.startsWith('-')
                            ? `${process.env.PUBLIC_URL}/static/img/Arrow_negative.svg`
                            : `${process.env.PUBLIC_URL}/static/img/Arrow_positive.svg`
                        }
                        alt="Arrow Icon"
                      />
                    )}
                  <h6 className="text-sm font-medium">
                    {info.savings30Change === 'N/A' ||
                    info.savings30Change === '0%'
                      ? info.savings30Change
                      : `${info.savings30Change.startsWith('-') ? '' : '+'}${
                          info.savings30Change
                        }`}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 py-6 px-4">
            {isLoading ? (
              <p className="text-thirdgray">Loading data...</p>
            ) : (
              <div className="w-[307px] h-[251px] 2xl:w-[370px] 3xl:w-[500px]">
                <Line
                  data={chartData}
                  options={{
                    scales: {
                      x: {
                        type: 'time',
                        time: {
                          unit: 'month',
                          displayFormats: {
                            month: 'MM/yy',
                          },
                        },
                        ticks: {
                          display: true,
                          color: '#333333',
                          maxRotation: 0,
                          minRotation: 0,
                          font: {
                            size: 12, // Set font size
                            style: 'normal', // Set font style
                            weight: 400, // Set font weight
                            lineHeight: 1.33, // Set line height (16px / 12px = 1.33)
                          },
                        },
                        border: {
                          color: '#333333',
                          display: true,
                        },
                        grid: {
                          color: '#B3B3B3', // Display vertical grid lines
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'Savings',
                          font: {
                            size: 14, // Set font size
                            style: 'normal', // Set font style
                            weight: 700, // Set font weight
                            lineHeight: 1.28, // Set line height (18px / 14px = 1.28)
                          },
                          align: 'center',
                        },
                        ticks: {
                          display: true,
                          color: '#000',
                          callback: function (value) {
                            const numericValue = Number(value);
                            return formatNumber(numericValue);
                          },
                          font: {
                            size: 12, // Set font size
                            style: 'normal', // Set font style
                            weight: 400, // Set font weight
                            lineHeight: 1.33, // Set line height (16px / 12px = 1.33)
                          },
                          align: 'center',
                        },
                        border: {
                          color: '#333333',
                          display: true,
                        },
                        grid: {
                          color: '#B3B3B3', // Display horizontal grid lines
                        },
                      },
                    },
                    plugins: {
                      legend: { display: false },
                    },
                    interaction: {
                      mode: 'index',
                      intersect: false,
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex px-4 py-2 justify-end">
          <a
            className="inline-flex items-center w-[110px] h-[30px] justify-center text-thirdgray text-sm font-normal bg-fourthgray rounded-[8px] cursor-pointer"
            onClick={handleRedirect}
          >
            See more
            <img
              src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
              className="w-4 h-4 bg-contain bg-center ml-1"
              alt="right-arrow"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default AutoOptimize;
