import moment from 'moment';
import { useQuery } from 'react-query';
import React, { useMemo, useState } from 'react';
import { addDays, subDays } from 'date-fns';

import { DEFAULT_STALE_TIME } from '../../constants';

// COMPONENTS
import FilterBarWrapper from '../FilterBarWrapper';
import DatepickerComponent from '../DatepickerComponent';
import ClusterDetails from './cluster_components/ClusterDetails';
import RevenuePerCluster from './cluster_components/RevenuePerCluster';
import TransactionsPerCluster from './cluster_components/TransactionsPerCluster';
import TooltipPopover from 'components/TooltipPopover';

// TYPES
import { DateValueType } from 'react-tailwindcss-datepicker';
import InsightsCategorySelector from './InsightsCustomSelector';

const fetchPerClusterData = async (
  startDate: string,
  endDate: string
): Promise<GETPerClusterData> => {
  const response = await fetch(
    `/get_cluster_data/per_cluster/${startDate}/${endDate}`
  );
  if (!response.ok) {
    const errorData = await response.json();
    if (errorData.error === "'json_data'") {
      throw new Error(
        'No data for this time period, please change the date range'
      );
    }
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};

const fetchClusterData = async (
  startDate: string,
  endDate: string
): Promise<GETClusterDetailsData> => {
  const response = await fetch(
    `/get_insights_category_keyword_data/${startDate}/${endDate}`
  );
  if (!response.ok) {
    const errorData = await response.json();
    if (errorData.error === "'json_data'") {
      throw new Error(
        'No data for this time period, please change the date range'
      );
    }
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};

const ALL_CLUSTERS_OPTION = 'All';

// CLUSTER PAGE
function Cluster() {
  const [activeCluster, setActiveCluster] = useState('');
  const [clusterOptions, setClusterOptions] = useState<string[]>([]);
  const [value, setValue] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: addDays(new Date(), -90), // 90 days before today
    endDate: subDays(new Date(), 3),
    // startDate: new Date("2024-04-07"),
    // endDate: new Date("2024-04-15"),
  });

  // DATA FETCHING
  const {
    data: perClusterData,
    isError: isErrorPerCluster,
    isLoading: isLoadingPerCluster,
  } = useQuery(
    ['revenueClusterData', value.startDate, value.endDate],
    () =>
      fetchPerClusterData(
        moment(value.startDate).format('YYYY-MM-DD'),
        moment(value.endDate).format('YYYY-MM-DD')
      ),
    {
      enabled: !!value.startDate && !!value.endDate,
      staleTime: DEFAULT_STALE_TIME,
      onSuccess: (data) => {
        setClusterOptions([
          ALL_CLUSTERS_OPTION,
          ...(data?.cluster_list_name || []),
        ]);
        setActiveCluster(ALL_CLUSTERS_OPTION);
      },
    }
  );

  const {
    data: detailsData,
    isLoading: isLoadingDetailsData,
    isError: isErrorDetailsData,
  } = useQuery(
    ['clusterDetailsData', value.startDate, value.endDate],
    () =>
      fetchClusterData(
        moment(value.startDate).format('YYYY-MM-DD'),
        moment(value.endDate).format('YYYY-MM-DD')
      ),
    {
      enabled: !!value.startDate && !!value.endDate,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setValue({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  const filteredPerClusterData = useMemo(() => {
    const filteredData = perClusterData?.per_cluster.filter(
      ({ cluster_name }) =>
        activeCluster !== ALL_CLUSTERS_OPTION
          ? cluster_name === activeCluster
          : true
    );

    if (!filteredData) return;

    const lastIndex = filteredData.length - 1;

    // Swap "Uncategorized" to end
    for (let i = 0; i < lastIndex; i++) {
      if (filteredData[i].cluster_name.toLowerCase() === 'uncategorized') {
        const temp = filteredData[lastIndex];

        filteredData[lastIndex] = filteredData[i];
        filteredData[i] = temp;
      }
    }

    return filteredData;
  }, [perClusterData, activeCluster]);

  // JSX
  return (
    <div className="pt-8">
      <FilterBarWrapper>
        <div className="flex-1" />
        <TooltipPopover 
          content="insights_page.date_range_filter" 
          type='popup'
          side='top'
        >
        <DatepickerComponent
          value={value}
          onChange={handleValueChange}
          product="insights"
        />
        </TooltipPopover>
      </FilterBarWrapper>
      <div>
        <InsightsCategorySelector
          options={clusterOptions}
          value={activeCluster}
          onChange={setActiveCluster}
        />
      </div>
      <RevenuePerCluster
        data={filteredPerClusterData}
        isError={isErrorPerCluster}
        isLoading={isLoadingPerCluster}
      />
      <TransactionsPerCluster
        data={filteredPerClusterData}
        isError={isErrorPerCluster}
        isLoading={isLoadingPerCluster}
      />
      <ClusterDetails
        data={detailsData?.search_terms}
        isError={isErrorDetailsData}
        isLoading={isLoadingDetailsData}
        clusterFilter={
          activeCluster !== ALL_CLUSTERS_OPTION ? activeCluster : undefined
        }
      />
    </div>
  );
}

export default Cluster;
