import {
  BFOutput,
  BDomainData,
  BFInputData,
  BPDomainData,
  CDDomainData,
  DomainDataAC,
  DomainDataAP,
  LADomainData,
  POPDomainData,
  BrandFocusTabData,
  BrandFocusInnerDataBar,
  BrandFocusInnerDataDoughnut,
  CompIntelAdType,
} from '../../types';

// -------------------- DATA FILTERING BASED ON DROPDOWN SECTION --------------------

const filterDomainsBrandPresence = (
  data: BPDomainData,
  selectedDomains: string[]
) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {} as BPDomainData);
};

const filterDomainsLocationPaidVsOrganicVsPLA = (
  data: POPDomainData,
  selectedDomains: string[]
) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {} as POPDomainData);
};

const filterDomainsLA = (data: LADomainData, selectedDomains: string[]) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {} as LADomainData);
};

const filterDomainsCD = (data: CDDomainData, selectedDomains: string[]) => {
  return Object.keys(data)
    .filter((domain) => selectedDomains.includes(domain))
    .reduce((acc, domain) => {
      acc[domain] = data[domain];
      return acc;
    }, {} as CDDomainData);
};

const filterBattlefieldDomains = (
  data: { [adType: string]: BDomainData },
  selectedDomains: string[]
) => {
  const filteredData: { [adType: string]: BDomainData } = {};

  Object.keys(data).forEach((adType) => {
    const filteredDomains = Object.keys(data[adType])
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        const domainData = data[adType][domain];

        if (domainData) {
          acc[domain] = domainData;
        }

        return acc;
      }, {} as BDomainData);

    filteredData[adType] = filteredDomains;
  });

  return filteredData;
};

const filterBrandFocusAC = (
  data: BrandFocusInnerDataDoughnut,
  selectedDomains: string[]
) => {
  const filteredData: BrandFocusInnerDataDoughnut = {};

  Object.keys(data).forEach((adType) => {
    filteredData[adType] = {
      desktop: Object.keys(data[adType].desktop)
        .filter((domain) => selectedDomains.includes(domain))
        .reduce((acc, domain) => {
          acc[domain] = data[adType].desktop[domain];
          return acc;
        }, {} as DomainDataAC),
      mobile: Object.keys(data[adType].mobile)
        .filter((domain) => selectedDomains.includes(domain))
        .reduce((acc, domain) => {
          acc[domain] = data[adType].mobile[domain];
          return acc;
        }, {} as DomainDataAC),
    };
  });

  return filteredData;
};

const filterBrandFocusAP = (
  data: BrandFocusInnerDataBar,
  selectedDomains: string[]
) => {
  const filteredData: BrandFocusInnerDataBar = {};

  Object.keys(data).forEach((adType) => {
    filteredData[adType] = {
      desktop: Object.keys(data[adType].desktop)
        .filter((domain) => selectedDomains.includes(domain))
        .reduce((acc, domain) => {
          acc[domain] = data[adType].desktop[domain];
          return acc;
        }, {} as DomainDataAP),
      mobile: Object.keys(data[adType].mobile)
        .filter((domain) => selectedDomains.includes(domain))
        .reduce((acc, domain) => {
          acc[domain] = data[adType].mobile[domain];
          return acc;
        }, {} as DomainDataAP),
    };
  });

  return filteredData;
};

// -------------------- END OF: DATA FILTERING BASED ON DROPDOWN SECTION --------------------

export const xlsBFGetAverageCoverage = (
  data: BFInputData,
  adType: CompIntelAdType,
  brands: string[]
): BFOutput => {
  const websites: string[] = [];
  const devices: string[] = [];
  const types: string[] = [];
  const coverages: string[] = [];
  const percentageDiffs: string[] = [];

  const { average_coverage, calculated_diff } = data;

  const selectedBrands = new Set(brands);

  for (const [device, deviceData] of Object.entries(
    average_coverage[adType] || {}
  )) {
    for (const [domain, coverage] of Object.entries(deviceData)) {
      if (selectedBrands.has(domain)) {
        websites.push(domain);
        devices.push(device);
        types.push(adType);
        coverages.push(coverage.toString());

        const websiteDiff = calculated_diff?.[domain];
        let percentage = '0';

        if (websiteDiff && typeof websiteDiff === 'object') {
          const deviceDiff = websiteDiff[device];
          if (deviceDiff && typeof deviceDiff === 'object') {
            percentage = String(deviceDiff[adType] ?? '0');
          }
        }

        percentageDiffs.push(percentage);
      }
    }
  }

  const output: BFOutput = {
    label: websites,
    columns: [devices, types, coverages, percentageDiffs],
  };

  return output;
};

export const xlsBFGetAveragePosition = (
  data: BFInputData,
  adType: CompIntelAdType,
  brands: string[]
): BFOutput => {
  const websites: string[] = [];
  const devices: string[] = [];
  const types: string[] = [];
  const position1: string[] = []; // For the first position
  const position2: string[] = []; // For the second position (if available)
  const position3: string[] = []; // For the third position (if available)

  const { average_position } = data;

  const selectedBrands = new Set(brands);

  for (const [device, deviceData] of Object.entries(
    average_position[adType] || {}
  )) {
    for (const [domain, positionData] of Object.entries(deviceData)) {
      if (selectedBrands.has(domain)) {
        const position1Value = positionData[0] ?? '0';
        const position2Value = positionData[1] ?? '0';
        const position3Value = positionData[2] ?? '0';

        websites.push(domain);
        devices.push(device);
        types.push(adType);

        position1.push(String(position1Value));
        position2.push(String(position2Value));
        position3.push(String(position3Value));
      }
    }
  }
  const output: BFOutput = {
    label: websites,
    columns: [devices, types, position1, position2, position3],
  };

  return output;
};

export const filterBFDataBasedOnDropdown = (
  data: BrandFocusTabData,
  selectedDomains: string[]
): BrandFocusTabData => {
  const newData: BrandFocusTabData = {
    battlefield: {
      desktop: {},
      mobile: {},
    },
    brand_focus: {
      average_coverage: {},
      average_position: {},
      calculated_diff: {},
    },
    brand_presence: {},
    location_analysis: {
      desktop: {},
      mobile: {},
    },
    paid_vs_organic_vs_pla: {
      desktop: {},
      mobile: {},
    },
  };

  if (selectedDomains.length === 0) return newData;

  // Filter brand focus data with nullish coalescing
  newData.brand_focus = {
    average_coverage: filterBrandFocusAC(
      data?.brand_focus?.average_coverage ?? {},
      selectedDomains
    ),
    average_position: filterBrandFocusAP(
      data?.brand_focus?.average_position ?? {},
      selectedDomains
    ),
    calculated_diff: filterDomainsCD(
      data?.brand_focus?.calculated_diff ?? {},
      selectedDomains
    ),
  };

  // Filter brand presence data with nullish coalescing
  newData.brand_presence = filterDomainsBrandPresence(
    data?.brand_presence ?? {},
    selectedDomains
  );

  // Filter paid vs organic vs pla data with nullish coalescing
  newData.paid_vs_organic_vs_pla = {
    desktop: filterDomainsLocationPaidVsOrganicVsPLA(
      data?.paid_vs_organic_vs_pla?.desktop ?? {},
      selectedDomains
    ),
    mobile: filterDomainsLocationPaidVsOrganicVsPLA(
      data?.paid_vs_organic_vs_pla?.mobile ?? {},
      selectedDomains
    ),
  };

  // Filter location analysis data with nullish coalescing
  newData.location_analysis = {
    desktop: filterDomainsLA(
      data?.location_analysis?.desktop ?? {},
      selectedDomains
    ),
    mobile: filterDomainsLA(
      data?.location_analysis?.mobile ?? {},
      selectedDomains
    ),
  };

  // Filter battlefield data with nullish coalescing
  newData.battlefield = {
    desktop: filterBattlefieldDomains(
      data?.battlefield?.desktop ?? {},
      selectedDomains
    ),
    mobile: filterBattlefieldDomains(
      data?.battlefield?.mobile ?? {},
      selectedDomains
    ),
  };

  return newData;
};
