import { useQuery } from 'react-query';
import { useIntentContext } from '../../hooks';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

// API REQUESTS
import { fetchConsumerIntBCData } from '../../api';

// COMPONENTS
import OutletSpinner from '../OutletSpinner';
import GrowingBrand from './BrandComparison_comp/GrowingBrand';
import BCStackedBar1 from './BrandComparison_comp/BCStackedBar1';
import BCStackedBar2 from './BrandComparison_comp/BCStackedBar2';
import BrandMarketOverview from './BrandComparison_comp/BrandMarketOverview';

// CONSUMER INTENT BRAND COMPARISON TAB
function BrandComparison() {
  const { selectedProducts, selectedVerticals, dateRange } = useIntentContext();
  const [searchParams] = useSearchParams();
  const usecases = searchParams.get('usecases');

  // Reference for the GrowingBrand component
  const growingBrandRef = useRef<HTMLDivElement>(null);

  const { data: consumerIntBCData, isLoading } = useQuery(
    ['consumerIntBCData', dateRange, selectedVerticals, selectedProducts],
    () =>
      fetchConsumerIntBCData(dateRange, selectedVerticals, selectedProducts),
    {
      enabled: !!dateRange?.startDate && !!dateRange?.endDate,
    }
  );

  // Scroll to GrowingBrand component when the page loads with usecases=micro_landscape_shifts
  useEffect(() => {
    // get window width
    const windowWidth = window.innerWidth;
    let offset = 240;

    if (windowWidth < 1324 && windowWidth > 923) {
      offset = 300;
    } else if (windowWidth <= 923) {
      offset = 360;
    }

    if (usecases === 'micro_landscape_shifts' && growingBrandRef.current) {
      window.scrollTo({ 
        top: growingBrandRef.current.getBoundingClientRect().top + window.scrollY - offset, 
        behavior: 'smooth' 
      });      
    }
  }, [usecases, consumerIntBCData]);

  return isLoading ? (
    <OutletSpinner />
  ) : consumerIntBCData ? (
    <>
      <BrandMarketOverview data={consumerIntBCData} />
      <div ref={growingBrandRef}>
        <GrowingBrand
          brands_growth_shrink_chart_data={
            consumerIntBCData.brands_growth_shrink_chart
          }
        />
      </div>
      <BCStackedBar1
        product_association_with_brands_chart_data={
          consumerIntBCData.product_association_with_brands_chart
        }
      />
      <BCStackedBar2
        feature_association_with_brands_chart_data={
          consumerIntBCData.feature_association_with_brands_chart
        }
      />
    </>
  ) : null;
}

export default BrandComparison;
