import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import { useCurrentClientAtom, useCurrentUserAtom } from 'atoms';

import { ReportType, ReportModule, downloadReport } from 'api';

// TYPES
import { DateValueType } from 'react-tailwindcss-datepicker';

export const useDownloadReport = (module: ReportModule) => {
  const [user] = useCurrentUserAtom();
  const [client] = useCurrentClientAtom();

  if (!user) {
    throw new Error('No current user available.');
  }
  if (!client) {
    throw new Error('No current client available.');
  }

  const mutation = useMutation({
    mutationFn: (params: {
      report_type: ReportType[typeof module];
      dateRange: DateValueType;
      verticals?: string;
      products?: string;
    }) => {
      return downloadReport({
        ...params,
        module,
        user_id: user.user_id,
        market: client.current_client_market,
      });
    },
    onError: (error) => {
      let errorMsg =
        'Unable to process request.  Please reach out to support for further assistance.';
      if (isAxiosError<{ error: string }>(error)) {
        errorMsg = error.response?.data.error || errorMsg;
      }
      toast.error(errorMsg);
    },
    onSuccess: () => {
      toast.success(
        'The process of generating your requested report is currently underway.  You will receive an email once this process is complete.'
      );
    },
  });

  return mutation;
};
